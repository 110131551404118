import React, { ReactElement, useContext, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ADD_TO_CART, BESTSELLERS, BOX_UNIT, OUTLET, PIECE_UNIT } from '../../../i18n/translations/TR';
import { NumberFormatComponentPieceUnit } from './NumberFormatPieceUnit';
import getProductUseCase from '../../product/domain/useCase/product/GetProductUseCase';
import { ShoppingModal } from './modals/shoppingModal/ShoppingModal';
import { getWeight } from '../helper/getWeight';
import { getArea } from '../helper/getArea';
import { getSquareMeasurement } from '../helper/getSquareMeasurement';
import { TencerRoutes } from '../tencer-routing/TencerRoutes';
import { getRoundedValues } from '../helper/getRoundedValues';
import { ReactComponent as IcSize } from '../../../assets/img/icons/ic-box.svg';
import { ReactComponent as IcWeight } from '../../../assets/img/icons/ic-weight.svg';
import { ReactComponent as IcArea } from '../../../assets/img/icons/ic-area.svg';
import { ReactComponent as IcStock } from '../../../assets/img/icons/ic-stock.svg';
import { Currency } from './Currency';
import { usePermission } from '../hooks/usePermission';
import { CollectionId } from '../../collection/domain/valueObject/CollectionId';
import { Product } from '../../product/domain/entity/Product';
import { ProductDetails } from '../../product/domain/entity/ProductDetails';
import { DependencyInjectionContext } from '../context/DependecyInjectionContext';

interface SwiperCardProps {
    title: string
    product: Product
}

export const SwiperCard = (props: SwiperCardProps): ReactElement => {
    const { t } = useTranslation();
    const permission = usePermission();
    const dic = useContext(DependencyInjectionContext);
    const mounted = useRef(false);
    const [productDetail, setProductDetail] = useState<ProductDetails>();
    const [show, setShow] = useState(false);
    const [showForce, setShowForce] = useState(false);

    const stockClass = classNames(
        'product-stock',
        { 'd-none': props.title === t(BESTSELLERS) || props.title === t(OUTLET) },
    );

    const handleClose = (): void => {
        setShow(false);
    };
    const handleShow = (): void => {
        setShow(true);
    };
    const handleCloseForce = (): void => {
        setShowForce(false);
    };
    const handleShowForce = (): void => {
        setShowForce(true);
    };

    const getProductDetails = (): void => {
        getProductUseCase({
            productRepository: dic.productRepository,
            productId: props.product.id,
        }).then(setProductDetail);
    };

    useEffect(() => {
        getProductDetails();
        return (): void => {
            mounted.current = false;
        };
    }, []);

    return (
        <div className="product-detail-image grid-item">
            <Link
                className="grid-item"
                key={props.product.id.value}
                style={{ textDecoration: 'none' }}
                to={`/products/${props.product.id.value}`}
            >
                <div className="product-image">
                    <img
                        alt="product"
                        src={props.product.image?.value}
                    />
                    {window.location.pathname === TencerRoutes.CATALOG ?
                        (
                            <div className={stockClass}>
                                {props.product.outlet ?
                                    (
                                        <IcStock className="icon-mini" />
                                    ) : null}
                            </div>
                        ) : null}
                </div>
                <div className="product-item-details">
                    <div className="product-title">{props.product.description}</div>
                    <div className="product-description">{props.product.collectionName?.value}</div>
                    {props.product.boxPiece > 0 ?
                        (
                            <div className="product-size">
                                <IcSize className="icon-mini" />
                                {`${props.product.boxPiece} ${t(PIECE_UNIT)}/${t(BOX_UNIT)}`}
                            </div>
                        ) : null}
                    {props.product.boxMeter > 0 ? (
                        <div className="product-size">
                            <IcArea className="icon-mini" />
                            <NumberFormatComponentPieceUnit
                                value={getRoundedValues(props.product.boxMeter, 3)}
                                suffix={` ${getSquareMeasurement()}/${t(BOX_UNIT)}`}
                            />
                        </div>
                    ) : null }
                    {props.product.boxWeight.weight > 0 ? (
                        <div className="product-weight">
                            <IcWeight className="icon-mini" />
                            <NumberFormatComponentPieceUnit
                                value={getRoundedValues(props.product.boxWeight.weight)}
                                suffix={` ${getWeight()}/${t(BOX_UNIT)}`}
                            />
                        </div>
                    ) : null}
                </div>
            </Link>
            <div className="product-item-detail">
                <div className="product-item-footer">
                    <div
                        className="product-price"
                        style={{ marginLeft: '20px', justifyContent: 'space-around' }}
                    >
                        {props.product.price !== null && props.product.unit ?
                            (
                                <div className="price-section">
                                    <Currency
                                        className="border-0 import"
                                        value={props.product.price.value}
                                    />
                                    <div
                                        className="units-type-mod"
                                        style={{ textDecoration: 'none' }}
                                    >
                                        {getArea(props.product.unit)}
                                    </div>
                                </div>
                            ) : null}

                        {permission.canDoOrders() && props.product.collectionId?.value !== CollectionId.MARKETING_ID ?
                            (
                                <div style={{ marginBottom: '20px' }}>
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        onClick={handleShow}
                                    >
                                        {t(ADD_TO_CART)}
                                    </button>
                                </div>
                            ) : null}
                    </div>
                </div>
            </div>

            { productDetail ? (
                <ShoppingModal
                    show={show}
                    showForce={showForce}
                    onHideShop={handleClose}
                    productDetails={productDetail}
                    handleCloseForce={handleCloseForce}
                    handleShowForce={handleShowForce}
                    refreshProductData={getProductDetails}
                />
            ) : null}

        </div>
    );
};
