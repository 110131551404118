import React from 'react';
import { DropdownButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Currency } from 'app/shared/components/Currency';
import { Invoice } from '../../../domain/model/Invoice';
import { INVOICES_VIEW_INVOICE_DETAILS } from '../../../../../i18n/translations/TR';
import { ReactComponent as IcVert } from '../../../../../assets/img/icons/ic-more-vert.svg';
import { Discount } from '../../../../orders/model/value-objects/Discount';
import { InvoiceNumber } from '../../../domain/vo/InvoiceNumber';

interface Props {
    invoice: Invoice;
    goToDetails: (id: InvoiceNumber) => void;
}

export const InvoiceTableItem = (props: Props): React.ReactElement => {
    const { t } = useTranslation();
    return (
        <tr key={props.invoice.id.toString()}>
            <td className="table-invoice-order-number">{props.invoice.orderNumber.value}</td>
            <td className="table-invoice-date">
                {props.invoice.date
                    .toLocaleDateString(
                        undefined,
                        { year: 'numeric', month: 'short', day: 'numeric' },
                    )}
            </td>
            <td className="table-invoice-brute">
                <Currency
                    value={props.invoice.bruteAmount.amount}
                />
            </td>
            <td className="table-invoice-discount">
                <Currency
                    value={props.invoice.discount.value ?? Discount.zero().value}
                />
            </td>
            <td className="table-invoice-net">
                <Currency
                    value={props.invoice.netAmount.amount}
                />
            </td>
            <td className="table-invoice-actions">
                <div className="dropdown d-flex justify-content-end">
                    <DropdownButton
                        key="start"
                        id="dropdown-button-drop-end"
                        drop="down"
                        variant="transparent"
                        title={<IcVert className="iconDots" />}
                        className="noButtonAppearance overflow-menu"
                    >
                        <li
                            className="dropdown-item"
                            onClick={(): void => props.goToDetails(props.invoice.number)}
                            role="presentation"
                        >
                            {t(INVOICES_VIEW_INVOICE_DETAILS)}
                        </li>
                    </DropdownButton>
                </div>
            </td>
        </tr>
    );
};
