import React, { FormEvent, FormEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Offcanvas } from 'react-bootstrap';
import { useOrigin } from 'app/shared/hooks/useOrigin';
import {
    APPLY_FILTERS,
    REMOVE_FILTERS,
    FILTERS,
    ORDER_PHASE_PREPARING,
    ORDER_PHASE_PREPARED,
    ORDER_PHASE_ORDERED,
    ORDER_PHASE_CARRIED,
    ORDER_PHASE_INVOICED,
    ORDER_FILTER_LABEL_STATUS,
    ORDER_FILTER_LABEL_PO,
    INPUT_TYPE_TO_SEARCH,
    ORDER_FILTER_LABEL_DATE_FROM,
    ORDER_FILTER_LABEL_DATE_TO,
    ORDER_FILTER_DEFAULT_LABEL,
    SELECT, ORDER_PHASE_OPEN, ORDER_PHASE_RELEASED,
} from '../../../../../i18n/translations/TR';
import { OrderFilterCollection } from '../../../model/OrderFilterCollection';
import { OrderPhase } from '../../../model/OrderPhase';
import { OrderFilter } from '../../../model/OrderFilter';
import { OrderFilterName } from '../../hooks/useOrderFilters';
import { Origin } from '../../../../shared/domain/value-objects/Origin';

interface Props {
    show: boolean;
    onHide: () => void;
    onApplyFilters: (filters: OrderFilterCollection) => void;
}

export const FilterOrdersModal: React.FC<Props> = ({ show, onHide, onApplyFilters }) => {
    const { t } = useTranslation();

    const [filters, setFilters] = React.useState<OrderFilterCollection>(new OrderFilterCollection([]));

    const [orderStatus, setOrderStatus] = React.useState<string>();
    React.useEffect(() => {
        if (orderStatus) {
            filters.addFilter(new OrderFilter(OrderFilterName.PHASE, orderStatus));
        } else {
            filters.removeFilter(OrderFilterName.PHASE);
        }
        setFilters(filters);
    }, [orderStatus]);

    const [orderPO, setOrderPO] = React.useState<string>('');
    React.useEffect(() => {
        if (orderPO) {
            filters.addFilter(new OrderFilter(OrderFilterName.PO, orderPO));
        } else {
            filters.removeFilter(OrderFilterName.PO);
        }
        setFilters(filters);
    }, [orderPO]);

    const [orderDateFrom, setOrderDateFrom] = React.useState<string>();
    React.useEffect(() => {
        if (orderDateFrom) {
            filters.addFilter(new OrderFilter(OrderFilterName.START_DATE, moment(orderDateFrom).unix().toString()));
        } else {
            filters.removeFilter(OrderFilterName.START_DATE);
        }
        setFilters(filters);
    }, [orderDateFrom]);

    const [orderDateTo, setOrderDateTo] = React.useState<string>();
    React.useEffect(() => {
        if (orderDateTo) {
            filters.addFilter(new OrderFilter(OrderFilterName.FINAL_DATE, moment(orderDateTo).unix().toString()));
        } else {
            filters.removeFilter(OrderFilterName.FINAL_DATE);
        }
        setFilters(filters);
    }, [orderDateTo]);

    const handleSubmit: FormEventHandler = (event: FormEvent) => {
        event.preventDefault();
        onApplyFilters(filters);
    };
    const handleReset: FormEventHandler = () => {
        [OrderFilterName.PO,
            OrderFilterName.PHASE,
            OrderFilterName.START_DATE,
            OrderFilterName.FINAL_DATE,
        ].forEach((filterName: string) => {
            filters.removeFilter(filterName);
        });
        setOrderStatus('');
        setOrderPO('');
        setOrderDateFrom('');
        setOrderDateTo('');
    };

    const UseOrigin = useOrigin();
    const renderOriginStatusOptions = (): React.ReactElement => {
        if (UseOrigin === Origin.EUROPE) {
            return (
                <>
                    <option value={OrderPhase.PREPARED}>{t(ORDER_PHASE_PREPARED)}</option>
                    <option value={OrderPhase.ORDERED}>{t(ORDER_PHASE_ORDERED)}</option>
                    <option value={OrderPhase.CARRIED}>{t(ORDER_PHASE_CARRIED)}</option>
                    <option value={OrderPhase.INVOICED}>{t(ORDER_PHASE_INVOICED)}</option>
                    <option value={OrderPhase.PREPARING}>{t(ORDER_PHASE_PREPARING)}</option>
                </>
            );
        }
        return (
            <>
                <option value={OrderPhase.OPEN}>{t(ORDER_PHASE_OPEN)}</option>
                <option value={OrderPhase.RELEASED}>{t(ORDER_PHASE_RELEASED)}</option>
            </>
        );
    };

    return (
        <Offcanvas
            show={show}
            placement="end"
            className="offcanvas"
            onHide={(): void => {
                onHide();
            }}
        >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>{t(FILTERS)}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <form id="filter-form" onSubmit={handleSubmit}>
                    <h6>{t(ORDER_FILTER_DEFAULT_LABEL)}</h6>
                    <div className="mb-4">
                        <label htmlFor="order-status-filter-select" className="form-label">
                            {t(ORDER_FILTER_LABEL_STATUS)}
                        </label>
                        <select
                            value={orderStatus}
                            id="order-status-filter-select"
                            className="form-select"
                            aria-label={t(ORDER_FILTER_LABEL_STATUS)}
                            onChange={(e): void => {
                                setOrderStatus(e.target.value.length > 0 ? e.target.value : '');
                            }}
                        >
                            <option value="">{t(SELECT)}</option>
                            {renderOriginStatusOptions()}
                        </select>
                    </div>

                    <div className="mb-4">
                        <label htmlFor="order-po-filter-input" className="form-label">
                            {t(ORDER_FILTER_LABEL_PO)}
                        </label>
                        <input
                            className="form-control"
                            list="datalistOptions"
                            id="order-po-filter-input"
                            placeholder={t(INPUT_TYPE_TO_SEARCH)}
                            value={orderPO}
                            onChange={(e): void => {
                                setOrderPO(e.target.value.length > 0 ? e.target.value : '');
                            }}
                        />
                    </div>

                    <div className="mb-4">
                        <div className="row">
                            <div className="col-6">
                                <label htmlFor="order-date-from-filter-input" className="form-label">
                                    {t(ORDER_FILTER_LABEL_DATE_FROM)}
                                </label>
                                <input
                                    type="date"
                                    className="form-control"
                                    id="order-date-from-filter-input"
                                    value={orderDateFrom}
                                    onChange={(e): void => {
                                        setOrderDateFrom(e.target.value);
                                    }}
                                />
                            </div>
                            <div className="col-6">
                                <label htmlFor="order-date-to-filter-input" className="form-label">
                                    {t(ORDER_FILTER_LABEL_DATE_TO)}
                                </label>
                                <input
                                    type="date"
                                    className="form-control"
                                    id="order-date-to-filter-input"
                                    value={orderDateTo}
                                    onChange={(e): void => {
                                        setOrderDateTo(e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </Offcanvas.Body>
            <div className="filter-footer">
                <button
                    type="submit"
                    className="btn btn-link text-decoration-none"
                    onClick={handleReset}
                >
                    {t(REMOVE_FILTERS)}
                </button>
                <button type="submit" className="btn btn-primary" data-bs-dismiss="offcanvas" form="filter-form">
                    {t(APPLY_FILTERS)}
                </button>
            </div>
        </Offcanvas>
    );
};
