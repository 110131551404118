import React from 'react';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'app/shared/components/Spinner';
import { AGENT, OBLIGATORY_FIELD_ERROR, SELECT_AN_OPTION } from '../../../../../../../../i18n/translations/TR';
import { Courier } from '../../../../../../domain/entity/Courier';

interface CourierFieldProps {
    isLoading: boolean
    couriers: Courier[]
    courierFieldError: FieldError | undefined
    courierFieldRegister: UseFormRegisterReturn
}

export const CourierField = (props: CourierFieldProps): React.ReactElement => {
    const { t } = useTranslation();

    return (
        <div className="transport-form-container-item__courier">
            <label
                htmlFor="courier"
                className="form-label"
            >
                <span>{t(AGENT)}</span>
            </label>
            <div className="row">
                <div className="col-lg-12">
                    {props.isLoading ? (
                        <Spinner classCss="spinner-container" />
                    ) : (
                        <select
                            id="courier"
                            className="form-select"
                            {...props.courierFieldRegister}
                        >
                            <option value="">{t(SELECT_AN_OPTION)}</option>
                            {props.couriers.map((courier) => (
                                <option
                                    key={courier.id.value}
                                    value={courier.id.value}
                                >
                                    {courier.name}
                                </option>
                            ))}
                        </select>
                    )}
                </div>
            </div>
            {props.courierFieldError ? (
                <div className="error-input-form">
                    {t(OBLIGATORY_FIELD_ERROR)}
                </div>
            ) : null}
        </div>
    );
};
