import { useTranslation } from 'react-i18next';
import { FEET2, M2_UNIT } from '../../../i18n/translations/TR';
import { useOrigin } from '../hooks/useOrigin';

export const getSquareMeasurement = (): string => {

    const { t } = useTranslation();
    const origin = useOrigin();

    let unitString: string = '';

    if (origin === 'EUROPE') {
        unitString = t(M2_UNIT);
    } else if (origin === 'USA') {
        unitString = t(FEET2);
    }

    return unitString;
};
