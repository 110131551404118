import { ProductId } from 'app/product/domain/valueObject/ProductId';
import CartItem from '../../../../domain/model/CartItem';
import { CartItemId } from '../../../../domain/value-objects/CartItemId';
import { Item } from '../../../../shared/ApiItem';
import { Price } from '../../../../../orders/model/value-objects/Price';
import ProductQuantity from '../../../../../product/domain/valueObject/ProductQuantity';
import { Weight } from '../../../../../orders/model/value-objects/Weight';
import { LotDataMapper } from '../../../../../product/infrastructure/repository/api/product/dataMapper/LotDataMapper';

export class CartItemMapper {

    static fromResponse(response: Item): CartItem {
        return new CartItem(
            new CartItemId(response.id),
            new ProductId(response.product_id),
            new Price(response.price.amount),
            new Price(response.unit_price.amount),
            new Price(response.discount?.amount ?? 0.0),
            new Price(response.discount2?.amount ?? 0.0),
            new Weight(response.weight.amount),
            new ProductQuantity(
                response.quantity.amount ? parseFloat(response.quantity.amount.toFixed(3)) : 0,
                response.quantity.unit,
            ),
            LotDataMapper.fromResponse(response.lot),
            response.quantity_in_pallets,
            response.quantity_in_boxes,
            response.quantity_in_pieces,
            response.out_of_stock,
        );
    }

}
