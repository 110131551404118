import { GetCollectionsRequest } from '../../../../../shared/infrastructure/tencer-api/dto/requests/GetCollectionsRequest';
import { CollectionTypeDataMapper } from './CollectionTypeDataMapper';
import { CollectionType } from '../../../../domain/valueObject/CollectionType';
import { Pagination } from '../../../../../shared/domain/value-objects/Pagination';
import { PaginationRequestDataMapper } from '../../../../../shared/infrastructure/tencer-api/data-mapper/PaginationRequestDataMapper';

export class GetCollectionsRequestDataMapper {

    public static fromDomain(
        type: CollectionType,
        textSearch: string,
        pagination: Pagination,
    ): GetCollectionsRequest {
        return {
            payload: {
                type: CollectionTypeDataMapper.fromDomain(type),
                textSearch: textSearch ?? undefined,
                pagination: PaginationRequestDataMapper.fromDomain(pagination),
            },
        };
    }

}
