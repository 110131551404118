import React, { ReactElement } from 'react';
import './HomeSearcherItem.scss';
import { useTranslation } from 'react-i18next';
import { Product } from '../../../../product/domain/entity/Product';
import { OUTLET } from '../../../../../i18n/translations/TR';

interface HomeSearcherItemProps {
    product: Product
}

export const HomeSearcherItem = (props: HomeSearcherItemProps): ReactElement => {
    const { t } = useTranslation();

    return (
        <div className="component-home-searcher-item">
            <div className="home-searcher-item-image-container">
                {props.product.image?.value ? (
                    <img
                        alt="product"
                        src={props.product.image.value}
                        className="home-searcher-item-image"
                    />
                ) : null}
            </div>
            <div className="home-searcher-item-details">
                <div className="home-searcher-item-details-header">
                    <div className="home-searcher-item-details-header-name">
                        {props.product.description}
                    </div>
                    {props.product.outlet ? (
                        <div className="home-searcher-item-details-header-tag">
                            {t(OUTLET)}
                        </div>
                    ) : null}
                </div>
                <div className="home-searcher-item-details-subtitle">
                    {props.product.collectionName?.value}
                </div>
            </div>
        </div>
    );
};
