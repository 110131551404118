import { useTranslation } from 'react-i18next';
import { PER_PIECE, PER_M2, PER_FEET } from '../../../i18n/translations/TR';
import { useOrigin } from '../hooks/useOrigin';
import { ProductUnit } from '../../product/domain/valueObject/ProductUnit';
import { Origin } from '../domain/value-objects/Origin';

export const getArea = (unit: ProductUnit): string => {
    const origin = useOrigin();
    const { t } = useTranslation();

    if (unit.isPiece()) {
        return t(PER_PIECE);
    }

    return origin && origin.match(Origin.EUROPE) ? t(PER_M2) : t(PER_FEET);
};
