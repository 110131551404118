import React from 'react';
import { Spinner } from 'app/shared/components/Spinner';
import { ServerErrorMessage } from 'app/shared/components/ServerErrorMessage';
import { DownloadsBanner } from 'app/shared/components/DownloadBanner';
import { ProductDataSheet } from './productDataSheet/ProductDataSheet';
import { WarehousesInfo } from './warehousesInfo/WarehousesInfo';
import { useProductDetailScreenController } from './useProductDetailScreenController';
import './ProductDetailScreen.scss';

export const ProductDetailScreen: React.FC = () => {
    const controller = useProductDetailScreenController();

    if (controller.loading) {
        return <Spinner classCss="header-section-spinner" />;
    }

    if (controller.error) {
        return <ServerErrorMessage />;
    }

    if (!controller.data) {
        return null;
    }

    return (
        <div className="component-product-detail-screen">
            <div className="row bg-white">
                <div className="col-xl-8">
                    <ProductDataSheet
                        detail={controller.data}
                        refreshProductData={controller.fetchGetProduct}
                        outOfStockWarehousesLocations={controller.outOfStockWarehousesLocations}
                    />
                </div>

                <div className="col-xl-4">
                    {controller.data?.lots.length > 0 ? (
                        <WarehousesInfo
                            details={controller.data}
                            outOfStockWarehousesLocations={controller.outOfStockWarehousesLocations}
                        />
                    ) : null}
                </div>
            </div>

            <DownloadsBanner />
        </div>
    );
};
