import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { STIMATED_PRODUCTION_DATE } from '../../../../../../../i18n/translations/TR';

interface EstimatedProductionDateLabelProps {
    amount: number,
    locale: string,
    isOutlet: boolean,
    stockEntryDate: string|undefined,
}

export const EstimatedProductionDateLabel = (props: EstimatedProductionDateLabelProps): ReactElement | null => {
    const { t } = useTranslation();

    const stockInProduction = (): string | null => {
        if (!props.isOutlet && props.amount > 0 && props.stockEntryDate?.length) {
            return `${t(STIMATED_PRODUCTION_DATE)}: ${new Date(props.stockEntryDate).toLocaleDateString(props.locale).replace(/\//g, '-')}`;
        }

        return null;
    };

    return (
        stockInProduction() ? (
            <div className="product-stock-label">
                <span className="product-stock stock-in-production">{stockInProduction()}</span>
            </div>
        ) : null
    );
};
