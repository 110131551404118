import i18next from 'i18next';
import { FEET2, M2_UNIT, PALLET_UNIT, PALLETS, PIECE_UNIT, PIECE_UNIT_SINGLE } from '../../../../i18n/translations/TR';
import { AmountUnit } from '../../../shared/domain/value-objects/AmountUnit';

export default class ProductQuantity {

    public constructor(
        private readonly _amount: number,
        private readonly _unit: AmountUnit,
    ) {}

    public get amount(): number {
        return this._amount;
    }

    public get unit(): AmountUnit {
        return this._unit;
    }

    public unitLabel = (origin: string|undefined): string => {
        if (this._unit.name === 'Unit') {
            if (this._amount > 1) {
                return `${i18next.t(PIECE_UNIT)}`;
            }
            return `${i18next.t(PIECE_UNIT_SINGLE)}`;
        }

        if (this._unit.name === 'SF') {
            return i18next.t(FEET2);
        }

        if (this._unit.name === 'PALLET') {
            return this._amount > 1 ? i18next.t(PALLETS) : i18next.t(PALLET_UNIT);
        }

        if (origin === 'USA') {
            return i18next.t(FEET2);
        }
        return i18next.t(M2_UNIT);
    };

}
