import { ProductDetailsResponse } from 'app/shared/infrastructure/tencer-api/dto/responses/ProductDetailsResponse';
import { ProductDetails } from '../../../../../domain/entity/ProductDetails';
import { ProductDataMapper } from './ProductDataMapper';
import { LotDataMapper } from './LotDataMapper';
import { ProductQuantityDataMapper } from './ProductQuantityDataMapper';

export class ProductDetailsDataMapper {

    public static fromResponse(response: ProductDetailsResponse): ProductDetails {
        return new ProductDetails(
            ProductDataMapper.fromResponse(response),
            response.lotInfo.map(LotDataMapper.fromResponse),
            ProductQuantityDataMapper.fromResponse(response.stock),
        );
    }

}
