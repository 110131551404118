import { CollectionRepository } from '../repository/CollectionRepository';
import { CollectionDetails } from '../entity/CollectionDetails';

interface GetMarketingUseCaseProps {
    textSearch: string,
    collectionRepository: CollectionRepository,
}

export default async (props: GetMarketingUseCaseProps): Promise<CollectionDetails> => {
    try {
        return await props.collectionRepository.findMarketing(props.textSearch);
    } catch (error) {
        return Promise.reject(error);
    }
};
