import { Price } from './Price';
import { OrderId } from '../../../shared/domain/value-objects/OrderId';

export default class PickHandleOrderLine {

    public constructor(
        private _orderId: OrderId,
        private _price: Price,
        private _description: string,
    ) {}

    public get orderId(): OrderId {
        return this._orderId;
    }

    public get price(): Price {
        return this._price;
    }

    public get description(): string {
        return this._description;
    }

}
