export class WarehouseLocation {

    public constructor(
        private readonly _code: string,
        private readonly _name: string,
    ) {}

    public get code(): string {
        return this._code;
    }

    public get name(): string {
        return this._name;
    }

}
