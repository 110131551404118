import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { Container, Dropdown, Nav, Navbar } from 'react-bootstrap';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import classNames from 'classnames';
import {
    ACCOUNT,
    DOWNLOADS,
    FAQ,
    INVOICES,
    LOADING_ORDERS,
    LOG_OUT_NAVBAR,
    MENU_OPTION_MARKETING,
    ORDERS,
    OUTLET,
    PRODUCTS,
    SEARCH,
} from '../../../i18n/translations/TR';
import { WOW_DOWNLOADS_HREF } from '../constants/hrefConstants';
import { CartInPaymentProcessModal } from './modals/CartInPaymentProcessModal';
import { ReactComponent as LogoWowBlack } from '../../../assets/img/logoWowBlack.svg';
import { CartInPaymentGateway } from './modals/CartInPaymentGatewayModal';
import { TencerRoutes } from '../tencer-routing/TencerRoutes';
import { CurrentUserContext } from '../context/CurrentUserContext';
import { ReactComponent as IcMenu } from '../../../assets/img/icons/ic-menu.svg';
import { ReactComponent as IcShopBag } from '../../../assets/img/icons/ic-shop-bag.svg';
import { ReactComponent as Account } from '../../../assets/img/icons/account.svg';
import { ReactComponent as Logout } from '../../../assets/img/icons/ic-logout.svg';
import { useToken } from '../hooks/useToken';
import { useOrigin } from '../hooks/useOrigin';
import { Origin } from '../domain/value-objects/Origin';
import { TencerAuthServiceInstance } from '../tencer-routing/tencer-auth-route/TencerAuthService';
import { LanguageSelector } from './LanguageSelector/LanguageSelector';
import { TencerApiClientSingleton } from '../infrastructure/tencer-api/TencerApiClientSingleton';
import { TencerLanguage } from '../../../i18n';
import { usePermission } from '../hooks/usePermission';
import { useIsMounted } from '../hooks/useIsMounted';
import CartStatusMapper from '../../cart/infraestructure/repository/api/data-mapper/CartStatusMapper';
import { CartStatus } from '../../cart/domain/value-objects/CartStatus';
import { SearchContext } from '../context/SearchContext';

interface HeaderSearchFromRegister {
    search: string,
}

interface HeaderProps {
    showSearch: boolean
}

export const Header = (props: HeaderProps): ReactElement => {
    const isMounted = useIsMounted();
    const location = useLocation();
    const origin = useOrigin();
    const UseToken = useToken();
    const navigate = useNavigate();
    const permission = usePermission();
    const { t, i18n } = useTranslation();
    const state = useContext(CurrentUserContext);
    const searchContext = useContext(SearchContext);
    const { register, handleSubmit, reset, setValue } = useForm<HeaderSearchFromRegister>();
    const [showCartInPaymentGateway, setShowCartInPaymentGateway] = useState(false);
    const [showCartInPaymentProcessModal, setShowCartInPaymentProcessModal] = useState(false);

    const button = document.getElementById('toggleButton');
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const FAQ_URL = 'https://www.wowdesigneu.com/faqs/';

    const closeMobileMenu = (): void => {
        if (window.location.pathname === TencerRoutes.CATALOG ||
            window.location.pathname === TencerRoutes.COLLECTIONS) {
            if (button != null && isMobile) {
                button.click();
            }
        }
    };

    const submitHandler: SubmitHandler<HeaderSearchFromRegister> = async (data: HeaderSearchFromRegister) => {
        closeMobileMenu();
        searchContext.handleSearch(data.search);
        setValue('search', data.search);
    };

    const logout = (): void => {
        state?.handleLogout();
    };

    const goToCart = (): void => {
        navigate(TencerRoutes.CART);
    };

    const handleCartStatusError = (cartStatus: CartStatus): void => {
        if (cartStatus.isInPaymentProcess() || cartStatus.isInPaymentGateway()) {
            navigate(TencerRoutes.HOME);
        }
        setShowCartInPaymentGateway(cartStatus.isInPaymentGateway());
        setShowCartInPaymentProcessModal(cartStatus.isInPaymentProcess());
    };

    const refreshCart = (): void => {
        const APIClient = TencerApiClientSingleton.getInstance();
        APIClient.getMyCartStatus()
            .then((response) => {
                if (!isMounted) {
                    return;
                }
                try {
                    const cartStatus = CartStatusMapper.fromResponse(response);
                    handleCartStatusError(cartStatus);
                } catch (e) {
                    state?.setCartItems(undefined);
                    state?.setExpirationDate(null);
                    setShowCartInPaymentProcessModal(false);
                }
            });
    };

    const changeLanguage = (lng: TencerLanguage): void => {
        i18n.changeLanguage(lng);
        localStorage.setItem('lang', lng);
    };

    useEffect(() => {
        searchContext.cleanSearch();
        setValue('search', '');
    }, [location.pathname]);

    useEffect(() => {
        setValue('search', searchContext.search);
    }, [searchContext.search]);

    useEffect(() => {
        if (state?.cartModified) {
            refreshCart();
        }
    }, [state?.cartModified]);

    useEffect(() => {
        reset({ search: '' });
        refreshCart();
    }, []);

    return (
        <>
            <Navbar expand="lg" className="fixed-top">
                <Container fluid>
                    <Navbar.Brand>
                        <Link to="/" className="navbar-brand">
                            <LogoWowBlack style={{ height: '3.5rem' }} />
                        </Link>

                        <ul className="icons-nav">
                            <Dropdown className="dropstart">
                                <Dropdown.Toggle
                                    className="dropdown-toggle bg-transparent btn-link"
                                    variant="success"
                                    id="dropdown-basic"
                                >
                                    <Account />
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="dropdown-menu user-container">
                                    <Dropdown.Item
                                        className="dropdown-item"
                                        onClick={(): void => navigate(TencerRoutes.ACCOUNT)}
                                    >
                                        <div className="user-container-item">
                                            <Account />
                                            <span>{t(ACCOUNT)}</span>
                                        </div>
                                    </Dropdown.Item>
                                    <Dropdown.Item className="dropdown-item" onClick={logout}>
                                        {TencerAuthServiceInstance.getToken() !== '' ? (
                                            <div className="user-container-item">
                                                <Logout />
                                                <span>{t(LOG_OUT_NAVBAR)}</span>
                                            </div>
                                        ) : null}
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            {!permission.canDoOrders() ? null : (
                                <li className="nav-item" onClick={goToCart} role="presentation">
                                    <span className="position-relative">
                                        <IcShopBag />
                                        <span
                                            className="position-absolute
                                            translate-middle badge badge-timer"
                                        >
                                            {state?.timer ? state.timer : ('')}
                                        </span>
                                        {state?.cartItems && state.cartItems > 0 ? (
                                            <span className="cart-counter">{state.cartItems}</span>
                                        ) : null}
                                    </span>
                                </li>
                            )}
                        </ul>

                    </Navbar.Brand>

                    <Navbar.Toggle aria-controls="basic-navbar-nav" className="border-0" id="toggleButton">
                        <IcMenu />
                    </Navbar.Toggle>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="navbar-nav mr-auto navbar-options-container">
                            {
                                UseToken.permissions.permissionsName.includes('LIST') ?
                                    (
                                        <>
                                            <NavLink
                                                className={(isActive): string => {
                                                    if (isActive.isActive) {
                                                        return 'link-active';
                                                    }
                                                    return 'link-inactive';
                                                }}
                                                to={TencerRoutes.CATALOG}
                                                children={<span>{t(PRODUCTS)}</span>}
                                            />
                                            <NavLink
                                                className={(isActive): string => classNames(
                                                    { 'link-active': isActive.isActive },
                                                    { 'link-inactive': !isActive.isActive },
                                                )}
                                                to={TencerRoutes.PRODUCT_OUTLET}
                                                children={<span>{t(OUTLET)}</span>}
                                            />
                                        </>
                                    )
                                    : null
                            }

                            {
                                origin && origin.match(Origin.USA) ?
                                    (
                                        <NavLink
                                            className={(isActive): string => {
                                                if (isActive.isActive) {
                                                    return 'link-active';
                                                }
                                                return 'link-inactive';
                                            }}
                                            to={TencerRoutes.MARKETING}
                                            children={<span>{t(MENU_OPTION_MARKETING)}</span>}
                                        />
                                    )
                                    : null
                            }

                            {
                                permission.canDoOrders() ?
                                    (
                                        <NavLink
                                            className={(isActive): string => {
                                                if (isActive.isActive) {
                                                    return 'link-active';
                                                }
                                                return 'link-inactive';
                                            }}
                                            to={TencerRoutes.ORDERS}
                                            children={<span>{t(ORDERS)}</span>}
                                        />
                                    )
                                    : null
                            }
                            {
                                permission.canViewInvoices() ?
                                    (
                                        <NavLink
                                            className={(isActive): string => {
                                                if (isActive.isActive) {
                                                    return 'link-active';
                                                }
                                                return 'link-inactive';
                                            }}
                                            to={TencerRoutes.INVOICES}
                                            children={<span>{t(INVOICES)}</span>}
                                        />
                                    )
                                    : null
                            }

                            {
                                permission.canManageLoadingOrders() ?
                                    (
                                        <NavLink
                                            className={(isActive): string => {
                                                if (isActive.isActive) {
                                                    return 'link-active';
                                                }
                                                return 'link-inactive';
                                            }}
                                            to={TencerRoutes.LOADING_ORDERS}
                                            children={<span>{t(LOADING_ORDERS)}</span>}
                                        />
                                    )
                                    : null
                            }

                            <NavLink
                                className={(isActive): string => {
                                    if (isActive.isActive) {
                                        return 'link-active';
                                    }
                                    return 'link-inactive';
                                }}
                                to={WOW_DOWNLOADS_HREF}
                                children={<span>{t(DOWNLOADS)}</span>}
                                target="_blank"
                            />
                            {
                                UseToken.permissions.permissionsName.includes('LIST') && props.showSearch ?
                                    (

                                        <Nav.Item className="w-100">
                                            <form onSubmit={handleSubmit(submitHandler)} className="d-flex">
                                                <input
                                                    className="form-control mx-2"
                                                    type="text"
                                                    placeholder={t(SEARCH)}
                                                    {...register('search')}
                                                />
                                                <button
                                                    className="btn btn-primary-outline btn-mini"
                                                    data-toggle="collapse"
                                                    type="submit"
                                                >
                                                    {t(SEARCH)}
                                                </button>
                                            </form>
                                        </Nav.Item>
                                    )
                                    : <div className="w-100">{}</div>
                            }
                            <div className="col-sm-1 col-md-1">
                                <a
                                    href={FAQ_URL}
                                    target="_blank"
                                    className="link-faq"
                                    rel="noreferrer"
                                >
                                    {t(FAQ)}
                                </a>
                            </div>

                            <div className="col-sm-1 col-md-1">
                                <LanguageSelector changeLanguage={changeLanguage} />
                            </div>

                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <CartInPaymentProcessModal
                onCheckingProgress={refreshCart}
                showModal={showCartInPaymentProcessModal}
            />

            <CartInPaymentGateway
                showModal={showCartInPaymentGateway}
                closeModal={(): void => {
                    setShowCartInPaymentGateway(false);
                }}
            />
        </>
    );
};
