import { useEffect, useState } from 'react';
import { useIsMounted } from 'app/shared/hooks/useIsMounted';
import { usePagination, usePaginationInterface } from 'app/shared/hooks/usePagination';
import { OrderId } from 'app/shared/domain/value-objects/OrderId';
import { useNavigate } from 'react-router-dom';
import { TencerApiClientSingleton } from '../../../shared/infrastructure/tencer-api/TencerApiClientSingleton';
import { OrderFilterCollection } from '../../model/OrderFilterCollection';
import { ShippingAddress } from '../../model/ShippingAddress';
import { ShippingAddressId } from '../../model/ShippingAddressId';
import Order from '../../model/Order';
import { useOrderRepository } from './useOrderRepository';
import { useOrderFilters, useOrderFiltersInterface } from './useOrderFilters';
import { useProforma } from './useProforma';
import { useToken } from '../../../shared/hooks/useToken';

export interface useOrdersListInterface {
    goToDetails(id: OrderId): void,
    orders: Order[],
    fetch: () => void,
    paginator: usePaginationInterface,
    orderFilters: useOrderFiltersInterface,
    handleApplyFilters: (filters: OrderFilterCollection) => void,
    getShippingAddress: (id: ShippingAddressId | null) => string,
    downloadProforma(orderId: OrderId): void,
    downloadingProforma: boolean,
    isLoading: boolean,
}

export const useOrdersList = (): useOrdersListInterface => {

    const apiClient = TencerApiClientSingleton.getInstance();
    const orderRepository = useOrderRepository();
    const isMounted = useIsMounted();
    const history = useNavigate();
    const proforma = useProforma();

    const paginator = usePagination();

    const [orders, setOrders] = useState<Order[]>([]);
    const isLoading = orderRepository.fetchUserOrdersMutation.isLoading;
    const orderFilters = useOrderFilters();
    const token = useToken();

    const fetch = ():void => orderRepository.fetchUserOrdersMutation.mutate(
        {
            filters: orderFilters.filters,
            actualPage: paginator.actualPage,
            resultsPerPage: paginator.resultsPerPage,
            confirmed: true,
        },
        {
            onSuccess: (result: Order[]) => {
                if (!isMounted) {
                    return;
                }
                const allResults = [...orders, ...result];
                setOrders(allResults);
                paginator.refresh(result.length);
            },
        },
    );

    const downloadProforma = (orderId: OrderId):void => proforma.download(orderId);
    const downloadingProforma = proforma.proformaLoading;

    const handleApplyFilters = (appliedFilters: OrderFilterCollection): void => {
        orderFilters.applyFilters(appliedFilters);
        paginator.setActualPage(1);
        setOrders([]);
    };
    const [shippingAddresses, setShippingAddresses] = useState<ShippingAddress[]>([]);

    const getShippingAddress = (id: ShippingAddressId | null): string => {
        const emptyAddress = '';
        if (!id) {
            return emptyAddress;
        }
        const shippingAddress = shippingAddresses.find((sa) => sa.code.equals(id));
        return shippingAddress ? shippingAddress.description : emptyAddress;
    };

    const goToDetails = (id: OrderId): void => {
        history(`/orders/${id.value}`);
    };

    useEffect(() => {
        const erpCode = token?.erpCode;
        if (!erpCode) return;
        apiClient.getShippingAddresses(erpCode).then((response) => {
            if (!isMounted) {
                return;
            }
            setShippingAddresses(response);
        });
    }, []);

    useEffect(() => {
        paginator.setActualPage(1);
        setOrders([]);
        if (token) {
            fetch();
        }
    }, [orderFilters.filters]);

    return {
        orders,
        fetch,
        paginator,
        orderFilters,
        handleApplyFilters,
        getShippingAddress,
        goToDetails,
        downloadProforma,
        downloadingProforma,
        isLoading,
    };
};
