import { ProductId } from '../valueObject/ProductId';
import { ProductPrice } from '../valueObject/ProductPrice';
import { CollectionId } from '../../../collection/domain/valueObject/CollectionId';
import { Image } from '../valueObject/Image';
import { CollectionName } from '../../../collection/domain/valueObject/CollectionName';
import { ProductFormat } from '../valueObject/ProductFormat';
import { Weight } from '../../../orders/model/value-objects/Weight';
import { ProductUnit } from '../valueObject/ProductUnit';

export class Product {

    public constructor(
        private _id: ProductId,
        private _outlet: boolean,
        private _default: boolean,
        private _enabled: boolean,
        private _heights: number,
        private _boxPiece: number,
        private _boxMeter: number,
        private _boxMeterL: number,
        private _boxWeight: Weight,
        private _palletBox: number,
        private _palletPlane: number,
        private _palletPiece: number,
        private _palletMeter: number,
        private _box: string | null,
        private _type: string | null,
        private _tile: string | null,
        private _code: string | null,
        private _paste: string | null,
        private _family: string | null,
        private _unit: ProductUnit | null,
        private _description: string | null,
        private _image: Image | null = null,
        private _price: ProductPrice | null,
        private _stockEntryDate: Date | null,
        private _format: ProductFormat | null,
        private _collectionId:CollectionId | null,
        private _collectionName: CollectionName | null,
    ) {}

    public get id(): ProductId {
        return this._id;
    }

    public get outlet(): boolean {
        return this._outlet;
    }

    public get default(): boolean {
        return this._default;
    }

    public get enabled(): boolean {
        return this._enabled;
    }

    public get heights(): number {
        return this._heights;
    }

    public get boxPiece(): number {
        return this._boxPiece;
    }

    public get boxMeter(): number {
        return this._boxMeter;
    }

    public get boxMeterL(): number {
        return this._boxMeterL;
    }

    public get boxWeight(): Weight {
        return this._boxWeight;
    }

    public get palletBox(): number {
        return this._palletBox;
    }

    public get palletPlane(): number {
        return this._palletPlane;
    }

    public get palletPiece(): number {
        return this._palletPiece;
    }

    public get palletMeter(): number {
        return this._palletMeter;
    }

    public get box(): string | null {
        return this._box;
    }

    public get type(): string | null {
        return this._type;
    }

    public get tile(): string | null {
        return this._tile;
    }

    public get code(): string | null {
        return this._code;
    }

    public get unit(): ProductUnit | null {
        return this._unit;
    }

    public get paste(): string | null {
        return this._paste;
    }

    public get family(): string | null {
        return this._family;
    }

    public get description(): string | null {
        return this._description;
    }

    public get price(): ProductPrice | null {
        return this._price;
    }

    public get image(): Image | null {
        return this._image;
    }

    public get stockEntryDate(): Date | null {
        return this._stockEntryDate;
    }

    public get format(): ProductFormat | null {
        return this._format;
    }

    public get collectionId(): CollectionId | null {
        return this._collectionId;
    }

    public get collectionName(): CollectionName | null {
        return this._collectionName;
    }

}
