import React from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';
import { ModalError } from 'app/shared/components/modals/ModalError';
import { PageSpinner } from '../../../../shared/components/page-spinner/page-spinner';
import { SectionHeaderLoadingOrderDetail } from './header/section-header-loading-order-detail';
import { LOADING_ORDER_DETAIL_TITLE } from '../../../../../i18n/translations/TR';
import { useLoadingOrdersDetail } from '../../hooks/use-loading-order-detail';
import { LoadingOrderId } from '../../../domain/value-objects/loading-order-id';
import { TencerRoutes } from '../../../../shared/tencer-routing/TencerRoutes';
import { LoadingOrderDetailSummary } from './summary/loading-order-detail-summary';
import { LoadingOrderDetailTable } from './table/loading-order-detail-table';
import { usePermission } from '../../../../shared/hooks/usePermission';

export const LoadingOrderDetailScreen: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const UseLoadingOrdersDetail = useLoadingOrdersDetail();

    const permission = usePermission();
    if (!permission.canManageLoadingOrders()) {
        return <Navigate replace to={TencerRoutes.HOME} />;
    }

    return (
        <div className="screen">
            <PageSpinner isBusy={UseLoadingOrdersDetail.isBusy} />
            <div className="screen-loading-order-detail">
                <section className="loading-order-detail">
                    <div className="container">
                        <SectionHeaderLoadingOrderDetail
                            title={t(LOADING_ORDER_DETAIL_TITLE, { id: UseLoadingOrdersDetail.loadingOrderId })}
                            downloadPDF={(): void => {
                                UseLoadingOrdersDetail.downloadLoadingOrderPDF(
                                    new LoadingOrderId(UseLoadingOrdersDetail.loadingOrderId),
                                );
                            }}
                            goBack={(): void => {
                                navigate(TencerRoutes.LOADING_ORDERS);
                            }}
                            isDownloadingPDF={UseLoadingOrdersDetail.isDownloadingPDF}
                            loadingOrder={UseLoadingOrdersDetail.loadingOrder}
                        />

                        {UseLoadingOrdersDetail.loadingOrder ? (
                            <>
                                <LoadingOrderDetailSummary
                                    loadingOrder={UseLoadingOrdersDetail.loadingOrder}
                                />
                                <LoadingOrderDetailTable orders={UseLoadingOrdersDetail.loadingOrder.ordersToLoad} />
                            </>
                        ) : null}
                        <ModalError
                            show={UseLoadingOrdersDetail.getLoadingOrderError ||
                                UseLoadingOrdersDetail.downloadPdfError}
                            onHide={UseLoadingOrdersDetail.hideErrorModal}
                        />
                    </div>
                </section>
            </div>
        </div>
    );
};
