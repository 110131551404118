import { ProductDetails } from 'app/product/domain/entity/ProductDetails';
import { ProductId } from 'app/product/domain/valueObject/ProductId';
import { ProductRepository } from 'app/product/domain/repository/ProductRepository';

interface GetProductUseCaseProps {
    productId: ProductId,
    productRepository: ProductRepository,
}

export default async (props: GetProductUseCaseProps): Promise<ProductDetails> => {
    try {
        return await props.productRepository.findById(props.productId);
    } catch (error) {
        return Promise.reject(error);
    }
};
