import { Pagination } from 'app/shared/domain/value-objects/Pagination';
import { ProductRepository } from '../../../../domain/repository/ProductRepository';
import { ProductDetails } from '../../../../domain/entity/ProductDetails';
import { ProductId } from '../../../../domain/valueObject/ProductId';
import { ProductFilterCollection } from '../../../../../orders/model/ProductFilterCollection';
import { ProductDetailsDataMapper } from './dataMapper/ProductDetailsDataMapper';
import { TencerApiClient } from '../../../../../shared/infrastructure/tencer-api/TencerApiClient';
import { Product } from '../../../../domain/entity/Product';
import { ProductDataMapper } from './dataMapper/ProductDataMapper';
import { ProductType } from '../../../../domain/valueObject/ProductType';
import { GetProductsRequestDataMapper } from './dataMapper/GetProductsRequestDataMapper';

export class ApiProductRepository implements ProductRepository {

    public constructor(
        private readonly client: TencerApiClient,
    ) {}

    public async findById(productId: ProductId): Promise<ProductDetails> {
        const response = await this.client.getProduct(productId.value.trim());
        return ProductDetailsDataMapper.fromResponse(response);
    }

    public async findAll(
        type: ProductType,
        pagination: Pagination,
        productsId: ProductId[],
        textSearch: string,
        filters?: ProductFilterCollection,
    ): Promise<Product[]> {
        const response = await this.client.getProducts(
            GetProductsRequestDataMapper.fromDomain(
                type,
                pagination,
                productsId,
                textSearch,
                filters,
            ),
        );
        return response.map(ProductDataMapper.fromResponse);
    }

}
