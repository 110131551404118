import { ProductType } from '../../../../../domain/valueObject/ProductType';
import { ProductId } from '../../../../../domain/valueObject/ProductId';
import { ProductFilterCollection } from '../../../../../../orders/model/ProductFilterCollection';
import { GetProductsRequest } from '../../../../../../shared/infrastructure/tencer-api/dto/requests/GetProductsRequest';
import { PaginationRequestDataMapper } from '../../../../../../shared/infrastructure/tencer-api/data-mapper/PaginationRequestDataMapper';
import { ProductTypeDataMapper } from './ProductTypeDataMapper';
import { Pagination } from '../../../../../../shared/domain/value-objects/Pagination';

export class GetProductsRequestDataMapper {

    public static fromDomain(
        type: ProductType,
        pagination: Pagination,
        productsId: ProductId[],
        textSearch: string,
        filters?: ProductFilterCollection,
    ): GetProductsRequest {
        return {
            payload: {
                type: ProductTypeDataMapper.fromDomain(type),
                textSearch: textSearch ?? undefined,
                productId: productsId.map((productId) => productId.value),
                pagination: PaginationRequestDataMapper.fromDomain(pagination),
                filters: {
                    collectionId: filters?.getValue('collection')?.value.toString() ?? undefined,
                    paste: filters?.getValue('material')?.value.toString() ?? undefined,
                    brand: filters?.getValue('brand')?.value.toString() ?? undefined,
                    availableStock: filters?.getValue('stock') ? true : undefined,
                },
            },
        };
    }

}
