import React, { ReactElement, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { getWeight } from 'app/shared/helper/getWeight';
import { getArea } from 'app/shared/helper/getArea';
import { getMeasurement } from 'app/shared/helper/getMeasurement';
import { getSquareMeasurement } from 'app/shared/helper/getSquareMeasurement';
import { getRoundedValues } from 'app/shared/helper/getRoundedValues';
import { NumberFormatComponentPieceUnit } from '../../../../../shared/components/NumberFormatPieceUnit';
import {
    ADD_TO_CART,
    AREA,
    AVAILABLE_STOCK_FROM,
    BOX_UNIT,
    FAMILY,
    MATERIAL,
    NO_STOCK,
    OUTLET,
    PIECE_UNIT,
    PRODUCT_SIZE,
    QUANTITY,
    REFERENCE,
    STOCK_AVAILABLE,
    WEIGHT,
} from '../../../../../../i18n/translations/TR';
import { useOrigin } from '../../../../../shared/hooks/useOrigin';
import { Origin } from '../../../../../shared/domain/value-objects/Origin';
import { ShoppingModal } from '../../../../../shared/components/modals/shoppingModal/ShoppingModal';
import { StockAvailableInfo } from '../../../component/stockAvailableInfo/StockAvailableInfo';
import { Currency } from '../../../../../shared/components/Currency';
import { EstimatedProductionDateLabel } from './estimatedProductionDataLabel/EstimatedProductionDateLabel';
import { WarehouseLocation } from '../../../../domain/entity/WarehouseLocation';
import { usePermission } from '../../../../../shared/hooks/usePermission';
import { CollectionId } from '../../../../../collection/domain/valueObject/CollectionId';
import { ProductDetails } from '../../../../domain/entity/ProductDetails';
import { ProductId } from '../../../../domain/valueObject/ProductId';

interface ProductDataSheetProps {
    detail: ProductDetails
    refreshProductData: (productId: ProductId) => void
    outOfStockWarehousesLocations: WarehouseLocation[]
}

export const ProductDataSheet = (props: ProductDataSheetProps): ReactElement => {
    const { t } = useTranslation();
    const origin = useOrigin();
    const permission = usePermission();

    const [show, setShow] = useState(false);
    const [showForce, setShowForce] = useState(false);
    const handleClose = (): void => setShow(false);
    const handleShow = (): void => setShow(true);
    const handleCloseForce = (): void => setShowForce(false);
    const handleShowForce = (): void => setShowForce(true);

    const stockClass = classNames(
        'product-stock text-start',
        { 'no-stock': !props.detail.hasStock() && !props.detail.product.outlet },
        { 'in-stock': props.detail.hasStock() && !props.detail.product.outlet },
    );

    const stockStringF = (): string => {
        const locale = origin === Origin.EUROPE ? 'es-ES' : 'en-US';

        if (!props.detail.product.outlet && props.detail.hasStock()) {
            return t(STOCK_AVAILABLE);
        }

        if (!props.detail.hasStock && props.detail.product.stockEntryDate) {
            return `${t(AVAILABLE_STOCK_FROM)}${props.detail.product.stockEntryDate
                .toLocaleDateString(locale)
                .replace(/\//g, '-')}`;
        }

        if (!props.detail.product.outlet && !props.detail.hasStock() && !props.detail.product.stockEntryDate) {
            return t(NO_STOCK);
        }

        return '';
    };

    return (
        <section className="header-section-detail bg-white">
            <div className="container">
                <div className="row">
                    <div
                        className="col-lg-6
                        product-info
                        d-flex
                        justify-content-center
                        align-items-center
                        mb-5"
                    >
                        <img
                            alt="tile"
                            className="img-fluid"
                            src={props.detail?.product.image?.value}
                        />
                    </div>

                    <div className="col-lg-6 product-info">
                        <p className="product-subtitle text-start">{props.detail?.product.collectionName?.value}</p>
                        <h3 className="page-title text-start">{props.detail?.product.description}</h3>
                        <div className="product-stock-labels-container">
                            {props.detail.product.outlet ? (
                                <div className="product-outlet-label">
                                    <span className={stockClass}>
                                        {t(OUTLET)}
                                    </span>
                                </div>
                            ) : (
                                <div className="product-stock-label">
                                    <span className={stockClass}>
                                        {stockStringF()}
                                    </span>
                                </div>
                            )}
                        </div>

                        <EstimatedProductionDateLabel
                            amount={props.detail.totalStock()}
                            isOutlet={props.detail.product.outlet}
                            stockEntryDate={props.detail.product.stockEntryDate?.toString()}
                            locale={origin === Origin.EUROPE ? 'es-ES' : 'en-US'}
                        />
                        <div>
                            <strong>{`${t(STOCK_AVAILABLE)}: `}</strong>
                        </div>
                        <div className="mb-1">
                            {
                                props.detail.product.unit ? (
                                    <StockAvailableInfo
                                        origin={origin}
                                        lots={props.detail.lots}
                                        unit={props.detail.product.unit}
                                        amount={props.detail.totalStock()}
                                        outOfStockWarehousesLocations={props.outOfStockWarehousesLocations}
                                    />
                                ) : null
                            }
                        </div>
                        {props.detail.product.format ?
                            (
                                <p>
                                    <strong>{`${t(PRODUCT_SIZE)}: `}</strong>
                                    {`${props.detail.product.format.value} ${getMeasurement()}`}
                                </p>
                            )
                            : null}
                        {props.detail.product.boxPiece > 0 ?
                            (
                                <p>
                                    <strong>{`${t(QUANTITY)}: `}</strong>
                                    {`${props.detail.product.boxPiece} ${t(PIECE_UNIT)}/${t(BOX_UNIT)}`}
                                </p>
                            )
                            : null}
                        {props.detail.product.boxMeter > 0 ? (
                            <p>
                                <strong>{`${t(AREA)}: `}</strong>
                                <NumberFormatComponentPieceUnit
                                    value={props.detail.product.boxMeter > 0 ? getRoundedValues(props.detail.product.boxMeter, 3) : ''}
                                    suffix={` ${getSquareMeasurement()}/${t(BOX_UNIT)}`}
                                />
                            </p>
                        ) : null}
                        {props.detail.product.boxWeight.weight > 0 ? (
                            <p>
                                <strong>{`${t(WEIGHT)}: `}</strong>
                                <NumberFormatComponentPieceUnit
                                    suffix={` ${getWeight()}/${t(BOX_UNIT)}`}
                                    value={props.detail.product.boxWeight.weight > 0 ?
                                        getRoundedValues(props.detail.product.boxWeight.weight) :
                                        ''}
                                />
                            </p>
                        ) : null}
                        {
                            props.detail.product.paste ? (
                                <p>
                                    <strong>{`${t(MATERIAL)}: `}</strong>
                                    {props.detail.product.paste}
                                </p>
                            ) : null
                        }
                        <p>
                            <strong>{t(REFERENCE)}</strong>
                            {`: ${props.detail.product.id.value}`}
                        </p>
                        {
                            props.detail.product.family ? (
                                <p>
                                    <strong>{t(FAMILY)}</strong>
                                    {`: ${props.detail.product.family}  `}
                                </p>
                            ) : null
                        }
                        <div className="detail-price mt-5">
                            {props.detail.product.price !== null && props.detail.product.unit ?
                                (
                                    <div className="product-price">
                                        <div className="import">
                                            <Currency
                                                value={getRoundedValues(props.detail.product.price.value)}
                                            />
                                        </div>
                                        <div className="units-type text-start">{getArea(props.detail.product.unit)}</div>
                                    </div>
                                ) : null}
                            {permission.canDoOrders() && props.detail.product.collectionId?.value !== CollectionId.MARKETING_ID ?
                                (
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                        onClick={handleShow}
                                    >
                                        {t(ADD_TO_CART)}
                                    </button>
                                ) : null}
                        </div>
                    </div>
                </div>
            </div>
            <ShoppingModal
                show={show}
                showForce={showForce}
                onHideShop={handleClose}
                productDetails={props.detail}
                handleCloseForce={handleCloseForce}
                handleShowForce={handleShowForce}
                refreshProductData={props.refreshProductData}
            />
        </section>
    );
};
