import React, { ReactElement } from 'react';

interface ActionButtonsProps {
    text: string|ReactElement;
    isBusy?: boolean;
    className?: string;
    disabled?: boolean;
    textIsBusy?: string;
    type?: 'button' | 'submit';
    onClick?: () => void | undefined | Promise<void>;
}

export const CActionButton = (props: ActionButtonsProps): React.ReactElement => {

    const textIsBusy = props.textIsBusy || props.text;
    if (props.type === 'button') {
        return (
            <button
                type="button"
                className={`c-action-button btn action-button ${props.className}`}
                disabled={props.disabled || props.isBusy}
                onClick={props.onClick}
            >
                {props.isBusy
                    ? <span className="spinner spinner-border" role="status" aria-hidden="true" />
                    : null}
                <span className="text">{props.isBusy ? textIsBusy : props.text}</span>
            </button>
        );
    }

    return (
        <button
            type="submit"
            className={`c-action-button btn action-button ${props.className}`}
            disabled={props.disabled || props.isBusy}
            onClick={props.onClick}
        >
            {props.isBusy
                ? <span className="spinner spinner-border" role="status" aria-hidden="true" />
                : null}
            <span className="text">{props.isBusy ? props.textIsBusy : props.text}</span>
        </button>
    );
};

CActionButton.defaultProps = {
    className: '',
    disabled: false,
    isBusy: false,
    type: 'button',
    onClick: undefined,
};
