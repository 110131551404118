import { ProductId } from 'app/product/domain/valueObject/ProductId';
import * as Price from '../../../orders/model/value-objects/Price';
import ProductQuantity from '../../../product/domain/valueObject/ProductQuantity';
import { Weight } from '../../../orders/model/value-objects/Weight';
import { CartItemId } from '../value-objects/CartItemId';
import { Lot } from '../value-objects/Lot';

export default class CartItem {

    public get quantityInPieces(): number {
        return this._quantityInPieces;
    }

    public get quantityInBoxes(): number {
        return this._quantityInBoxes;
    }
    public get quantityInPallets(): number {
        return this._quantityInPallets;
    }

    public get lot(): Lot {
        return this._lot;
    }

    public get outOfStock(): boolean {
        return this._outOfStock;
    }

    diff(item2: CartItem): number {
        if (this.productId.value.toLowerCase() < item2.productId.value.toLowerCase()) { return -1; }
        if (this.productId.value.toLowerCase() > item2.productId.value.toLowerCase()) { return 1; }
        return 0;
    }

    public get quantity(): ProductQuantity {
        return this._quantity;
    }
    public get weight(): Weight {
        return this._weight;
    }
    public get discount2(): Price.Price {
        return this._discount2;
    }
    public get discount(): Price.Price {
        return this._discount;
    }
    public get unitPrice(): Price.Price {
        return this._unitPrice;
    }
    public get price(): Price.Price {
        return this._price;
    }
    public get productId(): ProductId {
        return this._productId;
    }
    public get id(): CartItemId {
        return this._id;
    }

    public constructor(
        private _id: CartItemId,
        private _productId: ProductId,
        private _price: Price.Price,
        private _unitPrice: Price.Price,
        private _discount: Price.Price,
        private _discount2: Price.Price,
        private _weight: Weight,
        private _quantity: ProductQuantity,
        private _lot: Lot,
        private _quantityInPallets: number,
        private _quantityInBoxes: number,
        private _quantityInPieces: number,
        private _outOfStock: boolean = false,
    ) {
    }

}
