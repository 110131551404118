import React from 'react';
import { useTranslation } from 'react-i18next';
import { UseMutationResult } from 'react-query';
import { Spinner } from 'app/shared/components/Spinner';
import { CountryByProvince } from '../../../../../orders/model/countryByProvince';
import { UserCredentialId } from '../../../../../shared/domain/value-objects/UserCredentialId';
import { MY_ACCOUNT } from '../../../../../../i18n/translations/TR';
import { AccountSettingsCard } from './account-settings-card/account-settings-card';
import { AccountContactsCard } from './account-contacts-card/account-contacts-card';
import { AccountShippingAddressCard } from './account-shipping-address-card/account-shipping-address-card';
import { ErpContactsArray } from '../../../../domain/erp-contacts-array';
import { AddErpUserContactRequestDTO } from '../../../../infrastructure/api/dto/request/add-erp-user-contact-request.DTO';
import { ErpUser } from '../../../../domain/erp-user';
import { PatchErpUserPasswordDTO } from '../../../../infrastructure/api/dto/request/patch-erp-user-password.DTO';
import { ShippingAddress } from '../../../../../orders/model/ShippingAddress';

interface AccountViewProps {
    getErpUserIsLoading: boolean;
    erpUserContacts: ErpContactsArray;
    getErpUserContactsIsLoading: boolean;
    showDeleteContactModal: boolean;
    handleShowDeleteContactModal: () => void;
    handleHideDeleteContactModal: () => void;
    deleteErpUserContact: (email: string) => void;
    deleteErpUserContactIsLoading: boolean;
    mutationAddErpUserContact: UseMutationResult<string, unknown, AddErpUserContactRequestDTO, unknown>;
    getShippingAddressesIsLoading: boolean;
    shippingAddresses: ShippingAddress[];
    erpUser: ErpUser | undefined;
    mutationChangePassword: UseMutationResult<string, unknown, PatchErpUserPasswordDTO, unknown>;
    userCredentialId: UserCredentialId | undefined;
    countries: CountryByProvince[];
    shippingAddressDefault: ShippingAddress | undefined;
    getDefaultShippingAddressIsLoading: boolean;
    getDefaultShippingAddress: () => void;
    getShippingAddresses: () => void;
}
export const AccountView = (props: AccountViewProps): React.ReactElement => {
    const { t } = useTranslation();

    const renderAccountCard = (): React.ReactElement | null => {
        if (!props.erpUser) {
            return <Spinner classCss="header-section-spinner" />;
        }

        return !props.userCredentialId ? null : (
            <AccountShippingAddressCard
                shippingAddresses={props.shippingAddresses}
                erpUserId={props.erpUser.id}
                userCredentialId={props.userCredentialId}
                defaultShippingAddress={props.shippingAddressDefault}
                countries={props.countries}
                onSelect={(): void => {
                    props.getDefaultShippingAddress();
                }}
                getShippingAddressesIsLoading={props.getShippingAddressesIsLoading}
                getShippingAddresses={props.getShippingAddresses}
                getDefaultShippingAddress={props.getDefaultShippingAddress}
                getDefaultShippingAddressIsLoading={props.getDefaultShippingAddressIsLoading}
                defaultAddress={props.shippingAddressDefault}
            />
        );
    };

    return (
        <section className="header-section container">
            <div className="header-actions">
                <h3>{t(MY_ACCOUNT)}</h3>
            </div>

            {props.getErpUserIsLoading || !props.erpUser ? (
                <Spinner classCss="header-section-spinner" />
            ) : (
                <AccountSettingsCard erpUser={props.erpUser} mutationChangePassword={props.mutationChangePassword} />
            )}

            {props.getErpUserContactsIsLoading ? (
                <Spinner classCss="header-section-spinner" />
            ) : (
                <AccountContactsCard
                    erpUserContacts={props.erpUserContacts}
                    showDeleteContactModal={props.showDeleteContactModal}
                    handleHideDeleteContactModal={props.handleHideDeleteContactModal}
                    handleShowDeleteContactModal={props.handleShowDeleteContactModal}
                    deleteErpUserContact={props.deleteErpUserContact}
                    deleteErpUserContactIsLoading={props.deleteErpUserContactIsLoading}
                    mutationAddErpUserContact={props.mutationAddErpUserContact}
                />
            )}

            {renderAccountCard()}
        </section>
    );
};
