import { TencerApiClient } from 'app/shared/infrastructure/tencer-api/TencerApiClient';
import { LoadingOrderRepositoryInterface } from '../../domain/loading-order-repository-interface';
import LoadingOrder from '../../domain/loading-order';
import { LoadingOrderDTO } from './dto/loading-order.DTO';
import { LoadingOrderMapper } from './data-mapper/loading-order.mapper';
import { LoadingOrderId } from '../../domain/value-objects/loading-order-id';
import OrderToLoad from '../../domain/order-to-load';
import { OrderToLoadMapper } from './data-mapper/order-to-load.mapper';
import LoadingOrderSlot from '../../domain/loading-order-slot';
import { PostLoadingOrderMapper } from './data-mapper/post-loading-order.mapper';
import { TypeAheadData } from '../../../shared/components/typeAhead/typeAheadData';
import { ShippingAddress } from '../../../orders/model/ShippingAddress';
import { LoadingOrderTransportType } from '../../domain/value-objects/loading-order-transport_type';
import { GetLoadingOrderPackagesMapper } from './data-mapper/get-loading-order-packages.mapper';

export class LoadingOrderRepository implements LoadingOrderRepositoryInterface {

    private readonly client: TencerApiClient;

    constructor(client: TencerApiClient) {
        this.client = client;
    }

    async getLoadingOrders(
        page: number,
        results: number,
    ): Promise<LoadingOrder[]> {
        const response: LoadingOrderDTO[] = await this.client.getLoadingOrders(
            page,
            results,
        );
        return response.map((item) => LoadingOrderMapper.toDomain(item));
    }

    async downloadLoadingOrderPDF(
        loadingOrderId: LoadingOrderId,
    ): Promise<Blob> {
        const response = await this.client.getLoadingOrderPDF(
            loadingOrderId.value,
        );

        return response;
    }

    async getOrdersToLoad(loadingOrderShippingAddress: TypeAheadData): Promise<OrderToLoad[]> {
        const response = await this.client.getOrdersToLoad(
            loadingOrderShippingAddress.item.code.value,
        );
        return response.orders_to_load.map((item) => OrderToLoadMapper.toDomain(item));
    }

    async getLoadingOrderDates(): Promise<LoadingOrderSlot> {
        const response = await this.client.getLoadingOrderDates();
        return new LoadingOrderSlot(
            new Date(response.from),
            new Date(response.to),
        );
    }

    async getLoadingOrderPackages(ordersLoaded: OrderToLoad[]): Promise<number> {
        const response = await this.client.getLoadingOrderPackages(
            GetLoadingOrderPackagesMapper.fromDomain(ordersLoaded),
        );
        return response.quantity;
    }

    async postLoadingOrder(
        date: Date,
        orders: OrderToLoad[],
        shippingAddress: ShippingAddress,
        transportType: LoadingOrderTransportType,
    ): Promise<void> {
        return this.client.postLoadingOrder(
            PostLoadingOrderMapper.fromDomain(date, orders, shippingAddress, transportType),
        );
    }

    async getLoadingOrder(
        loadingOrderId: LoadingOrderId,
    ): Promise<LoadingOrder> {
        const response = await this.client.getLoadingOrder(
            loadingOrderId.value,
        );

        return LoadingOrderMapper.toDomain(response);
    }

    async getContainerMaxWeight(
        erpUserId: string,
    ): Promise<number> {
        const response = await this.client.getErpUser(
            {
                erpUserId,
            },
        );

        return response.container_max_weight;
    }

}
