import { CollectionTile } from '../valueObject/CollectionTile';
import { Collection } from './Collection';

export class CollectionDetails {

    public constructor(
        private readonly _collection: Collection,
        private readonly _tiles: CollectionTile[],
    ) {}

    public get collection(): Collection {
        return this._collection;
    }

    public get tiles(): CollectionTile[] {
        return this._tiles;
    }

}
