import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import './HomeSearcher.scss';
import { AsyncSelect } from '../../../shared/components/asyncSelect/AsyncSelect';
import { HOME_SEARCHER_PLACEHOLDER } from '../../../../i18n/translations/TR';
import { useHomeSearcherController } from './useHomeSearcherController';
import { AsyncSelectData } from '../../../shared/components/asyncSelect/AsyncSelectData';
import { Product } from '../../../product/domain/entity/Product';
import { HomeSearcherItem } from './item/HomeSearcherItem';

const renderItem = (option: AsyncSelectData<Product>): ReactElement => {
    return (
        <HomeSearcherItem product={option.data} />
    );
};

export const HomeSearcher = (): ReactElement => {
    const { t } = useTranslation();
    const controller = useHomeSearcherController();

    return (
        <div className="component-home-searcher">
            <AsyncSelect
                id="homeSearcher"
                item={renderItem}
                options={controller.options}
                isLoading={controller.isLoading}
                reference={controller.refAsyncSelect}
                handleSearch={controller.fetchProducts}
                handleSelected={controller.handleSelect}
                placeholder={t(HOME_SEARCHER_PLACEHOLDER)}
            />
        </div>
    );
};
