import { CollectionName } from 'app/collection/domain/valueObject/CollectionName';
import { CollectionId } from '../../../../domain/valueObject/CollectionId';
import { CollectionResponse } from '../../../../../shared/infrastructure/tencer-api/dto/responses/CollectionResponse';
import { Collection } from '../../../../domain/entity/Collection';
import { Image } from '../../../../../product/domain/valueObject/Image';

export class CollectionDataMapper {

    public static fromResponse(response: CollectionResponse): Collection {
        return new Collection(
            new CollectionId(Number(response.id)),
            new CollectionName(response.name),
            response.date ? new Date(response.date) : new Date(),
            new Image(response.image),
            new Image(response.imageHeader),
        );
    }

}
