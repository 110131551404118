import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { getRoundedValues } from 'app/shared/helper/getRoundedValues';
import { useOrigin } from 'app/shared/hooks/useOrigin';
import { getArea } from 'app/shared/helper/getArea';
import {
    AVAILABLE_LOTS,
    AVAILABLE_QUANTITY,
    LOT_REFERENCE,
    OUT_OF_STOCK_WAREHOUSES_INFO,
} from '../../../../../../i18n/translations/TR';
import { NumberFormatComponentPieceUnit } from '../../../../../shared/components/NumberFormatPieceUnit';
import { Origin } from '../../../../../shared/domain/value-objects/Origin';
import { WarehouseLocation } from '../../../../domain/entity/WarehouseLocation';
import { ProductDetails } from '../../../../domain/entity/ProductDetails';

interface WarehousesInfoProps {
    details: ProductDetails,
    outOfStockWarehousesLocations: WarehouseLocation[]
}

export const WarehousesInfo = (props: WarehousesInfoProps): ReactElement => {
    const origin = useOrigin();
    const { t } = useTranslation();

    return (
        <section className="warehouse-info-table-container">
            {origin === Origin.USA ? (
                <div className="unavailable-lots-container">
                    <p>{t(OUT_OF_STOCK_WAREHOUSES_INFO).toUpperCase()}</p>
                </div>
            ) : null}

            <div className="container available-lots-container">
                <h3 className="section-title">{t(AVAILABLE_LOTS)}</h3>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col" className="text-end">
                                {t(LOT_REFERENCE)}
                            </th>
                            {
                                props.details.product.unit ? (
                                    <th scope="col" className="text-end">
                                        {`${t(AVAILABLE_QUANTITY)} (${getArea(props.details.product.unit)})`}
                                    </th>
                                ) : null
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {props.details?.lots?.map((info) => (
                            <tr key={info.reference + (info.warehouse?.code ?? '')} className="available-lot">
                                <th scope="row" className="text-end">
                                    <span className="lot-reference">
                                        {info.reference}
                                    </span>
                                    {info.warehouse ? (
                                        <span className="lot-location">
                                            {`(${info.warehouse?.code ?? ''})`}
                                        </span>
                                    ) : null }
                                </th>
                                <td className="text-end">
                                    <NumberFormatComponentPieceUnit
                                        value={getRoundedValues(info.quantity)}
                                    />
                                </td>
                            </tr>
                        ))}
                        {
                            props.outOfStockWarehousesLocations.map((warehouse) => (
                                <tr key={warehouse.code} className="available-lot">
                                    <th scope="row" className="text-end">
                                        <span className="lot-location">
                                            {`(${warehouse.code})`}
                                        </span>
                                    </th>
                                    <td className="text-end">
                                        <NumberFormatComponentPieceUnit value={0} />
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </section>
    );
};
