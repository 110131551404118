import React from 'react';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';
import { getCurrency } from '../helper/getCurrency';
import { useOrigin } from '../hooks/useOrigin';

interface Props {
    value: number | string,
    className?: string,
    currency?: string,
}

export const Currency: React.FC<Props> = (
    { value, className, currency },
) => {
    const { i18n } = useTranslation();
    const origin = useOrigin();
    const originUsa = 'USA';
    const originEurope = 'EUROPE';

    const useCurrency = currency ?? getCurrency();

    const prefix = origin === originUsa ? `${useCurrency}` : '';
    const suffix = origin === originEurope ? ` ${useCurrency}` : '';

    return (
        <NumberFormat
            value={value}
            displayType="text"
            prefix={prefix}
            suffix={suffix}
            className={className!}
            thousandSeparator={i18n.language === 'es' ? '.' : ','}
            decimalSeparator={i18n.language === 'es' ? ',' : '.'}
            decimalScale={2}
            fixedDecimalScale
        />
    );
};
