import { ProductId } from '../../../../../domain/valueObject/ProductId';
import { LotResponse } from '../../../../../../shared/infrastructure/tencer-api/dto/responses/LotResponse';
import { Lot } from '../../../../../../cart/domain/value-objects/Lot';
import { WarehouseLocationDataMapper } from './WarehouseLocationDataMapper';

export class LotDataMapper {

    public static fromResponse(response: LotResponse): Lot {
        return new Lot(
            new ProductId(response.product),
            response.quantity ? response.quantity : 0,
            response.box ? response.box : null,
            response.caliber ? response.caliber : null,
            response.quality ? response.quality : null,
            response.pallet_type ? response.pallet_type : null,
            response.reference ? response.reference : null,
            response.warehouse_location ?
                WarehouseLocationDataMapper.fromResponse(response.warehouse_location) :
                null,
        );
    }

}
