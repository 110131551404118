import { Environment } from '../../../environment';

export const BASE_URL = String(Environment.API_HOST);
export const API_LOGIN = '/api/login_check';
export const API_PASSWORD_RECOVERY = '/api/user/password/reset';
export const API_PASSWORD_RESET = '/api/user/password/reset/';
export const API_PRODUCT_DETAIL = '/api/customer/packing/detail';
export const API_CATALOG = '/api/customer/packing/catalog?results=30';
export const API_OUTLET = '/api/customer/packing/outlet?results=30';
export const API_OUTLET_HOME = '/api/customer/packing/outlet?results=5';
export const API_TOP_TRENDING_PRODUCTS_HOME = '/api/customer/packing/top-trending?results=5';
export const API_TOP_TRENDING_PRODUCTS = '/api/customer/packing/top-trending?results=30';
export const API_PRODUCT_DETAILS_OK = '/api/customer/packing/detail?product=';
export const API_CATALOG_SEARCH = '/api/customer/packing/search?results=30';
export const TOKEN_REFRESH = '/api/token/refresh';
export const API_COLLECTIONS_HOME = '/api/customer/collection/catalog?results=5';
export const API_COLLECTIONS_CATALOG = '/api/customer/collection/catalog?results=30';
export const API_FILTER_VALUES = '/api/customer/filter/values';
export const API_COLLECTION_DETAILS = '/api/customer/collection/detail?collection=';
export const URL_PRODUCTS = '/top-products';
export const URL_OUTLET_PRODUCTS = '/outlet';
export const API_MY_CART = '/api/customer/my-cart';
export const API_MY_ITEM_CART_LIST = '/api/customer/packing/catalog?results=';
export const API_POST_PRODUCT = '/api/customer/my-cart/products/';
export const API_MODIFY_CART = '/api/customer/carts/cartId/cartItemId/client-internal';
export const API_MODIFY_QUANTITY_CART = '/api/customer/my-cart/items/';
