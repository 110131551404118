import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { getWeight } from 'app/shared/helper/getWeight';
import { getArea } from 'app/shared/helper/getArea';
import { getSquareMeasurement } from 'app/shared/helper/getSquareMeasurement';
import { getRoundedValues } from 'app/shared/helper/getRoundedValues';
import { getMeasurement } from 'app/shared/helper/getMeasurement';
import { ADD, BOX_UNIT, FAMILY, OUTLET, PIECE_UNIT } from '../../../../../../i18n/translations/TR';
import { NumberFormatComponentPieceUnit } from '../../../../../shared/components/NumberFormatPieceUnit';
import { ReactComponent as IcSize } from '../../../../../../assets/img/icons/ic-size.svg';
import { ReactComponent as IcWeight } from '../../../../../../assets/img/icons/ic-weight.svg';
import { ReactComponent as IcArea } from '../../../../../../assets/img/icons/ic-area.svg';
import { Currency } from '../../../../../shared/components/Currency';
import { usePermission } from '../../../../../shared/hooks/usePermission';
import { CollectionId } from '../../../../../collection/domain/valueObject/CollectionId';
import { Product } from '../../../../domain/entity/Product';

interface ProductListItemProps {
    product: Product;
}

export const ProductListItem = (props: ProductListItemProps): ReactElement => {
    const { t } = useTranslation();
    const permission = usePermission();

    return (
        <li className="list-item">
            {props.product ? (
                <>
                    <div className="product-info">
                        <div className="product-image">
                            <img src={props.product.image?.value} alt="product" />
                        </div>
                        <div className="product-item-details">
                            <div className="product-title">
                                <span>{props.product.description}</span>
                                <span className="product-format">
                                    {`${props.product.format?.value} ${getMeasurement()}`}
                                </span>
                                {props.product.outlet ? (
                                    <span className="product-outlet-label">
                                        <span className="product-stock text-start">
                                            {t(OUTLET)}
                                        </span>
                                    </span>
                                ) : null}
                            </div>
                            <div className="product-description">
                                {props.product.collectionName?.value}
                            </div>
                            <div>
                                {props.product.family ? (
                                    <div className="product-size">
                                        <strong>{`${t(FAMILY)} `}</strong>
                                        {` ${props.product.family}`}
                                    </div>
                                ) : null}
                                {props.product.boxPiece > 0 ? (
                                    <div className="product-size">
                                        <IcSize className="icon-mini" />
                                        {`${props.product.boxPiece} ${t(PIECE_UNIT)}/${t(BOX_UNIT)}`}
                                    </div>
                                ) : null}
                                {props.product.boxMeter > 0 ? (
                                    <div className="product-size">
                                        <IcArea className="icon-mini" />
                                        <NumberFormatComponentPieceUnit
                                            value={getRoundedValues(props.product.boxMeter, 3)}
                                            suffix={` ${getSquareMeasurement()}/${t(BOX_UNIT)}`}
                                        />
                                    </div>
                                ) : null}
                                {props.product.boxWeight.weight > 0 ? (
                                    <div className="product-size">
                                        <IcWeight className="icon-mini" />
                                        <NumberFormatComponentPieceUnit
                                            value={getRoundedValues(props.product.boxWeight.weight)}
                                            suffix={` ${getWeight()}/${t(BOX_UNIT)}`}
                                        />
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <div className="product-actions">
                        {props.product.price !== null && props.product.unit ?
                            (
                                <div className="product-price">
                                    <Currency
                                        value={props.product?.price.value}
                                        className="import"
                                    />
                                    <div className="units-type">
                                        {getArea(props.product.unit)}
                                    </div>
                                </div>
                            ) : null}
                        {permission.canDoOrders() && props.product.collectionId?.value !== CollectionId.MARKETING_ID ?
                            (
                                <button
                                    type="button"
                                    className="btn btn-secondary btn-mini"
                                >
                                    {t(ADD)}
                                </button>
                            ) : null}
                    </div>
                </>
            ) : null}
        </li>
    );
};
