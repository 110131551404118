import React from 'react';
import { Navigate } from 'react-router-dom';
import { ShippingAddressSelectorModal } from './shipping-address-selector-modal/shipping-address-selector-modal';
import { LoadingOrderAddStep, useLoadingOrderAdd } from '../hooks/use-loading-order-add';
import { OrdersToLoadModal } from './orders-to-load-modal/order-to-load-modal';
import { CalendarModal } from './calendar-modal/calendar-modal';
import { SummaryModal } from './summary-modal/summary-modal';
import { usePermission } from '../../../shared/hooks/usePermission';
import { TencerRoutes } from '../../../shared/tencer-routing/TencerRoutes';
import { ContainerWeightErrorModal } from './container-weight-error-modal/container-weight-error-modal';
import { OrdersToLoadErrorModal } from './orders-to-load-error-modal/orders-to-load-error-modal';

interface LoadingOrderAddScreenProps {
    hideLoadingOrdersAddScreen: () => void
    afterAddingLoadingScreen: () => void
}

export const LoadingOrderAddScreen: React.FC <LoadingOrderAddScreenProps> = (
    { hideLoadingOrdersAddScreen,
        afterAddingLoadingScreen },
) => {
    const UseLoadingOrdersAdd = useLoadingOrderAdd(hideLoadingOrdersAddScreen, afterAddingLoadingScreen);

    const permission = usePermission();
    if (!permission.canManageLoadingOrders()) {
        return <Navigate replace to={TencerRoutes.HOME} />;
    }

    return (
        <div className="screen">
            <ShippingAddressSelectorModal
                goBack={UseLoadingOrdersAdd.goBack}
                goForward={UseLoadingOrdersAdd.goForward}
                isBusy={UseLoadingOrdersAdd.getShippingAddressesIsLoading}
                shippingAddresses={UseLoadingOrdersAdd.shippingAddressesTypeAhead}
                loadingOrderTransportType={UseLoadingOrdersAdd.loadingOrderTransportType}
                loadingOrderShippingAddress={UseLoadingOrdersAdd.loadingOrderShippingAddress}
                handleLoadingOrderTransportType={UseLoadingOrdersAdd.handleLoadingOrderTransportType}
                handleLoadingOrderShippingAddress={UseLoadingOrdersAdd.handleLoadingOrderShippingAddress}
                showModal={UseLoadingOrdersAdd.currentStep === LoadingOrderAddStep.SHIPPING_ADDRESS_SELECTOR_STEP}
            />

            <OrdersToLoadModal
                goBack={UseLoadingOrdersAdd.goBack}
                goForward={UseLoadingOrdersAdd.goForward}
                ordersToLoad={UseLoadingOrdersAdd.ordersToLoad}
                ordersLoaded={UseLoadingOrdersAdd.ordersLoaded}
                numberOfPackages={UseLoadingOrdersAdd.numberOfPackages}
                moveOrderToLoaded={UseLoadingOrdersAdd.moveOrderToLoaded}
                calculateTotalWeight={UseLoadingOrdersAdd.calculateTotalWeight}
                checkIfOrderIsLoaded={UseLoadingOrdersAdd.checkIfOrderIsLoaded}
                removeOrderFromLoaded={UseLoadingOrdersAdd.removeOrderFromLoaded}
                moveOrderLineToLoaded={UseLoadingOrdersAdd.moveOrderLineToLoaded}
                checkIfOrderLineIsLoaded={UseLoadingOrdersAdd.checkIfOrderLineIsLoaded}
                getOrdersToLoadIsLoading={UseLoadingOrdersAdd.getOrdersToLoadIsLoading}
                loadingOrderTransportType={UseLoadingOrdersAdd.loadingOrderTransportType}
                removeOrderLineFromLoaded={UseLoadingOrdersAdd.removeOrderLineFromLoaded}
                loadingOrderShippingAddress={UseLoadingOrdersAdd.loadingOrderShippingAddress}
                getContainerMaxWeightIsLoading={UseLoadingOrdersAdd.getContainerMaxWeightIsLoading}
                getLoadingOrderPackagesIsLoading={UseLoadingOrdersAdd.getLoadingOrderPackagesIsLoading}
                showModal={UseLoadingOrdersAdd.currentStep !== LoadingOrderAddStep.SHIPPING_ADDRESS_SELECTOR_STEP}
                checkIfContainerMaxWeightHasBeenExceeded={UseLoadingOrdersAdd.checkIfContainerMaxWeightHasBeenExceeded}
            />

            {
                UseLoadingOrdersAdd.loadingOrderSlot
                    ?
                    (
                        <CalendarModal
                            goBack={UseLoadingOrdersAdd.goBack}
                            goForward={UseLoadingOrdersAdd.goForward}
                            loadingOrderSlot={UseLoadingOrdersAdd.loadingOrderSlot}
                            loadingOrderDate={UseLoadingOrdersAdd.loadingOrderSelectedDate}
                            getLoadingOrderDatesIsLoading={UseLoadingOrdersAdd.getLoadingOrderDatesIsLoading}
                            handleLoadingOrderSelectedDate={UseLoadingOrdersAdd.handleLoadingOrderSelectedDate}
                            showModal={UseLoadingOrdersAdd.currentStep === LoadingOrderAddStep.SCHEDULE_SELECTOR_STEP}
                        />
                    ) : null
            }

            <SummaryModal
                goBack={UseLoadingOrdersAdd.goBack}
                goForward={UseLoadingOrdersAdd.goForward}
                packages={UseLoadingOrdersAdd.numberOfPackages || 0}
                totalWeight={UseLoadingOrdersAdd.calculateTotalWeight()}
                totalProducts={UseLoadingOrdersAdd.calculateTotalProducts()}
                loadingOrderDate={UseLoadingOrdersAdd.loadingOrderSelectedDate}
                loadingOrderTransportType={UseLoadingOrdersAdd.loadingOrderTransportType}
                loadingOrderShippingAddress={UseLoadingOrdersAdd.loadingOrderShippingAddress}
                showModal={UseLoadingOrdersAdd.currentStep === LoadingOrderAddStep.SUMMARY_STEP}
                isBusy={
                    UseLoadingOrdersAdd.getLoadingOrderPackagesIsLoading ||
                    UseLoadingOrdersAdd.postLoadingOrderIsLoading
                }
            />

            <OrdersToLoadErrorModal
                goBack={UseLoadingOrdersAdd.goBack}
                message={UseLoadingOrdersAdd.orderToLoadErrorMessage || ''}
                showModal={
                    UseLoadingOrdersAdd.ordersToLoad.length === 0 &&
                    UseLoadingOrdersAdd.currentStep === LoadingOrderAddStep.ORDERS_TO_LOAD_STEP &&
                    !UseLoadingOrdersAdd.getOrdersToLoadIsLoading
                }
            />

            <ContainerWeightErrorModal
                maxWeight={UseLoadingOrdersAdd.containerMaxWeight || 0}
                currentWeight={UseLoadingOrdersAdd.calculateTotalWeight()}
                onHide={UseLoadingOrdersAdd.hideContainerWeightErrorModal}
                showModal={UseLoadingOrdersAdd.showContainerWeightErrorModal}
            />
        </div>
    );
};
