import { useEffect, useState } from 'react';
import { useForm, UseFormRegisterReturn, UseFormReturn } from 'react-hook-form';
import { UserClientRef } from 'app/shared/domain/value-objects/UserClientRef';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'app/shared/hooks/useDebounce';
import { useOrderRepository } from '../../../orders/application/hooks/useOrderRepository';
import Cart from '../../../cart/domain/model/Cart';
import { FORM_FIELD_REQUIRED } from '../../../../i18n/translations/TR';

interface CartPOFormData {
    clientRef: string | null;
}

export interface UseCartPOResponse {
    errorPO: boolean
    POIsBusy: boolean
    validatePO: () => boolean
    handleHidePOError: () => void
    clientRefInput: UseFormRegisterReturn
    cartPOForm: UseFormReturn<CartPOFormData>
}

export const useCartPO = (cart: Cart | null | undefined):UseCartPOResponse => {
    const { t } = useTranslation();
    const orderRepository = useOrderRepository();
    const [POIsBusy, setPOIsBusy] = useState(false);
    const [errorPO, setErrorPO] = useState<boolean>(false);
    const cartPOForm = useForm<CartPOFormData>({
        defaultValues: {
            clientRef: '',
        },
    });

    const clientRefInput = cartPOForm.register('clientRef', {
        required: false,
        maxLength: 20,
        onChange: () => {
            setPOIsBusy(true);
            cartPOForm.clearErrors();
        },
    });
    const watchClientRef = cartPOForm.watch('clientRef');

    const handleHidePoError = (): void => {
        setErrorPO(false);
    };

    const submitClientRef = (): void => {
        if (!cart || !watchClientRef || cart.clientInternalRef?.value === watchClientRef) {
            setPOIsBusy(false);
            return;
        }

        const clientRef = new UserClientRef(watchClientRef);

        orderRepository.addClientRefMutation.mutate(
            {
                cartId: cart.id,
                clientRef,
            },
            {
                onSuccess: () => {
                    setPOIsBusy(false);
                },
                onError: () => {
                    setErrorPO(true);
                    setPOIsBusy(false);
                    cartPOForm.resetField('clientRef');
                },
            },
        );
    };

    const submitClientRefDebounce = useDebounce(submitClientRef, 2000);

    const handleRequiredPOError = (): Promise<void> => {
        cartPOForm.setError('clientRef', {
            type: 'required',
            message: t(FORM_FIELD_REQUIRED),
        });

        cartPOForm.setFocus('clientRef');

        return Promise.resolve();
    };

    const validatePO = (): boolean => {
        if (!watchClientRef) {
            handleRequiredPOError();
            return false;
        }

        return true;
    };

    useEffect(() => {
        submitClientRefDebounce();
    }, [watchClientRef]);

    useEffect(() => {
        if (cart && cart.clientInternalRef) {
            cartPOForm.setValue(
                'clientRef',
                cart.clientInternalRef.value,
            );
        }

    }, [cart]);

    return {
        errorPO,
        POIsBusy,
        cartPOForm,
        validatePO,
        clientRefInput,
        handleHidePOError: handleHidePoError,
    };
};
