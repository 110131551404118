import React, { ReactElement } from 'react';
import './shipmentLine.scss';
import { getRoundedValues } from 'app/shared/helper/getRoundedValues';
import { Currency } from '../../../../shared/components/Currency';
import OrderShipmentLine from '../../../model/value-objects/OrderShipmentLine';

interface ShipmentLineLineProps {
    line: OrderShipmentLine
}

export const ShipmentLine = (props: ShipmentLineLineProps): ReactElement => {

    return (
        <div className="component-order-shipment-line list-item">
            <div className="shipment-line-description">
                <span className="title">
                    {props.line.description}
                </span>
            </div>

            <div className="shipment-line-total-price">
                <Currency
                    className="import"
                    value={getRoundedValues(props.line.price.amount)}
                />
            </div>
        </div>
    );
};
