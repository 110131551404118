import { TransportType } from './transport-type';
import { Courier } from '../../../cartSummary/domain/entity/Courier';
import { CourierService } from '../../../shared/domain/CourierService';
import { ShippingId } from './ShippingId';
import { Price } from './Price';

export default class OrderShipmentLine {

    public constructor(
        private _type: TransportType,
        private _isConsolidated: boolean,
        private _description: string,
        private _price: Price,
        private _account: string | null,
        private _shippingId: ShippingId | null,
        private _courier: Courier | null,
        private _courierService: CourierService | null,
    ) {}

    public get type(): TransportType {
        return this._type;
    }

    public get isConsolidated(): boolean {
        return this._isConsolidated;
    }

    public get description(): string {
        return this._description;
    }

    public get price(): Price {
        return this._price;
    }

    public get account(): string | null {
        return this._account;
    }

    public get shippingId(): ShippingId | null {
        return this._shippingId;
    }

    public get courier(): Courier | null {
        return this._courier;
    }

    public get courierService(): CourierService | null {
        return this._courierService;
    }

}
