import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { OrderFilterCollection } from '../../../model/OrderFilterCollection';
import { OrderFilterName } from '../../hooks/useOrderFilters';
import {
    ORDER_FILTER_LABEL_DATE_FROM,
    ORDER_FILTER_LABEL_DATE_TO,
    ORDERS_TABLE_COLUMN_PO,
    ORDERS_TABLE_COLUMN_STATUS,
} from '../../../../../i18n/translations/TR';
import { OrderFilter } from '../../../model/OrderFilter';
import i18n from '../../../../../i18n';

interface Props {
    filters: OrderFilterCollection;
}
export const OrderFilterPills: React.FC<Props> = ({ filters }) => {
    const { t } = useTranslation();
    const handleFilterNameTranslation = (name: string): string => {
        switch (name) {
            case OrderFilterName.FINAL_DATE:
                return t(ORDER_FILTER_LABEL_DATE_TO);
            case OrderFilterName.START_DATE:
                return t(ORDER_FILTER_LABEL_DATE_FROM);
            case OrderFilterName.PHASE:
                return t(ORDERS_TABLE_COLUMN_STATUS);
            default:
                return t(ORDERS_TABLE_COLUMN_PO);
        }
    };

    const renderOrderFilterLabel = (filter: OrderFilter): React.ReactElement => {
        if (filter.name !== OrderFilterName.START_DATE && filter.name !== OrderFilterName.FINAL_DATE) {
            return (
                <div key={filter.name} className="filter-tag">
                    {`${handleFilterNameTranslation(filter.name)}: ${filter.value}`}
                </div>
            );
        }
        return (
            <div key={filter.name} className="filter-tag">
                {`${handleFilterNameTranslation(filter.name)}: ${
                    moment.unix(Number(filter.value))
                        .locale(i18n.language)
                        .format('MM-DD-YYYY')
                }`}
            </div>

        );
    };

    return (
        <>
            {filters
                .toArray()
                .filter((filter) => filter.name !== 'order-orderer')
                .map((filter) => {
                    return renderOrderFilterLabel(filter);
                })}
        </>
    );
};
