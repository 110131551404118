import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductListScreen } from 'app/product/view/screen/list/ProductListScreen';
import { BESTSELLERS } from '../../../../../../i18n/translations/TR';
import { ProductType, ProductTypeValue } from '../../../../domain/valueObject/ProductType';

export const ProductBestsellersScreen = (): ReactElement => {
    const { t } = useTranslation();

    return (
        <ProductListScreen
            title={t(BESTSELLERS)}
            hasFiltersEnabled={false}
            className="component-product-best-sellers-screen"
            type={new ProductType(ProductTypeValue.BESTSELLER)}
        />
    );
};
