import { ProductDataMapper } from 'app/product/infrastructure/repository/api/product/dataMapper/ProductDataMapper';
import { ProductResponse } from '../../../../../shared/infrastructure/tencer-api/dto/responses/ProductResponse';
import { CollectionTile } from '../../../../domain/valueObject/CollectionTile';
import { CollectionTileResponse } from '../../../../../shared/infrastructure/tencer-api/dto/responses/CollectionTileResponse';

export class CollectionTileDataMapper {

    public static fromResponse(response: CollectionTileResponse): CollectionTile {
        return new CollectionTile(
            response.products.map((product: ProductResponse) => {
                return ProductDataMapper.fromResponse(product);
            }),
            response.type,
        );
    }

}
