import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SwiperBackground } from 'app/shared/components/SwiperBackground';
import { HomeSearcher } from 'app/home/presentation/searcher/HomeSearcher';
import {
    BOOKING_BUY_BANNER,
    CHECK_STOCK_BANNER,
    CHECK_THE_AVAILABLE_BANNER,
    MAKE_THE_RESERVATION_BANNER,
    OUR_COLLECTION_BANNER,
    WELCOME_WOW,
    WOW_BANNER,
} from '../../../../i18n/translations/TR';
import { TencerRoutes } from '../../../shared/tencer-routing/TencerRoutes';
import IconRest from '../../../../assets/img/icons/ic-buy-res.svg';
import IconStock from '../../../../assets/img/icons/ic-stock.svg';
import { FeatureItem } from './featureItem/FeatureItem';

export const MainBanner = (): ReactElement => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const goToCollections = ():void => {
        navigate(TencerRoutes.COLLECTIONS);
    };

    return (
        <section className="banner">
            <div>
                <div className="container">
                    <h1 className="home-title">
                        {t(WOW_BANNER)}
                    </h1>

                    <HomeSearcher />

                    <p className="intro-title">
                        {t(WELCOME_WOW)}
                    </p>

                    <div className="wow-features">
                        <FeatureItem
                            image={IconStock}
                            title={t(CHECK_STOCK_BANNER)}
                            description={t(CHECK_THE_AVAILABLE_BANNER)}
                        />

                        <FeatureItem
                            image={IconRest}
                            title={t(BOOKING_BUY_BANNER)}
                            description={t(MAKE_THE_RESERVATION_BANNER)}
                        />

                    </div>
                    <button
                        type="button"
                        onClick={goToCollections}
                        className="btn btn-primary"
                    >
                        {t(OUR_COLLECTION_BANNER)}
                    </button>
                </div>

                <SwiperBackground classNameProp="swiper-container-hero swiper-banner-home" />
            </div>
        </section>
    );
};
