import { ProductTypeResponse } from '../../../../../../shared/infrastructure/tencer-api/dto/responses/ProductResponse';
import { ProductType, ProductTypeValue } from '../../../../../domain/valueObject/ProductType';

export class ProductTypeDataMapper {

    public static fromDomain(productType: ProductType): ProductTypeResponse {
        const mapping: Record<ProductTypeValue, ProductTypeResponse> = {
            [ProductTypeValue.ALL]: ProductTypeResponse.ALL,
            [ProductTypeValue.OUTLET]: ProductTypeResponse.OUTLET,
            [ProductTypeValue.CATALOG]: ProductTypeResponse.CATALOG,
            [ProductTypeValue.BESTSELLER]: ProductTypeResponse.BESTSELLER,
        };

        const value = mapping[productType.value];

        if (!value) {
            throw new Error(`Invalid ProductType value: ${productType.value}`);
        }

        return value;
    }

}
