import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { OUTLET } from '../../../../../../i18n/translations/TR';
import { ProductListScreen } from '../ProductListScreen';
import { ProductType, ProductTypeValue } from '../../../../domain/valueObject/ProductType';

export const ProductOutletScreen = (): ReactElement => {
    const { t } = useTranslation();

    return (
        <ProductListScreen
            title={t(OUTLET)}
            hasFiltersEnabled={false}
            className="component-product-outlet-screen"
            type={new ProductType(ProductTypeValue.OUTLET)}
        />
    );
};
