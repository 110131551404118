import { CreditMemoResponse } from 'app/shared/infrastructure/tencer-api/dto/responses/CreditMemoResponse';
import { ICreditMemo } from '../../../../orders/model/CreditMemo';

export const CreditMemoMapper = {
    map: (creditMemo: CreditMemoResponse): ICreditMemo => {
        return {
            id: creditMemo.memo_id || '',
            amount: creditMemo.amount || 0.0,
        };
    },
};
