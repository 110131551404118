import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { ACCEPT, UNEXPECTED_ERROR } from '../../../../i18n/translations/TR';

interface Props {
    show: boolean
    message?: string | React.ReactElement
    onHide: () => void
}

export const ModalError: React.FC<Props> =
    (
        {
            show,
            onHide,
            message,
        },
    ) => {

        const { t } = useTranslation();
        const closeModal = (): void => {
            onHide();
        };

        return (
            <Modal
                centered
                show={show}
                onHide={closeModal}
                className="modal fade"
                aria-labelledby="contained-modal-title-vcenter"
                backdropClassName="calendar-modal-backdrop-wrapper"
            >
                <Modal.Body>
                    <p>{message}</p>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        type="button"
                        onClick={closeModal}
                        className="btn-link bg-transparent text-decoration-none"
                    >
                        {t(ACCEPT)}
                    </button>
                </Modal.Footer>
            </Modal>

        );
    };

ModalError.defaultProps = {
    message: i18next.t(UNEXPECTED_ERROR),
};
