import { Product } from './Product';
import { Lot } from '../../../cart/domain/value-objects/Lot';
import ProductQuantity from '../valueObject/ProductQuantity';

export class ProductDetails {

    public constructor(
        private readonly _product: Product,
        private readonly _lots: Lot[],
        private readonly _stock: ProductQuantity,
    ) {}

    public get product(): Product {
        return this._product;
    }

    public get lots(): Lot[] {
        return this._lots;
    }

    public get stock(): ProductQuantity {
        return this._stock;
    }

    public hasStock(): boolean {
        return this._stock.amount > 0;
    }

    public totalStock(): number {
        return this._stock.amount;
    }

}
