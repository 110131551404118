import React, { createContext, ReactElement, useMemo, useState } from 'react';

export interface SearchContextResponse {
    search: string
    cleanSearch: () => void
    handleSearch: (value: string) => void
}

export const SearchContext = createContext<SearchContextResponse>(
    {} as SearchContextResponse,
);

type ProviderProps = {
    children: ReactElement,
};

export const SearchContextProvider = ({ children }: ProviderProps): ReactElement => {
    const [search, setSearch] = useState('');

    const cleanSearch = (): void => {
        setSearch('');
    };

    const handleSearch = (value: string): void => {
        setSearch(value);
    };

    const stateValues = useMemo(() => ({
        search,
        cleanSearch,
        handleSearch,
    }), [
        search,
        cleanSearch,
        handleSearch,
    ]);

    return (
        <SearchContext.Provider value={stateValues}>
            {children}
        </SearchContext.Provider>
    );
};
