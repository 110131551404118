import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useOrigin } from 'app/shared/hooks/useOrigin';
import { CountryByProvince } from '../../../model/countryByProvince';
import { useShippingAddressRepository } from '../../hooks/useShippingAddressRepository';
import { ShippingAddressForm, ShippingAddressFormData } from './ShippingAddressForm';
import { ErrorCode } from '../../../../shared/domain/value-objects/ErrorCode';
import { ERROR_CODE_40016, ERROR_CODE_40016_USA } from '../../../../../i18n/translations/TR';
import { Origin } from '../../../../shared/domain/value-objects/Origin';
import { ShippingAddress } from '../../../model/ShippingAddress';

interface Props {
    countries: CountryByProvince[];
    onCancel: () => void;
    onSuccess(shippingAddress: ShippingAddress): void;
    formErrorMessage: string | undefined
    handleFormErrorMessage: (error: string | undefined) => void
}

export const AddShippingAddressForm: React.FC<Props> = (props: Props) => {
    const origin = useOrigin();
    const { t } = useTranslation();
    const form = useForm<ShippingAddressFormData>({});
    const shippingAddressRepository = useShippingAddressRepository();

    const onSubmit = form.handleSubmit((request: ShippingAddressFormData) => {
        props.handleFormErrorMessage(undefined);
        shippingAddressRepository.postShippingAddressMutation.mutate(
            {
                description: request.description || null,
                country_id: request.countryId || null,
                province_id: request.provinceId || null,
                postal_code: request.postalCode || null,
                locality: request.locality || null,
                address: request.address || null,
                phone: request.phone || null,
                address1: request.address1 || null,
                address2: request.address2 || null,
                fax: request.fax || null,
                contact: request.contact || null,
            },
            {
                onSuccess: (data) => {
                    props.onSuccess(data);
                },
                onError: (error) => {
                    // @ts-ignore
                    if (ErrorCode.CP_OUT_OF_RANGE === error.response.data.code) {
                        props.handleFormErrorMessage(
                            origin === Origin.USA ?
                                t(
                                    ERROR_CODE_40016_USA,
                                    { postalCodeMin: form.watch('minCp'), postalCodeMax: form.watch('maxCp') },
                                ) :
                                t(
                                    ERROR_CODE_40016,
                                    { postalCodeMin: form.watch('minCp'), postalCodeMax: form.watch('maxCp') },
                                ),
                        );
                    }
                },
            },
        );
    });

    return (
        <ShippingAddressForm
            countries={props.countries}
            onSubmit={onSubmit}
            onCancel={props.onCancel}
            address={undefined}
            isBusy={shippingAddressRepository.postShippingAddressMutation.isLoading}
            form={form}
            formErrorMessage={props.formErrorMessage}
        />
    );
};
