export enum OrderStatusType {
    ORDERED = 'PEDIDO',
    IN_PREPARATION = 'EN PREPARACION',
    READY = 'PREPARADO',
    CHARGED = 'CARGADO',
    INVOICED = 'FACTURADO',
    RELEASED = 'RELEASED',
    OPEN = 'OPEN',
}

export class OrderStatus {

    private readonly _value: OrderStatusType;

    public constructor(value: OrderStatusType) {
        this._ensureIsValid(value); // Valida antes de asignar
        this._value = value;
    }

    public get value(): OrderStatusType {
        return this._value;
    }

    private _ensureIsValid(value: OrderStatusType): void {
        const validStatuses = Object.values(OrderStatusType);
        if (!validStatuses.includes(value)) {
            throw new Error(`Invalid status value: ${value}`);
        }
    }

}
