import React, { createContext, ReactElement, useMemo } from 'react';
import { CartRepository } from 'app/cartSummary/domain/repository/CartRepository';
import { ProductFilterRepository } from 'app/product/domain/repository/ProductFilterRepository';
import { InvoiceRepository } from '../../invoices/domain/repository/InvoiceRepository';
import { CreditMemoRepository } from '../../cartSummary/domain/repository/CreditMemoRepository';
import { ErpUserRepository } from '../../account/domain/repository/ErpUserRepository';
import { AuthenticationRepository } from '../../login/domain/repository/AuthenticationRepository';
import { TencerApiClientSingleton } from '../infrastructure/tencer-api/TencerApiClientSingleton';
import ApiCreditMemoRepository from '../../orders/infrastructure/repository/api/ApiCreditMemoRepository';
import { ApiErpUserRepository } from '../../account/infrastructure/api/repository/ApiErpUserRepository';
import { ApiCartRepository } from '../../cartSummary/infrastructure/repository/ApiCartRepository';
import { ApiAuthenticationRepository } from '../../login/infrastructure/repository/api/ApiAuthenticationRepository';
import { ApiInvoiceRepository } from '../../invoices/infrastructure/repository/api/ApiInvoiceRepository';
import { ApiCourierRepository } from '../../cartSummary/infrastructure/repository/ApiCourierRepository';
import { ApiTransportRepository } from '../../cartSummary/infrastructure/repository/ApiTransportRepository';
import { ApiWowCourierRepository } from '../../cartSummary/infrastructure/repository/ApiWowCourierRepository';
import { CourierRepository } from '../../cartSummary/domain/repository/CourierRepository';
import { TransportRepository } from '../../cartSummary/domain/repository/TransportRepository';
import { WowCourierRepository } from '../../cartSummary/domain/repository/WowCourierRepository';
import { LocalProductFilterRepository } from '../../product/infrastructure/repository/local/LocalProductFilterRepository';
import { CollectionRepository } from '../../collection/domain/repository/CollectionRepository';
import { ApiCollectionRepository } from '../../collection/infrastructure/repository/api/ApiCollectionRepository';
import { ProductRepository } from '../../product/domain/repository/ProductRepository';
import { ApiProductRepository } from '../../product/infrastructure/repository/api/product/ApiProductRepository';
import { AnalyticsRepository } from '../domain/repository/AnalyticsRepository';
import { ApiAnalyticsRepository } from '../infrastructure/tencer-api/repository/ApiAnalyticsRepository';
import { WarehouseLocationRepository } from '../../product/domain/repository/WarehouseLocationRepository';
import { ApiWarehouseLocationRepository } from '../../product/infrastructure/repository/api/warehouseLocation/ApiWarehouseLocationRepository';

export interface IDependencyInjection {
    cartRepository: CartRepository;
    productRepository: ProductRepository;
    invoiceRepository: InvoiceRepository;
    erpUserRepository: ErpUserRepository;
    courierRepository: CourierRepository;
    analyticsRepository: AnalyticsRepository;
    transportRepository: TransportRepository;
    collectionRepository: CollectionRepository;
    wowCourierRepository: WowCourierRepository;
    creditMemoRepository: CreditMemoRepository;
    productFilterRepository: ProductFilterRepository;
    authenticationRepository: AuthenticationRepository;
    warehouseLocationRepository: WarehouseLocationRepository;
}

export const DependencyInjectionContext = createContext<IDependencyInjection>(
    {} as IDependencyInjection,
);

type ProviderProps = {
    children: ReactElement,
};

export const DependencyInjectionProvider = ({ children }: ProviderProps): ReactElement => {

    const stateValues = useMemo(() => {
        const apiClient = TencerApiClientSingleton.getInstance();

        const cartRepository = new ApiCartRepository(apiClient);
        const invoiceRepository = new ApiInvoiceRepository(apiClient);
        const courierRepository = new ApiCourierRepository(apiClient);
        const productRepository = new ApiProductRepository(apiClient);
        const erpUserRepository = new ApiErpUserRepository(apiClient);
        const analyticsRepository = new ApiAnalyticsRepository(apiClient);
        const transportRepository = new ApiTransportRepository(apiClient);
        const collectionRepository = new ApiCollectionRepository(apiClient);
        const wowCourierRepository = new ApiWowCourierRepository(apiClient);
        const creditMemoRepository = new ApiCreditMemoRepository(apiClient);
        const productFilterRepository = new LocalProductFilterRepository();
        const authenticationRepository = new ApiAuthenticationRepository(apiClient);
        const warehouseLocationRepository = new ApiWarehouseLocationRepository(apiClient);

        return {
            cartRepository,
            invoiceRepository,
            courierRepository,
            productRepository,
            erpUserRepository,
            analyticsRepository,
            transportRepository,
            wowCourierRepository,
            creditMemoRepository,
            collectionRepository,
            productFilterRepository,
            authenticationRepository,
            warehouseLocationRepository,
        };
    }, []);

    return (
        <DependencyInjectionContext.Provider value={stateValues}>
            {children}
        </DependencyInjectionContext.Provider>
    );
};
