import React, { ReactElement } from 'react';
import OrderLine from '../../../model/OrderLine';
import { OrderLineItem } from './item/OrderLineItem';
import { PalletFeeLine } from '../palletFeeLine/PalletFeeLine';
import { ShipmentLine } from '../shipmentLine/ShipmentLine';
import OrderShipmentLine from '../../../model/value-objects/OrderShipmentLine';

interface OrderLinesListProps {
    lines: OrderLine[],
    palletFeeLine: OrderLine | null
    orderShipmentLine: OrderShipmentLine | null
}

export const OrderLinesList = (props: OrderLinesListProps): ReactElement => {
    return (
        <ul className="section-list">
            {
                (
                    props.lines.map((line: OrderLine) => (
                        <OrderLineItem
                            line={line}
                            key={line.idx.value}
                        />
                    ))
                )
            }
            {
                props.palletFeeLine ?
                    (
                        <PalletFeeLine line={props.palletFeeLine} />
                    ) :
                    null
            }
            {
                props.orderShipmentLine &&
                props.orderShipmentLine.price.amount > 0 ?
                    (
                        <ShipmentLine line={props.orderShipmentLine} />
                    ) :
                    null
            }
        </ul>
    );
};
