import React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';
import { useOrigin } from 'app/shared/hooks/useOrigin';
import {
    SELECT_PROVINCE, SELECT_STATE,
    SHIPPING_ADDRESS_LABEL_PROVINCE,
    SHIPPING_ADDRESS_LABEL_STATE,
} from '../../../../../../i18n/translations/TR';
import { Province } from '../../../../model/Province';
import { Origin } from '../../../../../shared/domain/value-objects/Origin';

interface ShippingAddressProvinceFieldProps {
    provinceRegister: UseFormRegisterReturn
    errorField: FieldError | undefined
    provinces: Province[]
    provinceId: string
}

export const ShippingAddressProvinceField = (
    {
        provinceRegister,
        errorField,
        provinces,
        provinceId,
    }: ShippingAddressProvinceFieldProps,
): React.ReactElement => {
    const origin = useOrigin();
    const { t } = useTranslation();
    return (
        <div className="form-field province-field">
            <label htmlFor="province" className="form-label is-required">
                {origin === Origin.USA ? t(SHIPPING_ADDRESS_LABEL_STATE) : t(SHIPPING_ADDRESS_LABEL_PROVINCE)}
            </label>
            <select
                {...provinceRegister}
                className="form-select"
                value={provinceId}
            >
                <option
                    value=""
                >
                    {origin === Origin.USA ? t(SELECT_STATE) : t(SELECT_PROVINCE)}
                </option>
                { provinces ?
                    provinces.map((province) => (
                        <option
                            key={province.id.value}
                            value={province.id.value}
                        >
                            {province.name}
                        </option>
                    )) : null }

            </select>
            {errorField ? (
                <div className="error-input-form">{errorField.message}</div>
            ) : null}
        </div>
    );
};
