import React from 'react';
import { useTranslation } from 'react-i18next';
import { DESCRIPTION_LOGIN, WELCOME, WOW } from 'i18n/translations/TR';
import { SwiperBackground } from 'app/shared/components/SwiperBackground';
import { PasswordResetForm } from './shared/PasswordResetForm';

export const PasswordRecoveryScreen: React.FC = () => {
    const { t } = useTranslation();

    return (

        <div className="login-page">
            <div className="login-container">
                <div className="login-page-text">
                    <p className="login-welcome">{t(WELCOME)}</p>
                    <h1 className="login-title">
                        {t(WOW)}
                    </h1>
                    <h3 className="login-description">
                        {t(DESCRIPTION_LOGIN)}
                    </h3>
                </div>
                <PasswordResetForm />
                <SwiperBackground classNameProp="swiper-container-hero swiper-login" />
            </div>
        </div>

    );
};
