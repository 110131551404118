import { CompanyId } from 'app/shared/domain/value-objects/CompanyId';
import { OrderId } from 'app/shared/domain/value-objects/OrderId';
import { UserClientRef } from 'app/shared/domain/value-objects/UserClientRef';
import { OrderLineResponse } from '../responses/OrderLineResponse';
import { OrderResponse } from '../responses/OrderResponse';
import Order from '../../../../model/Order';
import { ShippingAddressId } from '../../../../model/ShippingAddressId';
import { Discount } from '../../../../model/value-objects/Discount';
import { PackageTrackingNo } from '../../../../model/value-objects/PackageTrackingNo';
import { Price } from '../../../../model/value-objects/Price';
import { ShippingAgentCode } from '../../../../model/value-objects/ShippingAgentCode';
import { Weight } from '../../../../model/value-objects/Weight';
import { Year } from '../../../../model/value-objects/Year';
import { OrderFileMapper } from './OrderFileMapper';
import { ShippingAddressMapper } from './ShippingAddressMapper';
import { DiscountResponse } from '../../../../../shared/infrastructure/tencer-api/dto/responses/DiscountResponse';
import { CreditMemoMapper } from '../../../../../shared/infrastructure/tencer-api/data-mapper/credit-memo.mapper';
import { OrderShipmentLineMapper } from './OrderShipmentLineMapper';
import { PickHandleOrderLineMapper } from './PickHandleOrderLineMapper';
import { OrderLineMapper } from './OrderLineMapper';
import { OrderStatusMapper } from './OrderStatusMapper';

export class OrderMapper {

    public static fromResponse(response: OrderResponse): Order {
        return new Order(
            new OrderId(response.id),
            new CompanyId(response.company),
            new Year(response.year),
            response.shipping ? new ShippingAddressId(response.shipping) : null,
            response.lines.map((line: OrderLineResponse) => OrderLineMapper.fromResponse(line)),
            response.order_date ? new Date(response.order_date) : null,
            response.user_client_ref ? new UserClientRef(response.user_client_ref) : null,
            OrderFileMapper.fromOrderFilesResponse(response.files ?? []),
            new Weight(response.weight_gross ?? 0),
            new Weight(response.weight_net ?? 0),
            new Price(response.gross ?? 0),
            new Price(response.net ?? 0),
            response.shipping_agent_code ? new ShippingAgentCode(response.shipping_agent_code) : null,
            response.package_tracking_no ? new PackageTrackingNo(response.package_tracking_no) : null,
            response.status ? OrderStatusMapper.fromResponse(response.status) : null,
            response.shipping_address ? ShippingAddressMapper.fromResponse(response.shipping_address) : null,
            response.order_shipment_line ? OrderShipmentLineMapper.fromResponse(response.order_shipment_line) : null,
            response.pallet_handling_fee_line ? OrderLineMapper.fromResponse(response.pallet_handling_fee_line) : null,
            response.pick_handle_order_line ? PickHandleOrderLineMapper.fromResponse(response.pick_handle_order_line) : null,
            response.credit_memo ? CreditMemoMapper.map(response.credit_memo) : undefined,
            response.discounts.map(
                (discount: DiscountResponse) => new Discount(discount.percent, discount.description),
            ),
        );
    }

}
