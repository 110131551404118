import { useEffect, useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { TencerApiClientSingleton } from '../../../../shared/infrastructure/tencer-api/TencerApiClientSingleton';
import { useIsMounted } from '../../../../shared/hooks/useIsMounted';
import { ProductFiltersResponse } from '../../../../shared/infrastructure/tencer-api/dto/responses/ProductFiltersResponse';
import { ProductFilter } from '../../../../orders/model/ProductFilter';
import { ProductFilterCollection } from '../../../../orders/model/ProductFilterCollection';

interface UseProductFilterModalControllerResponse {
    error: string;
    loading: boolean;
    removeFilters: () => void
    data?: ProductFiltersResponse;
    submitHandler: (formData: ProductFilterFormRegisters) => void
    productFilterUseForm: UseFormReturn<ProductFilterFormRegisters>
}

interface ProductFilterFormRegisters {
    material: string,
    collection: string,
    checkbox: boolean,
    collectionString: string,
    pasteString: string,
    brand: string,
    brandString: string,
}

export function useProductFilterModalController(
    filters: ProductFilterCollection,
    onApply: (filters : ProductFilterCollection) => Promise<void>,
): UseProductFilterModalControllerResponse {
    const isMounted = useIsMounted();
    const [error, setError] = useState<string>('');
    const [data, setData] = useState<ProductFiltersResponse>();
    const [loading, setLoading] = useState<boolean>(true);

    const productFilterUseForm = useForm<ProductFilterFormRegisters>({
        mode: 'onChange',
    });

    const removeFilters = (): void => {
        productFilterUseForm.reset();
    };

    const submitHandler = async (formData: ProductFilterFormRegisters): Promise<void> => {
        setLoading(true);
        const appliedFilters = [];

        if (formData.collection) {
            appliedFilters.push(new ProductFilter(
                'collection',
                formData.collection,
            ));
        }

        if (formData.material) {
            appliedFilters.push(
                new ProductFilter(
                    'material',
                    formData.material,
                ),
            );
        }

        if (formData.checkbox) {
            appliedFilters.push(
                new ProductFilter(
                    'stock',
                    formData.checkbox ? 'true' : 'false',
                ),
            );
        }

        if (formData.brand) {
            appliedFilters.push(
                new ProductFilter(
                    'brand',
                    formData.brand,
                ),
            );
        }

        const collection = new ProductFilterCollection(appliedFilters);
        await onApply(collection)
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        productFilterUseForm.setValue('material', filters.getValue('material')?.value.toString() ?? '');
        productFilterUseForm.setValue('collection', filters.getValue('collection')?.value.toString() ?? '');
        productFilterUseForm.setValue('checkbox', !!filters.getValue('stock')?.value.toString());
        productFilterUseForm.setValue('brand', filters.getValue('brand')?.value.toString() ?? '');
    }, []);

    // TODO (Álvaro): do not user api method directly
    useEffect(() => {
        TencerApiClientSingleton.getInstance()
            .getFilterValues()
            .then((filtersResponse) => { if (!isMounted) { return; } setData(filtersResponse); })
            .catch((e) => setError(e))
            .finally(() => { if (!isMounted) { return; } setLoading(false); });
    }, []);

    return {
        data,
        error,
        loading,
        removeFilters,
        submitHandler,
        productFilterUseForm,
    };
}
