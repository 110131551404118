import React from 'react';
import 'i18n';
import { QueryClient, QueryClientProvider } from 'react-query';
import CookieConsent from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';
import {
    COOKIES_CONSENT_ACCEPT,
    COOKIES_CONSENT_BODY,
    COOKIES_CONSENT_POLICY_LINK,
    COOKIES_CONSENT_TITLE,
} from 'i18n/translations/TR';
import { DependencyInjectionProvider } from 'app/shared/context/DependecyInjectionContext';
import { AppRouting } from './AppRouting';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import { CurrentUserProvider } from './shared/context/CurrentUserContext';
import 'moment/locale/es';
import 'moment/locale/en-gb';
import 'moment/locale/fr';
import 'moment/locale/de';
import 'moment/locale/it';
import { EnvEnum } from './shared/domain/value-objects/EnvEnum';
import { SearchContextProvider } from './shared/context/SearchContext';

export const App = (): React.ReactElement => {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
                staleTime: Infinity,
            },
        },
    });

    const { t } = useTranslation();

    React.useEffect((): (() => void) | undefined => {
        if (process.env.REACT_APP_ENV !== EnvEnum.PROD) {
            const script = document.createElement('script');
            script.src = '/scripts/livebeep-script.js';
            script.async = true;
            document.head.appendChild(script);
            return ():void => {
                document.head.removeChild(script);
            };
        }
        return undefined;
    }, []);

    return (
        <>
            <QueryClientProvider client={queryClient}>
                <DependencyInjectionProvider>
                    <SearchContextProvider>
                        <CurrentUserProvider>
                            <AppRouting />
                        </CurrentUserProvider>
                    </SearchContextProvider>
                </DependencyInjectionProvider>
            </QueryClientProvider>
            <CookieConsent buttonText={t(COOKIES_CONSENT_ACCEPT)}>
                <p>{t(COOKIES_CONSENT_TITLE)}</p>
                <p>{t(COOKIES_CONSENT_BODY)}</p>
                <p>
                    <a href="/cookies-policy.pdf" target="_blank" rel="noreferrer">
                        {t(COOKIES_CONSENT_POLICY_LINK)}
                    </a>
                </p>
            </CookieConsent>
        </>
    );
};
