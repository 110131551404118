import { TencerApiClient } from 'app/shared/infrastructure/tencer-api/TencerApiClient';
import { ProductId } from 'app/product/domain/valueObject/ProductId';
import { AnalyticsRepository } from '../../../domain/repository/AnalyticsRepository';
import { EAnalyticsEvent } from '../../../domain/value-objects/AnalyticsEvent';
import { PostAnalyticsEventMapper } from '../data-mapper/post-analytics-event.mapper';

export class ApiAnalyticsRepository implements AnalyticsRepository {

    private readonly _client: TencerApiClient;

    constructor(client: TencerApiClient) {
        this._client = client;
    }

    public async logOutEvent(
        date: Date,
        event: EAnalyticsEvent.LOGOUT,
    ): Promise<void> {
        return this._client.postAnalyticsEvent(
            PostAnalyticsEventMapper.fromDomain(
                date,
                event,
            ),
        );
    }

    public async productDetailEvent(
        date: Date,
        event: EAnalyticsEvent.PRODUCT_DETAIL,
        productId: ProductId,
    ): Promise<void> {
        return this._client.postAnalyticsEvent(
            PostAnalyticsEventMapper.fromDomain(
                date,
                event,
                productId,
            ),
        );
    }

}
