import React from 'react';
import moment from 'moment';
import { DropdownButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Currency } from '../../../../../../shared/components/Currency';
import { ReactComponent as IcVert } from '../../../../../../../assets/img/icons/ic-more-vert.svg';
import {
    DOWNLOAD_PROFORMA, ORDER_PHASE_OPEN, ORDER_PHASE_RELEASED,
    ORDER_STATUS_LABEL_CHARGED,
    ORDER_STATUS_LABEL_IN_PREPARATION,
    ORDER_STATUS_LABEL_INVOICED,
    ORDER_STATUS_LABEL_ORDERED,
    ORDER_STATUS_LABEL_READY,
    ORDERS_VIEW_ORDER_DETAILS,
} from '../../../../../../../i18n/translations/TR';
import Order from '../../../../../model/Order';
import { useOrdersListInterface } from '../../../../hooks/useOrdersList';
import { OrderStatusType } from '../../../../../model/value-objects/OrderStatus';
import { useToken } from '../../../../../../shared/hooks/useToken';
import { Origin } from '../../../../../../shared/domain/value-objects/Origin';
import { useOrigin } from '../../../../../../shared/hooks/useOrigin';

interface OrderListTableBodyItemProps {
    order: Order
    ordersList: useOrdersListInterface
}
export const OrderListTableBodyItem = (props: OrderListTableBodyItemProps): React.ReactElement => {
    const { t, i18n } = useTranslation();
    const UseToken = useToken();
    const origin = useOrigin();

    const statusTranslation = (status: string): string => {
        switch (status) {
            case OrderStatusType.ORDERED:
                return t(ORDER_STATUS_LABEL_ORDERED);
            case OrderStatusType.IN_PREPARATION:
                return t(ORDER_STATUS_LABEL_IN_PREPARATION);
            case OrderStatusType.READY:
                return t(ORDER_STATUS_LABEL_READY);
            case OrderStatusType.CHARGED:
                return t(ORDER_STATUS_LABEL_CHARGED);
            case OrderStatusType.INVOICED:
                return t(ORDER_STATUS_LABEL_INVOICED);
            case OrderStatusType.RELEASED:
                return t(ORDER_PHASE_RELEASED);
            case OrderStatusType.OPEN:
                return t(ORDER_PHASE_OPEN);
            default:
                return '';
        }
    };
    return (
        <tr key={props.order.id.value}>
            <td className="order-id">{props.order.id.value}</td>
            <td className="order-po">{props.order.user_client_ref?.value}</td>
            <td className="order-shipping">
                {UseToken.origin === Origin.EUROPE ?
                    props.ordersList.getShippingAddress(props.order.shipping)
                    : props.order.shippingAddress?.description}
            </td>
            <td className="order-date">
                {
                    props.order.order_date
                        ? moment(props.order.order_date)
                            .locale(i18n.language)
                            .format('MMM D, YYYY')
                        : ''
                }
            </td>
            <td className="order-status">
                {
                    props.order.status?.value ? (
                        <span className="status-label">
                            {statusTranslation(props.order.status?.value)}
                        </span>
                    ) : null
                }
            </td>
            <td className="price order-price">
                <span className="amount order-price-amount">
                    {props.order.net ? (
                        <Currency value={props.order.net.amount} />
                    ) : null}
                </span>
            </td>
            {origin === Origin.USA ? (
                <td className="order-shipping_method">
                    <span>{props.order.shipmentLine?.type?.translate}</span>
                </td>
            ) : null}
            <td className="order-actions">
                <div className="dropdown d-flex justify-content-end">
                    <DropdownButton
                        key="start"
                        id="dropdown-button-drop-end"
                        drop="down"
                        variant="transparent"
                        title={<IcVert className="iconDots" />}
                        className="noButtonAppearance overflow-menu"
                    >
                        <li
                            className="dropdown-item"
                            onClick={():void => props.ordersList.goToDetails(props.order.id)}
                            role="presentation"
                        >
                            {t(ORDERS_VIEW_ORDER_DETAILS)}
                        </li>
                        <li
                            className={
                                !props.ordersList.downloadingProforma ? 'dropdown-item' : 'dropdown-item disabled'
                            }
                            onClick={
                                ():void => props.ordersList.downloadProforma(props.order.id)
                            }
                            role="presentation"
                        >
                            {props.ordersList.downloadingProforma ?
                                (
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                )
                                : null}

                            {t(DOWNLOAD_PROFORMA)}
                        </li>
                    </DropdownButton>
                </div>
            </td>
        </tr>
    );
};
