import { WarehouseLocationRepository } from '../../repository/WarehouseLocationRepository';
import { WarehouseLocation } from '../../entity/WarehouseLocation';

interface GetWarehousesLocationUseCaseProps {
    warehouseLocationRepository: WarehouseLocationRepository,
}

export default async (props: GetWarehousesLocationUseCaseProps): Promise<WarehouseLocation[]> => {
    try {
        return await props.warehouseLocationRepository.findAll();
    } catch (error) {
        return Promise.reject(error);
    }
};
