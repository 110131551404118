import { PickHandleOrderLineResponse } from 'app/orders/infrastructure/repository/api/responses/PickHandleOrderLineResponse';
import PickHandleOrderLine from 'app/orders/model/value-objects/PickHandleOrderLine';
import { Price } from '../../../../model/value-objects/Price';
import { OrderId } from '../../../../../shared/domain/value-objects/OrderId';

export class PickHandleOrderLineMapper {

    public static fromResponse(response: PickHandleOrderLineResponse): PickHandleOrderLine {
        return new PickHandleOrderLine(
            new OrderId(response.order_id.order_number.value),
            new Price(response.price.amount),
            response.description,
        );
    }

}
