import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import { Product } from 'app/product/domain/entity/Product';
import { CollectionTileItem } from './item/CollectionTileItem';

SwiperCore.use([Pagination, Navigation]);

interface CollectionTileSectionProps {
    title: string,
    products: Product[]
}

export const CollectionTileSection = (props: CollectionTileSectionProps): ReactElement | null => {
    return props.products.length ? (
        <section className="product-detail-section bg-white">
            <h3 className="container section-title">
                {props.title}
            </h3>
            <div className="collection-swiper-wrapper
                            container product-type-grid
                             swiper-container swiper-product-type"
            >

                {props.products.map((product:Product) => (
                    <div
                        key={product.id.value}
                        className="swiper-slide collection-swiper-slider"
                    >
                        <Link to={`/products/${product.id.value}`}>
                            <CollectionTileItem product={product} />
                        </Link>
                    </div>
                ))}
            </div>
        </section>
    ) : null;
};
