/* eslint-disable global-require */

export const BG_01 = require('./bg-01.jpg');
export const BG_02 = require('./bg-02.jpg');
export const BG_03 = require('./bg-03.jpg');
export const BG_04 = require('./bg-04.jpg');
export const BG_05 = require('./bg-05.jpg');
export const BG_06 = require('./bg-06.jpg');
export const BG_07 = require('./bg-07.jpg');
export const BG_08 = require('./bg-08.jpg');
export const BG_09 = require('./bg-09.jpg');
export const BG_10 = require('./bg-10.jpg');
export const BG_11 = require('./bg-11.jpg');
export const PD_01 = require('./product-detail-00d.jpg');
export const ProductDetailImage = require('./product-detail-03.jpg');
export const BerryGloss = require('./color-berry-gloss.png');
export const BerryMatt = require('./color-berry-matt.png');
export const BlushGloss = require('./color-blush-gloss.png');
export const DownloadsImage = require('./downloads.png');

/* eslint-disable global-require */
