import { useNavigate } from 'react-router-dom';
import { useContext, useState } from 'react';
import { DependencyInjectionContext } from '../../../../../shared/context/DependecyInjectionContext';
import { TencerRoutes } from '../../../../../shared/tencer-routing/TencerRoutes';
import { useToken } from '../../../../../shared/hooks/useToken';
import { PaymentMethod } from '../../../../../orders/model/value-objects/PaymentMethod';

interface UseCheckoutOkControllerResponse {
    isLoading: boolean
    goToOrders: () => void
    goToCatalog: () => void
}

export const useCheckoutOkController = (): UseCheckoutOkControllerResponse => {
    const UseToken = useToken();
    const navigate = useNavigate();
    const dic = useContext(DependencyInjectionContext);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const changeCartStatus = async (): Promise<void> => {
        setIsLoading(true);
        await dic.cartRepository.setInPaymentProcessStatus()
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleCartStatus = async (): Promise<void> => {
        if (UseToken.paymentMethod === PaymentMethod.CREDIT_CARD) {
            await changeCartStatus();
        }

        return Promise.resolve();
    };

    const goToCatalog = (): void => {
        handleCartStatus()
            .finally(() => {
                navigate(TencerRoutes.CATALOG);
            });
    };

    const goToOrders = (): void => {
        handleCartStatus()
            .finally(() => {
                navigate(TencerRoutes.ORDERS);
            });
    };

    return {
        isLoading,
        goToOrders,
        goToCatalog,
    };
};
