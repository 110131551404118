import { Name } from 'app/shared/domain/value-objects/Name';
import { Id } from './value-objects/id';

export class Permission {

    private _id:Id;
    private _name: Name;

    public constructor(
        id:Id,
        name:Name,
    ) {
        this._id = id;
        this._name = name;
    }

    public get id(): Id {
        return this._id;
    }
    public set id(value: Id) {
        this._id = value;
    }

    public get name(): Name {
        return this._name;
    }
    public set name(value:Name) {
        this._name = value;
    }

}
