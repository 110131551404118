import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageSpinner } from 'app/shared/components/page-spinner/page-spinner';
import { CHECKOUT_OK_TEXT, CHECKOUT_OK_TITLE, CONTINUE, ORDERS } from '../../../../../../i18n/translations/TR';
import { ReactComponent as IconCheck } from '../../../../../../assets/img/icons/ic-check-done.svg';
import './checkoutOk.scss';
import { CActionButton } from '../../../../../shared/components/CActionButton/CActionButton';
import { useCheckoutOkController } from './CheckoutOkController';

export const CheckoutOk = (): React.ReactElement => {
    const { t } = useTranslation();
    const controller = useCheckoutOkController();

    return (
        <>
            <PageSpinner isBusy={controller.isLoading} />
            <div className="component-checkout-ok payment-result payment-result-ok">
                <div className="header">
                    <div className="title">
                        <h1 className="section-title">
                            <span className="icon"><IconCheck /></span>
                            <span className="text">{t(CHECKOUT_OK_TITLE)}</span>
                        </h1>
                    </div>
                </div>
                <div className="body">
                    <p>{t(CHECKOUT_OK_TEXT)}</p>
                    <div className="actions">
                        <CActionButton
                            text={t(CONTINUE)}
                            onClick={controller.goToCatalog}
                        />
                        <CActionButton
                            text={t(ORDERS)}
                            onClick={controller.goToOrders}
                            className="btn-primary-outline"
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
