import React, { ReactElement } from 'react';
import { Collection } from '../../../../domain/entity/Collection';

interface CollectionListItemProps {
    collection: Collection;
}

export const CollectionListItem = (props: CollectionListItemProps): ReactElement => {

    return (
        <li className="grid-item">
            <div className="product-image">
                <img
                    alt="product"
                    src={props.collection.image.value}
                />
            </div>
            <div className="product-item-details">
                <div className="product-title">
                    {props.collection.name.value}
                </div>
                <div className="product-description">
                    {props.collection.name.value}
                </div>
            </div>
        </li>
    );
};
