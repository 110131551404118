import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { TencerRoutes } from '../../../../shared/tencer-routing/TencerRoutes';

interface FeatureItemProps {
    image: string,
    title: string,
    description: string
}

export const FeatureItem = (props: FeatureItemProps): ReactElement => {
    const navigate = useNavigate();

    const goToCatalog = ():void => {
        navigate(TencerRoutes.CATALOG);
    };

    return (
        <div
            className="feature-item"
            onClick={goToCatalog}
            role="presentation"
        >
            <div className="feature-img">
                <img
                    width="47"
                    src={props.image}
                    alt="default-icon"
                />
            </div>
            <div className="feature-info">
                <div className="feature-title">
                    {props.title}
                </div>
                <div className="feature-description">
                    {props.description}
                </div>
            </div>
        </div>
    );
};
