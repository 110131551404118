import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    LOGIN,
    NEW_PASSWORD,
    PASSWORD_RESET_SUCCESS,
    PASSWORD_RESET_TEXT,
    PASSWORD_RESET_TITLE,
    PASSWORDS_NO_MATCH,
    PASSWORDS_REPEAT,
    SET_PASSWORD_HELP,
} from 'i18n/translations/TR';
import { SubmitHandler, useForm } from 'react-hook-form';
import { CurrentUserContext } from 'app/shared/context/CurrentUserContext';

import { ErrorMessage } from 'app/login/sign-in/shared/components/ErrorMessage';
import { ThankYouMessage } from 'app/login/sign-in/shared/components/ThankYouMessage';
import { TencerRoutes } from 'app/shared/tencer-routing/TencerRoutes';
import { useErrorTranslation } from 'app/shared/hooks/useErrorTranslation';
import { TencerApiClientSingleton } from '../../shared/infrastructure/tencer-api/TencerApiClientSingleton';

interface FormInputs {
    password2: string,
    password: string,
    validation:string,
}

/* eslint-disable-next-line */
export const PasswordResetForm = () => {
    const { t } = useTranslation();
    const history = useNavigate();
    const state = React.useContext(CurrentUserContext);
    const { register, handleSubmit, watch, resetField, setError,
        formState: { errors, isValid }, clearErrors } = useForm<FormInputs>({
        mode: 'onChange',
    });
    const UseErrorTranslation = useErrorTranslation();
    const [hasError, setHasError] = useState(false);
    const [hasSentPassword, setPasswordSent] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const urlPath = window.location.pathname;
    const token = urlPath.substring(urlPath.lastIndexOf('/') + 1);
    localStorage.setItem('tokenPassword', token);

    const goToLogin = () : void => {
        history(TencerRoutes.LOGIN_SIGN_IN);
    };

    const handlePasswordReset = async (password: string): Promise<void> => {
        setIsLoading(true);
        const tokenPassword = localStorage.getItem('tokenPassword');
        TencerApiClientSingleton.getInstance().postPasswordChange(password, tokenPassword)
            .then((res) => {
                state?.setPasswordReset(true);
                localStorage.removeItem('tokenPassword');
                setIsLoading(false);
                return res;
            })
            .catch((error) => {
                setError(
                    'validation',
                    { type: 'apiError', message: UseErrorTranslation.translate(error.response.data.code) },
                );
                state?.setNoPasswordReset(true);
                setHasError(true);
                setIsLoading(false);
                return error;
            });

    };

    useEffect(() => {

        if (state?.passwordReset === true) {
            setPasswordSent(true);
        }

        if (state?.noPasswordReset === true) {
            setHasError(true);
            resetField('password');
            resetField('password2');
        }

    }, [state?.currentUser, state?.errorMessage, state?.passwordReset, state?.noPasswordReset]);

    const submitHandler: SubmitHandler<FormInputs> = (data: FormInputs) => {
        handlePasswordReset(data.password);
    };

    const refreshForm = (): void => {
        state?.refreshForm();
        setHasError(false);
        resetField('validation');
        clearErrors();
    };

    const watchPassword = useRef({});
    watchPassword.current = watch('password');

    return (

        <div className="login-page-form">
            { hasSentPassword ?
                (
                    <span>
                        <ThankYouMessage message={t(PASSWORD_RESET_SUCCESS)} />
                        <button
                            type="button"
                            className="btn-link bg-transparent text-decoration-none border-0"
                            onClick={goToLogin}
                        >
                            {t(LOGIN)}
                        </button>
                    </span>

                ) :
                (
                    <form onSubmit={handleSubmit(submitHandler)}>
                        <p className="login-form-title">
                            {t(PASSWORD_RESET_TITLE)}
                        </p>

                        <p className="login-form-subtitle">
                            {t(PASSWORD_RESET_TEXT)}
                        </p>
                        <div className="form-floating">
                            <input
                                onClick={refreshForm}
                                type="password"
                                className="form-control"
                                id="floatingPasswordRecovery"
                                defaultValue=""
                                {...register(
                                    'password',
                                    { required: true },
                                )}
                            />
                            <label htmlFor="floatingPasswordRecovery">{t(NEW_PASSWORD)}</label>
                        </div>

                        <div className="form-floating">
                            <input
                                onClick={refreshForm}
                                type="password"
                                className="form-control"
                                id="floatingPasswordRecovery2"
                                defaultValue=""
                                {...register(
                                    'password2',
                                    { required: true,
                                        /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
                                        validate: (val: string):any => (
                                            val === watchPassword.current || t(PASSWORDS_NO_MATCH)
                                        ) },
                                )}
                            />
                            <label htmlFor="floatingPasswordRecovery2">{t(PASSWORDS_REPEAT)}</label>
                            <small id="passwordHelp" className="form-text text-muted">{t(SET_PASSWORD_HELP)}</small>
                            {errors.password2 || errors.password ?
                                <ErrorMessage message={t(PASSWORDS_NO_MATCH)} /> : null}
                        </div>

                        { hasError ? <ErrorMessage message={errors.validation?.message} /> : null }

                        <button
                            type="submit"
                            className="btn btn-primary centeredButton mt-5"
                            disabled={!isValid || isLoading}
                        >
                            {t(PASSWORD_RESET_TITLE)}
                        </button>
                    </form>

                )}

        </div>
    );
};
