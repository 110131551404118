import { useForm, UseFormReturn } from 'react-hook-form';
import { useContext, useEffect, useState } from 'react';
import { CourierServiceId } from 'app/shared/domain/value-objects/CourierServiceId';
import { TransportFormName, TransportFormRegisters } from '../../domain/entity/TransportForm';
import { ETransportType } from '../../../orders/model/value-objects/transport-type';
import { Courier } from '../../domain/entity/Courier';
import { CourierId } from '../../../shared/domain/value-objects/CourierId';
import { CourierService } from '../../../shared/domain/CourierService';
import { Price } from '../../../orders/model/value-objects/Price';
import { DependencyInjectionContext } from '../../../shared/context/DependecyInjectionContext';

export interface UseCartTransportResponse {
    couriers: Courier[]
    watchLiftGate: boolean
    pickHandlePrice: Price
    isGettingCouriers: boolean
    liftGateCouriers: Courier[]
    noLiftGateCouriers: Courier[]
    isGettingWowCouriers: boolean
    consolidatedCouriers: Courier[]
    isGettingCouriersServices: boolean
    couriersServices: CourierService[]
    afterChangeShippingAddressDefault: () => void
    UseTransportForm: UseFormReturn<TransportFormRegisters>
    findCourier: (courierId: CourierId) => Courier | undefined
    findCourierService: (courierServiceId: CourierServiceId) => CourierService | undefined
}

export const useCartTransport = (): UseCartTransportResponse => {
    const dic = useContext(DependencyInjectionContext);
    const [couriers, setCouriers] = useState<Courier[]>([]);
    const [isGettingCouriers, setIsGettingCouriers] = useState(false);
    const [isGettingWowCouriers, setIsGettingWowCouriers] = useState(false);
    const [liftGateCouriers, setLiftGateCouriers] = useState<Courier[]>([]);
    const [noLiftGateCouriers, setNoLiftGateCouriers] = useState<Courier[]>([]);
    const [consolidatedCouriers, setConsolidatedCouriers] = useState<Courier[]>([]);
    const [isGettingCouriersServices, setIsGettingCouriersServices] = useState(false);
    const [couriersServices, setCouriersServices] = useState<CourierService[]>([]);
    const [pickHandlePrice, setPickHandlePrice] = useState<Price>(new Price(0));

    const UseTransportForm = useForm<TransportFormRegisters>({
        defaultValues: {
            courier: '',
            comments: '',
            liftGate: false,
            courierService: '',
            consolidated: false,
            allowQuoteTransport: false,
            responsible: ETransportType.WILL_CALL,
        },
    });

    const watchCourier = UseTransportForm.watch(TransportFormName.COURIER);
    const watchLiftGate = UseTransportForm.watch(TransportFormName.LIFT_GATE);
    const watchResponsible = UseTransportForm.watch(TransportFormName.RESPONSIBLE);
    const watchConsolidated = UseTransportForm.watch(TransportFormName.CONSOLIDATED);

    const getWowCouriers = async (
        hasLiftGate: boolean,
        consolidated: boolean,
        onSuccess: (couriers: Courier[]) => void,
    ): Promise<void> => {
        await dic.wowCourierRepository
            .findAll(hasLiftGate, consolidated)
            .then(onSuccess);
    };

    const getLiftGateCouriers = async (): Promise<void> => {
        await getWowCouriers(true, false, setLiftGateCouriers);
    };

    const getNoLiftGateCouriers = async (): Promise<void> => {
        await getWowCouriers(false, false, setNoLiftGateCouriers);
    };

    const getConsolidatedCouriers = async (): Promise<void> => {
        await getWowCouriers(false, true, setConsolidatedCouriers);
    };

    const getAllWowCouriers = async (): Promise<void> => {
        try {
            setIsGettingWowCouriers(true);
            await Promise.all([
                getLiftGateCouriers(),
                getNoLiftGateCouriers(),
                getConsolidatedCouriers(),
            ]);
        } finally {
            setIsGettingWowCouriers(false);
        }
    };

    const getCouriers = async (): Promise<void> => {
        setIsGettingCouriers(true);
        await dic.courierRepository
            .findAll(watchResponsible === ETransportType.SMALL_PARCEL)
            .then(setCouriers)
            .finally(() => {
                setIsGettingCouriers(false);
            });
    };

    const getCourierServices = async (): Promise<void> => {
        if (watchCourier) {
            setIsGettingCouriersServices(true);
            await dic.courierRepository
                .getCourierServices(new CourierId(watchCourier))
                .then(setCouriersServices)
                .finally(() => {
                    setIsGettingCouriersServices(false);
                });
        }
    };

    const getPickHandlePrice = async (): Promise<void> => {
        await dic.transportRepository
            .getPickHandle()
            .then(setPickHandlePrice);
    };

    const afterChangeShippingAddressDefault = (): void => {
        UseTransportForm.resetField(TransportFormName.COURIER, {
            defaultValue: '',
        });
        UseTransportForm.resetField(TransportFormName.COURIER_SERVICE, {
            defaultValue: '',
        });

        if (watchResponsible === ETransportType.WOW) {
            getAllWowCouriers();
        }

        if (watchResponsible === ETransportType.OWN_LOGISTIC ||
            watchResponsible === ETransportType.SMALL_PARCEL
        ) {
            getCouriers();
        }
    };

    const findCourier = (courierId: CourierId): Courier | undefined => {
        if (watchResponsible === ETransportType.WOW) {
            if (watchConsolidated) {
                return consolidatedCouriers.find((courier) => courier.id.value === courierId.value);
            }

            if (watchLiftGate) {
                return liftGateCouriers.find((courier) => courier.id.value === courierId.value);
            }
            return noLiftGateCouriers.find((courier) => courier.id.value === courierId.value);
        }

        return couriers.find((courier) => courier.id.value === courierId.value);
    };

    const findCourierService = (courierServiceId: CourierServiceId): CourierService | undefined => {
        return couriersServices.find((courierService) => courierService.id.value === courierServiceId.value);
    };

    const onChangeResponsible = (): void => {
        if (watchResponsible === ETransportType.WOW) {
            getAllWowCouriers();
        }

        if (watchResponsible === ETransportType.OWN_LOGISTIC ||
            watchResponsible === ETransportType.SMALL_PARCEL
        ) {
            getCouriers();
            setCouriersServices([]);
        }

        UseTransportForm.resetField(TransportFormName.COURIER, {
            defaultValue: '',
        });
        UseTransportForm.resetField(TransportFormName.COURIER_SERVICE, {
            defaultValue: '',
        });
    };

    const onChangeCourier = (): void => {
        if (watchResponsible === ETransportType.OWN_LOGISTIC ||
            watchResponsible === ETransportType.SMALL_PARCEL
        ) {
            UseTransportForm.resetField(TransportFormName.COURIER_SERVICE, {
                defaultValue: '',
            });
            getCourierServices();
        }
    };

    useEffect(() => {
        onChangeResponsible();
    }, [watchResponsible]);

    useEffect(() => {
        onChangeCourier();
    }, [watchCourier]);

    useEffect(() => {
        getPickHandlePrice();
    }, []);

    useEffect(() => {
        UseTransportForm.resetField(TransportFormName.COURIER);
    }, [watchLiftGate]);

    useEffect(() => {
        UseTransportForm.resetField(TransportFormName.LIFT_GATE);
        UseTransportForm.resetField(TransportFormName.COURIER, {
            defaultValue: '',
        });
    }, [watchConsolidated]);

    return {
        couriers,
        findCourier,
        watchLiftGate,
        pickHandlePrice,
        couriersServices,
        UseTransportForm,
        liftGateCouriers,
        isGettingCouriers,
        noLiftGateCouriers,
        findCourierService,
        consolidatedCouriers,
        isGettingWowCouriers,
        isGettingCouriersServices,
        afterChangeShippingAddressDefault,
    };
};
