import { CollectionRepository } from '../repository/CollectionRepository';
import { Collection } from '../entity/Collection';
import { Pagination } from '../../../shared/domain/value-objects/Pagination';
import { CollectionType } from '../valueObject/CollectionType';

interface GetCollectionsUseCaseProps {
    type: CollectionType,
    textSearch: string,
    pagination: Pagination,
    collectionRepository: CollectionRepository,
}

export default async (props: GetCollectionsUseCaseProps): Promise<Collection[]> => {
    try {
        return await props.collectionRepository.findAll(
            props.type,
            props.textSearch,
            props.pagination,
        );
    } catch (error) {
        return Promise.reject(error);
    }
};
