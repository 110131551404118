import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { FILTER, HOME, IN_STOCK, NO_STOCK, PRODUCTS } from '../../../../../i18n/translations/TR';
import { ProductFilterCollection } from '../../../../orders/model/ProductFilterCollection';
import { TencerRoutes } from '../../../../shared/tencer-routing/TencerRoutes';
import { ReactComponent as IcFilter } from '../../../../../assets/img/icons/ic-filter.svg';
import { ReactComponent as IcClose } from '../../../../../assets/img/icons/ic-close.svg';

interface ProductsListHeaderProps {
    title: string;
    handleShow?: () => void;
    hasFiltersEnabled: boolean;
    onRemove?: (key: string) => void;
    filters?: ProductFilterCollection;
}

export const ProductsListHeader = (props: ProductsListHeaderProps): ReactElement => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const goToCatalog = ():void => {
        navigate(TencerRoutes.CATALOG);
    };

    const goToHome = ():void => {
        navigate(TencerRoutes.HOME);
    };

    const openFilters = ():void => {
        if (!props.handleShow) {
            return;
        }
        props.handleShow();
    };

    const removeFilter = (key: string):void => {
        if (!props.onRemove) {
            return;
        }
        props.onRemove(key);

    };

    const renderFilterTag = (
        key: string,
        label?: string,
    ): ReactElement | null => {
        const value = props.filters?.getValue(key)?.value;

        return value ? (
            <div className="filter-tag">
                <span>{label || value}</span>
                <IcClose
                    role="presentation"
                    onClick={(): void => removeFilter(key)}
                />
            </div>
        ) : null;
    };

    return (
        <div className="component-product-list-header">
            {!props.hasFiltersEnabled ? (
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item" onClick={goToHome} role="presentation">
                            {t(HOME)}
                        </li>
                        <li className="breadcrumb-item" onClick={goToCatalog} role="presentation">
                            {t(PRODUCTS)}
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            {props.title}
                        </li>
                    </ol>
                </nav>
            ) : null}

            { props.filters ? (
                <>
                    <div className="header-catalog header-actions">
                        <h3 className="section-title">
                            {props.title}
                        </h3>
                        {
                            props.hasFiltersEnabled ? (
                                <div className="actions">
                                    <p
                                        className="bg-transparent"
                                        onClick={openFilters}
                                        aria-hidden="true"
                                    >
                                        <IcFilter />
                                        <span>{t(FILTER)}</span>
                                    </p>
                                </div>
                            ) : null
                        }
                    </div>
                    <div className={classNames(
                        'filter-tag-container',
                        Object.keys(props.filters || {}).length > 0 ? 'd-flex' : 'd-none',
                    )}
                    >
                        {renderFilterTag('material')}
                        {renderFilterTag('collection')}
                        {renderFilterTag('brand')}
                        {renderFilterTag('stock', props.filters?.getValue('stock')?.value ? t(IN_STOCK) : t(NO_STOCK))}
                    </div>
                </>
            ) : null }
        </div>
    );
};
