import React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';
import { NAME } from '../../../../../../i18n/translations/TR';

interface ShippingAddressContactFieldProps {
    errorField: FieldError | undefined
    contactRegister: UseFormRegisterReturn
}

export const ShippingAddressContactField = (
    {
        errorField,
        contactRegister,
    }: ShippingAddressContactFieldProps,
): React.ReactElement => {
    const { t } = useTranslation();
    return (
        <div className="form-field contact-field">
            <label
                htmlFor="contact"
                className="form-label is-required"
            >
                {t(NAME)}
            </label>
            <input
                type="text"
                id="contact"
                maxLength={60}
                {...contactRegister}
                className="form-control"
            />
            {errorField ? (
                <div className="error-input-form">
                    {errorField.message}
                </div>
            ) : null}
        </div>
    );
};
