import { CollectionId } from '../valueObject/CollectionId';
import { CollectionName } from '../valueObject/CollectionName';
import { Image } from '../../../product/domain/valueObject/Image';

export class Collection {

    public constructor(
        private readonly _id: CollectionId,
        private readonly _name: CollectionName,
        private readonly _date: Date,
        private readonly _image: Image,
        private readonly _imageHeader: Image,
    ) {}

    public get id(): CollectionId {
        return this._id;
    }

    public get name(): CollectionName {
        return this._name;
    }

    public get date(): Date {
        return this._date;
    }

    public get image(): Image {
        return this._image;
    }

    public get imageHeader(): Image {
        return this._imageHeader;
    }

}
