import { WarehouseLocation } from '../../../../../domain/entity/WarehouseLocation';
import { WarehouseLocationResponse } from '../../../../../../shared/infrastructure/tencer-api/dto/responses/WarehouseLocationResponse';

export class WarehouseLocationDataMapper {

    public static fromResponse(response: WarehouseLocationResponse): WarehouseLocation {
        return new WarehouseLocation(
            response.code,
            response.name,
        );
    }

}
