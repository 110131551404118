export interface TransportTypeResponse {
    name: string,
    value: TransportTypeResponseValue,
}

export enum TransportTypeResponseValue {
    WOW = 1,
    OWN_LOGISTIC = 2,
    WILL_CALL = 3,
    SMALL_PARCEL = 4,
}
