import React from 'react';
import { useTranslation } from 'react-i18next';
import { DOWN, LOADS, SEE_DOWNLOADS, TEXT_DOWNLOADS } from '../../../i18n/translations/TR';
import { DownloadsImage } from '../../../assets/img';
import { WOW_DOWNLOADS_HREF } from '../constants/hrefConstants';

export const DownloadsBanner: React.FC = () => {
    const { t } = useTranslation();
    return (
        <section className="product-detail-section pt-5 bg-light">
            <div className="container">
                <div className="row">

                    <div className="col-md-8 my-auto">
                        <h1 className="banner-section-title">
                            {t(DOWN)}
                            <span className="f-serif">{t(LOADS)}</span>
                        </h1>
                        <p>{t(TEXT_DOWNLOADS)}</p>
                        <a
                            href={WOW_DOWNLOADS_HREF}
                            className="btn btn-primary-outline mt-5"
                            target="_blank"
                            rel="noreferrer"
                        >
                            {t(SEE_DOWNLOADS)}
                        </a>
                    </div>

                    <div className="col-md-4">
                        <img src={DownloadsImage} alt="downloadsImage" />
                    </div>

                </div>
            </div>
        </section>
    );
};
