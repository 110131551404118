import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Product } from 'app/product/domain/entity/Product';
import { getArea } from 'app/shared/helper/getArea';
import { ADD, REF } from '../../../../../../../i18n/translations/TR';
import { Currency } from '../../../../../../shared/components/Currency';
import { usePermission } from '../../../../../../shared/hooks/usePermission';
import { CollectionId } from '../../../../../domain/valueObject/CollectionId';

interface CollectionTileItemProps {
    product: Product
}

export const CollectionTileItem = (props: CollectionTileItemProps): ReactElement => {
    const { t } = useTranslation();
    const permission = usePermission();

    return (
        <li className="component-collection-tile-item product-grid-item">
            <div className="product-grid-image">
                {props.product.image ? (
                    <img
                        alt="product"
                        src={props.product.image.value}
                    />
                ) : null }
            </div>

            <div className="product-grid-item-details">
                {props.product.collectionName ? (
                    <div className="product-grid-title">
                        {props.product.collectionName.value}
                    </div>
                ) : null}
                {props.product.format ? (
                    <div className="product-grid-size">
                        {props.product.format.value}
                    </div>
                ) : null}
                {props.product.description ? (
                    <div className="product-grid-finish">
                        {props.product.description}
                    </div>
                ) : null}
                {props.product.family ? (
                    <div className="product-grid-family">
                        {`${props.product.family}`}
                    </div>
                ) : null}
                <div className="product-grid-ref">
                    {`${t(REF)}${props.product.id.value}`}
                </div>
            </div>
            <div className="product-grid-item-buy">
                {props.product.price !== null && props.product.unit ?
                    (
                        <div className="product-grid-item-footer">
                            <div className="product-grid-price">
                                <div className="product-grid-import">
                                    <Currency
                                        className="product-price import"
                                        value={props.product?.price.value}
                                    />
                                </div>
                                <div className="product-grid-units-type">
                                    {getArea(props.product.unit)}
                                </div>
                            </div>
                        </div>
                    ) : null}
                {permission.canDoOrders() &&
                props.product.collectionId?.value !== CollectionId.MARKETING_ID ?
                    (
                        <button
                            type="button"
                            className="btn btn-secondary btn-mini"
                        >
                            {t(ADD)}
                        </button>
                    ) : null}
            </div>
        </li>
    );
};
