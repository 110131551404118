import React, { ReactElement } from 'react';
import { useCollectionDetailScreenController } from './useCollectionDetailScreenController';
import { CollectionInfo } from '../../component/collectionInfo/CollectionInfo';

export const CollectionDetailScreen = (): ReactElement => {
    const controller = useCollectionDetailScreenController();

    return (
        <div className="component-collection-detail-screen">
            <CollectionInfo
                data={controller.data}
                error={controller.error}
                isLoading={controller.isLoading}
            />
        </div>
    );

};
