import { COWeight } from 'app/shared/components/CO-weight';
import { Quantity } from 'app/shared/components/Quantity';
import {
    BOX_UNIT,
    BOXES,
    FAMILY,
    LOT,
    OUTLET,
    PALLETS,
    PALLETS_SINGLE,
    PIECE_UNIT,
    PIECE_UNIT_SINGLE,
    PRODUCT_INFO_WAREHOUSE_LABEL,
    SKU,
} from 'i18n/translations/TR';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import CartItem from '../domain/model/CartItem';
import { Product } from '../../product/domain/entity/Product';

interface CartItemProductDetailsProps {
    item: CartItem;
    squareUnits: string;
    measureUnitLabel: string;
    product: Product | undefined;
}

export const CartItemProductDetails = (props: CartItemProductDetailsProps): React.ReactElement => {
    const { t } = useTranslation();
    return (
        <div className="product-item-details">
            <div className="product-subtitle-cart">{props.product?.collectionName?.value}</div>
            <div className="product-subtitle-cart-group">
                { props.product ? (
                    <>
                        <div className="product-title">{props.product?.description}</div>
                        <strong className="product-title-measure">
                            {`${props.product?.format?.value} ${props.measureUnitLabel}`}
                        </strong>
                        {props.product && props.product.outlet ? (
                            <div className="product-outlet-label">
                                <span className="product-stock text-start">
                                    {t(OUTLET)}
                                </span>
                            </div>
                        ) : null}
                    </>
                ) : <Skeleton width={200} />}
            </div>
            <div className="product-cart-data-wrap">
                <div className="product-tag">
                    <span className="product-tag-label">
                        {t(SKU).toUpperCase()}
                    </span>
                    <span className="product-tag-value">
                        {props.item.productId.value}
                    </span>
                </div>
                {props.item.lot.reference !== null ? (
                    <div className="product-tag">
                        <span className="product-tag-label">
                            {t(LOT).toUpperCase()}
                        </span>
                        <span className="product-tag-value">
                            {props.item.lot.reference}
                        </span>
                    </div>
                ) : null}
                {props.product?.family ? (
                    <div className="product-tag">
                        <span className="product-tag-label">
                            {t(FAMILY)}
                        </span>
                        <span className="product-tag-value">
                            {props.product?.family}
                        </span>
                    </div>
                ) : null}
                {props.item.lot.warehouse ? (
                    <div className="product-tag">
                        <span className="product-tag-label">
                            {t(PRODUCT_INFO_WAREHOUSE_LABEL)}
                        </span>
                        <span className="product-tag-value">
                            {props.item.lot.warehouse.code}
                        </span>
                    </div>
                ) : null}
                {/* Q. pallet */}
                {props.item.quantityInPallets < 1 ? null : (
                    <div className="product-tag">
                        <Quantity
                            value={props.item.quantityInPallets}
                            label={
                                props.item.quantityInPallets > 1 ?
                                    `${t(PALLETS).toLowerCase()}`
                                    : `${t(PALLETS_SINGLE).toLowerCase()}`
                            }
                        />
                    </div>
                )}
                {/* Q. boxes */}
                {props.item.quantityInBoxes < 1 ? null : (
                    <div className="product-tag">
                        <Quantity
                            value={props.item.quantityInBoxes}
                            label={
                                props.item.quantityInBoxes > 1 ?
                                    `${t(BOXES).toLowerCase()}`
                                    : `${t(BOX_UNIT).toLowerCase()}`
                            }
                        />
                    </div>
                )}
                <div className="product-tag">
                    <Quantity
                        value={props.item.quantityInPieces}
                        label={props.item.quantityInPieces > 1 ? `${t(PIECE_UNIT)}` : `${t(PIECE_UNIT_SINGLE)}`}
                    />
                </div>
                {!props.product?.unit?.isPiece() ? (
                    <div className="product-tag">
                        <Quantity
                            value={props.item.quantity.amount}
                            label={props.squareUnits}
                            scale={3}
                        />
                    </div>
                ) : null}
                {/* Q. weight */}
                <div className="product-tag">
                    <COWeight value={props.item?.weight?.weight} />
                </div>
            </div>
        </div>

    );
};
