import { CollectionDataMapper } from 'app/collection/infrastructure/repository/api/dataMapper/CollectionDataMapper';
import { Pagination } from 'app/shared/domain/value-objects/Pagination';
import { TencerApiClient } from '../../../../shared/infrastructure/tencer-api/TencerApiClient';
import { CollectionDetails } from '../../../domain/entity/CollectionDetails';
import { CollectionRepository } from '../../../domain/repository/CollectionRepository';
import { CollectionDetailsDataMapper } from './dataMapper/CollectionDetailsDataMapper';
import { Collection } from '../../../domain/entity/Collection';
import { CollectionId } from '../../../domain/valueObject/CollectionId';
import { GetCollectionsRequestDataMapper } from './dataMapper/GetCollectionsRequestDataMapper';
import { CollectionType } from '../../../domain/valueObject/CollectionType';

export class ApiCollectionRepository implements CollectionRepository {

    public constructor(
        private readonly client: TencerApiClient,
    ) {}

    public async findAll(
        type: CollectionType,
        textSearch: string,
        pagination: Pagination,
    ): Promise<Collection[]> {
        const response = await this.client.getCollections(GetCollectionsRequestDataMapper.fromDomain(
            type,
            textSearch,
            pagination,
        ));

        return response.map(CollectionDataMapper.fromResponse);
    }

    public async findById(collectionId: CollectionId): Promise<CollectionDetails> {
        const response = await this.client.getCollectionDetails({
            payload: {
                collection: collectionId.value,
            },
        });

        return CollectionDetailsDataMapper.fromResponse(response);
    }

    public async findMarketing(textSearch: string): Promise<CollectionDetails> {
        const response = await this.client.getMarketingCollectionDetails({
            payload: {
                textSearch,
            },
        });

        return CollectionDetailsDataMapper.fromResponse(response);
    }

}
