import { TencerApiClient } from '../../../../../shared/infrastructure/tencer-api/TencerApiClient';
import { WarehouseLocationRepository } from '../../../../domain/repository/WarehouseLocationRepository';
import { WarehouseLocationDataMapper } from '../product/dataMapper/WarehouseLocationDataMapper';
import { WarehouseLocation } from '../../../../domain/entity/WarehouseLocation';

export class ApiWarehouseLocationRepository implements WarehouseLocationRepository {

    public constructor(
        private readonly client: TencerApiClient,
    ) {}

    public async findAll(): Promise<WarehouseLocation[]> {
        const response = await this.client.getWarehousesLocation();
        return response.map(WarehouseLocationDataMapper.fromResponse);
    }

}
