export enum ProductTypeValue {
    ALL = 'ALL',
    OUTLET = 'OUTLET',
    CATALOG = 'CATALOG',
    BESTSELLER = 'BESTSELLER',
}

export class ProductType {

    public constructor(private readonly _value: ProductTypeValue) {
        this._ensureIsValid();
    }

    public get value(): ProductTypeValue {
        return this._value;
    }

    private _ensureIsValid(): void {
        if (!Object.values(ProductTypeValue).includes(this._value)) {
            throw new Error(`Invalid product type value: ${this._value}`);
        }
    }

}
