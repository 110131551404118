import classNames from 'classnames';
import React, { ReactElement } from 'react';

interface ProductTabProps {
    id: string,
    text: string,
    target: string,
    active: boolean,
    className?: string,
    onClick: () => void,
}

export const ProductTab = (props: ProductTabProps): ReactElement => {
    return (
        <button
            role="tab"
            id={props.id}
            type="button"
            data-bs-toggle="pill"
            aria-selected="true"
            aria-controls={props.target}
            data-bs-target={`#${props.target}`}
            className={classNames('nav-link', props.className, { active: props.active })}
            onClick={props.onClick}
        >
            {props.text}
        </button>
    );
};

ProductTab.defaultProps = {
    className: '',
};
