import i18next from 'i18next';
import { OWN_LOGISTIC_LABEL, SMALL_PARCEL_LABEL, WILL_CALL_LABEL, WOW_TRANSPORT_LABEL } from '../../../../i18n/translations/TR';

export enum ETransportType {
    WILL_CALL = 'Will call',
    OWN_LOGISTIC = 'Own logistic',
    SMALL_PARCEL = 'Small parcel',
    WOW = 'Wow'
}

export class TransportType {

    private readonly _value: ETransportType;

    public constructor(value: ETransportType) {
        this.ensureIsNotUndefined(value);
        this._value = value;
    }

    private ensureIsNotUndefined(value: ETransportType): void {
        if (value === undefined) {
            throw new Error('TransportType value is undefined');
        }
    }

    public get value(): ETransportType {
        return this._value;
    }

    public get translate(): string {
        switch (this._value) {
            case ETransportType.WILL_CALL:
                return i18next.t(WILL_CALL_LABEL) as string;
            case ETransportType.OWN_LOGISTIC:
                return i18next.t(OWN_LOGISTIC_LABEL) as string;
            case ETransportType.SMALL_PARCEL:
                return i18next.t(SMALL_PARCEL_LABEL) as string;
            case ETransportType.WOW:
            default:
                return i18next.t(WOW_TRANSPORT_LABEL) as string;
        }
    }

}
