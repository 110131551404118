import { CActionButton } from 'app/shared/components/CActionButton/CActionButton';
import { CANCEL, CREATE, FORM_FIELD_REQUIRED, INVALID_USA_PHONE } from 'i18n/translations/TR';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ShippingAddress } from '../../../model/ShippingAddress';
import { Province } from '../../../model/Province';
import { CountryByProvince } from '../../../model/countryByProvince';
import { ShippingAddressDescriptionField } from './fields/ShippingAddressDescriptionField';
import { ShippingAddressCountryField } from './fields/ShippingAddressCountryField';
import { ShippingAddressProvinceField } from './fields/ShippingAddressProvinceField';
import { ShippingAddressPostalCodeField } from './fields/ShippingAddressPostalCodeField';
import { ShippingAddressLocalityField } from './fields/ShippingAddressLocalityField';
import { ShippingAddressPhoneField } from './fields/ShippingAddressPhoneField';
import { ShippingAddressFaxField } from './fields/ShippingAddressFaxField';
import { ShippingAddressFullAddressField } from './fields/ShippingAddressFullAddressField';
import { useToken } from '../../../../shared/hooks/useToken';
import { Origin } from '../../../../shared/domain/value-objects/Origin';
import { ShippingAddressContactField } from './fields/ShippingAddressContactField';
import { useOrigin } from '../../../../shared/hooks/useOrigin';

export interface ShippingAddressFormData {
    description: string | undefined
    countryId: string | undefined
    provinceId: string | undefined
    postalCode: string| undefined
    locality: string| undefined
    address: string | undefined
    address1: string| undefined
    address2: string| undefined
    phone: string| undefined
    contact: string| undefined
    fax: string| undefined
    code: string| undefined
    maxCp: number | undefined
    minCp: number | undefined
}

interface Props {
    countries: CountryByProvince[],
    onSubmit: (e?: (React.BaseSyntheticEvent<object> | undefined)) => Promise<void>,
    onCancel: () => void,
    address: ShippingAddress | undefined,
    isBusy: boolean,
    form: UseFormReturn<ShippingAddressFormData>
    formErrorMessage: string | undefined
}

export const ShippingAddressForm: React.FC<Props> = (props: Props) => {
    const [provinces, setProvinces] = React.useState<Province[]>([]);
    const UseToken = useToken();
    const origin = useOrigin();
    const { t } = useTranslation();

    const watchCountry = props.form.watch('countryId');
    const watchProvince = props.form.watch('provinceId');

    React.useEffect(() => {
        const foundCountry = props.countries.filter(
            (countryByProvince) => countryByProvince.country && countryByProvince.country.countryId === watchCountry,
        );
        setProvinces(foundCountry[0]?.provinces);

    }, [watchCountry]);

    React.useEffect(() => {
        if (provinces) {
            const foundProvince = provinces.find((province) => {
                return province.id.value === watchProvince;

            });
            if (foundProvince) {
                props.form.setValue('minCp', foundProvince.postalCodeMin || undefined);
                props.form.setValue('maxCp', foundProvince.postalCodeMax || undefined);
            }
        }
    }, [watchProvince, provinces]);

    const requiredFieldError = t(FORM_FIELD_REQUIRED);

    const descriptionRegister = props.form.register('description', { required: requiredFieldError });
    const descriptionRegisterError = props.form.formState.errors.description;
    const countryRegister = props.form.register('countryId', { required: requiredFieldError });
    const countryRegisterError = props.form.formState.errors.countryId;
    const provinceRegister = props.form.register('provinceId', { required: requiredFieldError });
    const provinceRegisterError = props.form.formState.errors.provinceId;
    const postalCodeRegister = props.form.register('postalCode', { required: requiredFieldError });
    const postalCodeRegisterError = props.form.formState.errors.postalCode;
    const localityRegister = props.form.register('locality', { required: requiredFieldError });
    const localityRegisterError = props.form.formState.errors.locality;
    const phoneRegister = props.form.register(
        'phone',
        {
            required: requiredFieldError,
            ...(origin === Origin.USA && {
                pattern: {
                    value: /^(\(?\d{3}\)?[\s.-]?)\d{3}[\s.-]?\d{4}$/,
                    message: t(INVALID_USA_PHONE),
                },
            }),
        },
    );
    const phoneError = props.form.formState.errors.phone;
    const contactRegister = props.form.register('contact', { required: requiredFieldError });
    const contactError = props.form.formState.errors.contact;
    const faxRegister = props.form.register('fax');
    const addressRegister = props.form.register('address', { required: requiredFieldError });
    const addressError = props.form.formState.errors.address;
    const address1Register = props.form.register('address1');
    const address2Register = props.form.register('address2');

    return (
        <form
            className="shipping_address-form"
            onSubmit={props.onSubmit}
        >
            {props.formErrorMessage ? (
                <div className="shipping_address-form-error-container">
                    {props.formErrorMessage}
                </div>
            ) : null}
            <div className="shipping_address-form-container">
                <ShippingAddressDescriptionField
                    descriptionRegister={descriptionRegister}
                    errorField={descriptionRegisterError}
                />

                <ShippingAddressCountryField
                    countryRegister={countryRegister}
                    errorField={countryRegisterError}
                    countries={props.countries}
                />

                <ShippingAddressProvinceField
                    provinceRegister={provinceRegister}
                    errorField={provinceRegisterError}
                    provinces={provinces}
                    provinceId={props.form.watch('provinceId') || ''}
                />

                <ShippingAddressPostalCodeField
                    postalCodeRegister={postalCodeRegister}
                    errorField={postalCodeRegisterError}
                />

                <ShippingAddressLocalityField
                    localityRegister={localityRegister}
                    errorField={localityRegisterError}
                />

                <ShippingAddressFullAddressField
                    fullAddressRegister={addressRegister}
                    errorField={addressError}
                    fullAddress1Register={address1Register}
                    fullAddress2Register={address2Register}
                />

                <ShippingAddressPhoneField
                    phoneRegister={phoneRegister}
                    errorField={phoneError}
                />

                {UseToken.origin === Origin.EUROPE ? (
                    <ShippingAddressFaxField
                        faxRegister={faxRegister}
                    />
                ) : null}

                {UseToken.origin === Origin.USA ? (
                    <ShippingAddressContactField
                        errorField={contactError}
                        contactRegister={contactRegister}
                    />
                ) : null}

                <div className="actions">
                    <CActionButton
                        text={t(CANCEL)}
                        onClick={props.onCancel}
                        textIsBusy={t(CANCEL)}
                        className="btn btn-link"
                    />
                    <CActionButton
                        text={t(CREATE)}
                        type="submit"
                        className="btn btn-primary"
                        isBusy={props.isBusy}
                        textIsBusy={t(CREATE)}
                    />
                </div>
            </div>
        </form>
    );
};
