import React from 'react';
import { Currency } from 'app/shared/components/Currency';
import { Discount } from '../../../model/value-objects/Discount';
import { Price } from '../../../model/value-objects/Price';
import { DiscountsSummaryLayout } from './DiscountsSummaryLayout';

interface DiscountsSummaryPropsInterface {
    layout?: string,
    gross: Price,
    discounts: Discount[],
}

export const DiscountsSummary: React.FC<DiscountsSummaryPropsInterface> = (props: DiscountsSummaryPropsInterface) => {
    let totalDiscountAmount = 0;
    const discountAmount = (discount: Discount): number => {
        if (totalDiscountAmount === 0) {
            totalDiscountAmount = props.gross?.amount ?? 0;
        }
        const amount = Number(((totalDiscountAmount * discount.value) / 100).toFixed(2));
        totalDiscountAmount -= amount;
        return amount;
    };

    return (
        <ul className={`discounts-summary l-${props.layout}`}>
            {props.discounts.map((discount) => (
                discount.value > 0 ? (
                    <li className="discounts-summary-item" key={discount.description}>
                        <div className="discounts-summary-item-description-value">
                            <span className="discounts-summary-item-description">
                                {discount.description}
                            </span>
                            <span className="discounts-summary-item-value">
                                {`(-${discount.value}%)`}
                            </span>
                        </div>
                        <span className="discounts-summary-item-total">
                            <span>-</span>
                            <Currency
                                value={discountAmount(discount)}
                            />
                        </span>
                    </li>
                ) : null

            ))}
        </ul>
    );
};

DiscountsSummary.defaultProps = {
    layout: DiscountsSummaryLayout.FULL,
};
