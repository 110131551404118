import { Pagination } from 'app/shared/domain/value-objects/Pagination';
import { ProductRepository } from 'app/product/domain/repository/ProductRepository';
import { ProductFilterCollection } from 'app/orders/model/ProductFilterCollection';
import { ProductId } from 'app/product/domain/valueObject/ProductId';
import { Product } from 'app/product/domain/entity/Product';
import { ProductType } from '../../valueObject/ProductType';

interface GetProductsUseCaseProps {
    type: ProductType,
    textSearch: string,
    pagination: Pagination,
    productsId: ProductId[],
    filters?: ProductFilterCollection,
    productRepository: ProductRepository,
}

export default async (props: GetProductsUseCaseProps): Promise<Product[]> => {
    try {
        return await props.productRepository.findAll(
            props.type,
            props.pagination,
            props.productsId,
            props.textSearch,
            props.filters,
        );
    } catch (error) {
        return Promise.reject(error);
    }
};
