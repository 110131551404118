import { useContext, useEffect, useState } from 'react';
import getProductsUseCase from 'app/product/domain/useCase/product/GetProductsUseCase';
import { useIsMounted } from '../../shared/hooks/useIsMounted';
import getCollectionsUseCase from '../../collection/domain/useCase/GetCollectionsUseCase';
import { DependencyInjectionContext } from '../../shared/context/DependecyInjectionContext';
import { Pagination } from '../../shared/domain/value-objects/Pagination';
import { Collection } from '../../collection/domain/entity/Collection';
import { PaginationOrder } from '../../shared/domain/value-objects/PaginationOrder';
import { Product } from '../../product/domain/entity/Product';
import { ProductTypeValue, ProductType } from '../../product/domain/valueObject/ProductType';
import { CollectionType, CollectionTypeValue } from '../../collection/domain/valueObject/CollectionType';

interface useHomeScreenControllerResponse {
    collections: Collection[]
    bestSellersProducts: Product[]
    outletProducts: Product[]
    isGettingCollections: boolean
    errorGettingCollections: boolean
    isGettingOutletProducts: boolean
    errorGettingOutletProducts: boolean
    isGettingBestSellersProducts: boolean
    errorGettingBestSellersProducts: boolean
}

export const useHomeScreenController = (): useHomeScreenControllerResponse => {
    const defaultPaginationPage = 1;
    const defaultPaginationResults = 5;
    const dic = useContext(DependencyInjectionContext);
    const [collections, setCollections] = useState<Collection[]>([]);
    const [isGettingCollections, setIsGettingCollections] = useState<boolean>(true);
    const [errorGettingCollections, setErrorGettingCollections] = useState<boolean>(false);

    const [outletProducts, setOutletProducts] = useState<Product[]>([]);
    const [isGettingOutletProducts, setIsGettingOutletProducts] = useState<boolean>(true);
    const [errorGettingOutletProducts, setErrorGettingOutletProducts] = useState<boolean>(false);

    const [bestSellersProducts, setBestSellersProducts] = useState<Product[]>([]);
    const [isGettingBestSellersProducts, setIsGettingBestSellersProducts] = useState<boolean>(true);
    const [errorGettingBestSellersProducts, setErrorGettingBestSellersProducts] = useState<boolean>(false);
    const isMounted = useIsMounted();

    const getCollections = async (): Promise<void> => {
        const collectionPagination = new Pagination(
            defaultPaginationPage,
            defaultPaginationResults,
            PaginationOrder.DATE,
        );

        await getCollectionsUseCase({
            type: new CollectionType(CollectionTypeValue.HOME),
            textSearch: '',
            pagination: collectionPagination,
            collectionRepository: dic.collectionRepository,
        }).then((data) => {
            if (!isMounted) {
                return;
            }
            setCollections(data);
        })
            .catch(() => {
                setErrorGettingCollections(true);
            })
            .finally(() => setIsGettingCollections(false));
    };

    const getOutletProducts = async (): Promise<void> => {
        const pagination = new Pagination(
            defaultPaginationPage,
            defaultPaginationResults,
            null,
        );

        await getProductsUseCase({
            pagination,
            productsId: [],
            textSearch: '',
            productRepository: dic.productRepository,
            type: new ProductType(ProductTypeValue.OUTLET),
        })
            .then((data) => {
                setOutletProducts(data);
            })
            .catch(() => setErrorGettingOutletProducts(true))
            .finally(() => setIsGettingOutletProducts(false));
    };

    const getBestSellersProducts = async (): Promise<void> => {
        const pagination = new Pagination(
            defaultPaginationPage,
            defaultPaginationResults,
            null,
        );

        await getProductsUseCase({
            pagination,
            productsId: [],
            textSearch: '',
            productRepository: dic.productRepository,
            type: new ProductType(ProductTypeValue.BESTSELLER),
        })
            .then((data) => {
                setBestSellersProducts(data);
            })
            .catch(() => setErrorGettingBestSellersProducts(true))
            .finally(() => setIsGettingBestSellersProducts(false));
    };

    useEffect(() => {
        getCollections();
        getOutletProducts();
        getBestSellersProducts();
    }, []);

    return {
        collections,
        outletProducts,
        bestSellersProducts,
        isGettingCollections,
        isGettingOutletProducts,
        errorGettingCollections,
        errorGettingOutletProducts,
        isGettingBestSellersProducts,
        errorGettingBestSellersProducts,
    };
};
