import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Offcanvas } from 'react-bootstrap';
import {
    APPLY_FILTERS,
    BRANDS,
    COLLECTIONS,
    FILTERS,
    MATERIAL,
    REMOVE_FILTERS,
    SELECT,
    SHOW_ONLY,
} from '../../../../../i18n/translations/TR';
import { ProductFilter } from '../../../../orders/model/ProductFilter';
import { ProductFilterCollection } from '../../../../orders/model/ProductFilterCollection';
import { Spinner } from '../../../../shared/components/Spinner';
import { useProductFilterModalController } from './useProductFilterModalController';

interface ProductFilterModalProps {
    show: boolean,
    onHide: () => void,
    onReset: () => void,
    filters: ProductFilterCollection,
    onApply: (filters : ProductFilterCollection) => Promise<void>,
}

export const ProductFilterModal = (props: ProductFilterModalProps): ReactElement => {
    const { t } = useTranslation();
    const controller = useProductFilterModalController(
        props.filters,
        props.onApply,
    );
    const data = controller.data;

    return (
        <Offcanvas
            show={props.show}
            placement="end"
            className="offcanvas"
            onHide={(): void => {
                props.onHide();
            }}
        >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>{t(FILTERS)}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>

                { controller.loading ? <Spinner classCss="header-section-spinner" /> : (

                    <form id="filter-form" onSubmit={controller.productFilterUseForm.handleSubmit(controller.submitHandler)}>
                        <div className="mb-4">

                            <label htmlFor="selectFormat" className="form-label">{t(MATERIAL)}</label>
                            <select
                                className="form-select"
                                id="selectFormat"
                                {...controller.productFilterUseForm.register(
                                    'material',
                                )}
                                onChange={(e): void => controller.productFilterUseForm.setValue(
                                    'pasteString',
                                    e.target.selectedOptions[0].label,
                                )}

                            >
                                <option value="">{t(SELECT)}</option>
                                {data?.pastes ?
                                    (
                                        data.pastes.map((paste) => (
                                            <option key={paste} value={paste}>{paste}</option>
                                        ))
                                    ) : null}
                            </select>
                        </div>

                        <div className="mb-4">

                            <label htmlFor="selectCollection" className="form-label">{t(COLLECTIONS)}</label>
                            <select
                                className="form-select"
                                id="selectCollection"
                                {...controller.productFilterUseForm.register(
                                    'collection',
                                )}
                                onChange={(e): void => {
                                    props.filters.addFilter(
                                        new ProductFilter('collection', e.target.selectedOptions[0].value),
                                    );
                                    controller.productFilterUseForm.setValue('collectionString', e.target.selectedOptions[0].label);
                                    controller.productFilterUseForm.setValue('collection', e.target.selectedOptions[0].value);
                                }}
                            >
                                <option value="">{t(SELECT)}</option>
                                {data?.collections ?
                                    (
                                        data.collections.map((collection) => (
                                            <option
                                                key={collection.id}
                                                value={collection.id}
                                                label={collection.name}
                                            >
                                                {collection.name}
                                            </option>

                                        ))
                                    ) : null}
                            </select>
                        </div>

                        <div className="mb-4">

                            <label htmlFor="selectBrand" className="form-label">{t(BRANDS)}</label>
                            <select
                                className="form-select"
                                id="selectBrand"
                                {...controller.productFilterUseForm.register(
                                    'brand',
                                )}
                                onChange={(e): void => controller.productFilterUseForm.setValue(
                                    'brandString',
                                    e.target.selectedOptions[0].label,
                                )}
                            >
                                <option value="">{t(SELECT)}</option>
                                {data?.brands ?
                                    (
                                        data.brands.map((brand) => (
                                            <option key={brand} value={brand}>{brand}</option>

                                        ))
                                    ) : null}
                            </select>
                        </div>

                        <div className="row form-check mt-5 mb-4">
                            <div className="col">
                                <label className="form-check-label" htmlFor="defaultCheckbox">
                                    {t(SHOW_ONLY)}
                                </label>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="defaultCheckbox"
                                    {...controller.productFilterUseForm.register(
                                        'checkbox',
                                    )}
                                />
                            </div>
                        </div>
                    </form>
                ) }
            </Offcanvas.Body>
            <div className="filter-footer">
                <button
                    type="submit"
                    className="btn btn-link text-decoration-none"
                    onClick={controller.removeFilters}
                >
                    {t(REMOVE_FILTERS)}
                </button>
                <button
                    type="submit"
                    className="btn btn-primary"
                    data-bs-dismiss="offcanvas"
                    form="filter-form"
                >
                    {t(APPLY_FILTERS)}
                </button>
            </div>
        </Offcanvas>
    );
};
