import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useSupportEmail } from 'app/shared/hooks/useSupportEmail';
import PageBannerModel from '../../../../page-banner/model/PageBanner';
import { PAYMENT_TERMS_LIMIT_EXCEEDED_ERROR } from '../../../../../i18n/translations/TR';
import { URL } from '../../../../shared/domain/value-objects/URL';
import { PageBanner } from '../../../../page-banner/component/PageBanner';

interface TermsBannerProps {
    pageId: string
}

export const TermsBanner = (props: TermsBannerProps): ReactElement => {
    const { t } = useTranslation();
    const { getSupportEmailLink } = useSupportEmail();

    return (
        <PageBanner
            classes={['danger']}
            pageId={props.pageId}
            forcedMessage={
                new PageBannerModel(
                    t(PAYMENT_TERMS_LIMIT_EXCEEDED_ERROR),
                    new URL(getSupportEmailLink()),
                )
            }
        />
    );
};
