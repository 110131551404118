import React from 'react';
import { ORDER_TOTAL_AMOUNT_LABEL } from 'i18n/translations/TR';
import { getRoundedValues } from 'app/shared/helper/getRoundedValues';
import { t } from 'i18next';
import { Currency } from '../../../shared/components/Currency';
import { Price } from '../../../orders/model/value-objects/Price';
import './total-price.scss';

interface TotalPriceProps {
    price: Price;
}

export const TotalPrice: React.FC<TotalPriceProps> = ({ price }) => {
    return (
        <div className="total-price">
            <div className="total-price-header">
                <div className="total-price-title">
                    <span>{t(ORDER_TOTAL_AMOUNT_LABEL)}</span>
                </div>
                <div className="total-price-import">
                    <Currency className="product-price-import" value={getRoundedValues(price.amount ?? 0.0)} />
                </div>
            </div>
        </div>
    );
};
