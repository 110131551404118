import React, { createRef } from 'react';
import Typeahead from 'react-bootstrap-typeahead/types/core/Typeahead';
import { useTranslation } from 'react-i18next';
import {
    LOADING_ORDER_TRANSPORT_TYPE, LOADING_ORDER_TRANSPORT_TYPE_INFO,
    LOADING_ORDERS_TABLE_COLUMN_SHIPPING_ADDRESS,
    SELECT_LOADING_ORDER_TRANSPORT_TYPE,
    SHIPPING_ADDRESS_SELECTOR_PLACEHOLDER,
} from '../../../../../../i18n/translations/TR';
import { TypeAhead } from '../../../../../shared/components/typeAhead/typeAhead';
import { TypeAheadArrayData } from '../../../../../shared/components/typeAhead/typeAheadArrayData';
import { TypeAheadData } from '../../../../../shared/components/typeAhead/typeAheadData';
import {
    ELoadingOrderTransportType,
    LoadingOrderTransportType,
} from '../../../../domain/value-objects/loading-order-transport_type';

interface ShippingAddressSelectorFormProps {
    isBusy: boolean
    shippingAddresses: TypeAheadArrayData
    loadingOrderShippingAddress: TypeAheadData | undefined
    loadingOrderTransportType: LoadingOrderTransportType | undefined
    handleLoadingOrderShippingAddress: (shippingAddress: TypeAheadData | undefined) => void
    handleLoadingOrderTransportType: (transportType: LoadingOrderTransportType | undefined) => void
}
export const ShippingAddressSelectorForm = (
    {
        isBusy,
        shippingAddresses,
        loadingOrderTransportType,
        loadingOrderShippingAddress,
        handleLoadingOrderTransportType,
        handleLoadingOrderShippingAddress,
    }: ShippingAddressSelectorFormProps,
):React.ReactElement => {
    const { t } = useTranslation();
    const finderRef = createRef<Typeahead>();

    const handleSelected = (selected: TypeAheadData): void => {
        if (selected) {
            handleLoadingOrderShippingAddress(selected);
        } else {
            handleLoadingOrderShippingAddress(undefined);
        }
    };

    const renderItem = (option: TypeAheadData): React.ReactElement => {
        return (
            <span>
                {`(${option.item.code.value.trim()}) ${option.item.description}`}
            </span>
        );
    };

    // TODO (Álvaro) refactor and use useForm
    return (
        <form className="shipping-address-selector-form-container">
            <div className="shipping-address-selector-form-item">
                <label className="form-label" htmlFor="shippingAddressFinder">
                    {t(LOADING_ORDERS_TABLE_COLUMN_SHIPPING_ADDRESS)}
                </label>
                <TypeAhead
                    id="shippingAddressFinder"
                    placeholder={t(SHIPPING_ADDRESS_SELECTOR_PLACEHOLDER) as string}
                    options={shippingAddresses}
                    isLoading={isBusy}
                    reference={finderRef}
                    item={renderItem}
                    disabled={false}
                    handleSelected={handleSelected}
                    defaultSelected={loadingOrderShippingAddress ?
                        [loadingOrderShippingAddress.labelKey] :
                        undefined}
                />
            </div>
            <div className="shipping-address-selector-form-item">
                <label className="form-label" htmlFor="shippingAddressFinder">
                    {t(LOADING_ORDER_TRANSPORT_TYPE)}
                </label>
                <select
                    value={loadingOrderTransportType?.value}
                    className="form-select"
                    onChange={(event): void => {
                        if (event.target.value) {
                            const transportType = new LoadingOrderTransportType(
                                event.target.value as ELoadingOrderTransportType,
                            );
                            handleLoadingOrderTransportType(transportType);
                        } else {
                            handleLoadingOrderTransportType(undefined);
                        }
                    }}
                >
                    <option
                        value=""
                    >
                        {t(SELECT_LOADING_ORDER_TRANSPORT_TYPE)}
                    </option>
                    {
                        Object.keys(ELoadingOrderTransportType).map((type) => {
                            const transportType = new LoadingOrderTransportType(type as ELoadingOrderTransportType);
                            return (
                                <option
                                    key={transportType.value}
                                    value={transportType.value}
                                >
                                    {transportType.translate}
                                </option>
                            );
                        })
                    }
                </select>
                {loadingOrderTransportType?.value === ELoadingOrderTransportType.CONTAINER ? (
                    <span className="shipping-address-selector-form-item-info">
                        {`*${t(LOADING_ORDER_TRANSPORT_TYPE_INFO)}`}
                    </span>
                ) : null}
            </div>
        </form>
    );
};
