import { Product } from 'app/product/domain/entity/Product';

export class CollectionTile {

    public get products(): Product[] {
        return this._products;
    }
    public get type(): string {
        return this._type;
    }

    public constructor(
        private _products: Product[],
        private _type: string,
    ) {}

}
