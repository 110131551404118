export interface CollectionResponse {
    id: string;
    name: string;
    image: string;
    imageHeader: string;
    date: string | null;
}

export enum CollectionTypeResponse {
    ALL = 'all',
    HOME = 'home',
}
