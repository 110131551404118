import { Integer } from 'app/shared/domain/value-objects/Integer';
import { ProductId } from 'app/product/domain/valueObject/ProductId';
import { Discount } from './value-objects/Discount';
import { OrderLineIdx } from './value-objects/OrderLineIdx';
import { OrderLineStatus } from './value-objects/OrderLineStatus';
import { Price } from './value-objects/Price';
import ProductQuantity from '../../product/domain/valueObject/ProductQuantity';
import { ToneId } from './value-objects/ToneId';
import { Weight } from './value-objects/Weight';
import { WarehouseLocation } from '../../product/domain/entity/WarehouseLocation';

export default class OrderLine {

    public constructor(
        idx: OrderLineIdx,
        weight: Weight,
        price: Price,
        productId: ProductId,
        quantity: ProductQuantity,
        description: string | null,
        tone: ToneId | null,
        pallets: Integer,
        boxesTotal: Integer,
        boxes: Integer,
        weightNet: Weight,
        discountSpecial: Discount,
        discountSpecial2: Discount,
        esPico: boolean,
        net: Price,
        gross: Price,
        deliveryDate: Date | null,
        status: OrderLineStatus,
        warehouse: WarehouseLocation | null,
    ) {
        this._idx = idx;
        this._weight = weight;
        this._price = price;
        this._productId = productId;
        this._quantity = quantity;
        this._description = description;
        this._tone = tone;
        this._pallets = pallets;
        this._boxesTotal = boxesTotal;
        this._boxes = boxes;
        this._weightNet = weightNet;
        this._discountSpecial = discountSpecial;
        this._discountSpecial2 = discountSpecial2;
        this._esPico = esPico;
        this._gross = gross;
        this._net = net;
        this._deliveryDate = deliveryDate;
        this._status = status;
        this._warehouse = warehouse;
    }

    private _idx: OrderLineIdx;
    public get idx(): OrderLineIdx {
        return this._idx;
    }

    private _weight: Weight;
    public get weight(): Weight {
        return this._weight;
    }

    private _price: Price;
    public get price(): Price {
        return this._price;
    }

    private _productId: ProductId;
    public get productId(): ProductId {
        return this._productId;
    }

    private _description: string | null;
    public get description(): string | null {
        return this._description;
    }

    private _tone: ToneId | null;
    public get tone(): ToneId | null {
        return this._tone;
    }

    private _pallets: Integer;
    public get pallets(): Integer {
        return this._pallets;
    }

    private _boxesTotal: Integer;
    public get boxesTotal(): Integer {
        return this._boxesTotal;
    }
    private _boxes: Integer;
    public get boxes(): Integer {
        return this._boxes;
    }

    private _quantity: ProductQuantity;
    public get quantity(): ProductQuantity {
        return this._quantity;
    }

    private _weightNet: Weight;
    public get weightNet(): Weight {
        return this._weightNet;
    }

    private _discountSpecial: Discount;
    public get discountSpecial(): Discount {
        return this._discountSpecial;
    }

    private _discountSpecial2: Discount;
    public get discountSpecial2(): Discount {
        return this._discountSpecial2;
    }

    private _esPico: boolean;
    public get esPico(): boolean {
        return this._esPico;
    }

    private _net: Price;
    public get net(): Price {
        return this._net;
    }

    private _gross: Price;
    public get gross(): Price {
        return this._gross;
    }

    private _deliveryDate: Date | null;
    public get deliveryDate(): Date | null {
        return this._deliveryDate;
    }

    private _status: OrderLineStatus;
    public get status(): OrderLineStatus {
        return this._status;
    }

    private _warehouse: WarehouseLocation | null;
    public get warehouse(): WarehouseLocation | null {
        return this._warehouse;
    }

}
