import { Origin } from '../domain/value-objects/Origin';
import { useToken } from './useToken';

export const useOrigin = (): Origin | undefined => {
    const UseToken = useToken();

    if (UseToken.origin === Origin.EUROPE) {
        return Origin.EUROPE;
    }

    if (UseToken.origin === Origin.USA) {
        return Origin.USA;
    }

    return undefined;
};
