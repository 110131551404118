import { AxiosRequestConfig } from 'axios';
import { TencerAuthServiceInstance } from '../../tencer-routing/tencer-auth-route/TencerAuthService';

export const tokenConfig = (language = ''): AxiosRequestConfig => {
    const tokenUser = TencerAuthServiceInstance.getToken();

    let config = {};

    if (tokenUser) {
        const newToken = tokenUser.replace(/['"]+/g, '');
        config = {
            headers: { 'content-type': 'application/json',
                Authorization: `Bearer ${newToken}` },
        };
    } else if (language.length) {
        config = {
            headers: { 'content-type': 'application/json',
                'Accept-Language': language },
        };
    } else {
        config = {
            headers: { 'content-type': 'application/json' },
        };
    }

    return config;

};

export const config = {
    headers: { 'content-type': 'application/json' },
};
/* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
export const url = (base: string, api: string, token: any = '') : string => {
    return base + api + token;
};
