import React, { ReactElement } from 'react';
import { useMarketingScreenController } from 'app/collection/view/screen/marketing/useMarketingScreenController';
import { CollectionInfo } from '../../component/collectionInfo/CollectionInfo';

export const MarketingScreen = (): ReactElement => {
    const controller = useMarketingScreenController();

    return (
        <div className="component-marketing-screen">
            <CollectionInfo
                data={controller.data}
                error={controller.error}
                isLoading={controller.isLoading}
            />
        </div>
    );
};
