import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { ProductListItem } from './item/ProductListItem';
import { Product } from '../../../domain/entity/Product';

interface ProductsListProps {
    products: Product[]
}

export const ProductsList = (props: ProductsListProps): ReactElement => {
    return (
        <div className="component-products-list">
            <section className="container section-list 100vh">
                { props.products.map((product) => (
                    <Link
                        key={product.id.value}
                        style={{ textDecoration: 'none' }}
                        to={`/products/${product.id.value}`}
                    >
                        <ProductListItem
                            product={product}
                            key={product.id.value}
                        />
                    </Link>
                )) }
            </section>
        </div>
    );
};
