import React from 'react';
import { useTranslation } from 'react-i18next';
import { UseFormRegisterReturn } from 'react-hook-form';
import { CreditMemoSelectorOption } from 'app/cartSummary/application/components/body/summary/CreditMemoForm/CreditMemoSelectorOption';
import { CREDIT_MEMO_LABEL, SELECT_AN_OPTION } from 'i18n/translations/TR';

interface CreditMemoSelectorProps {
    currency: string;
    options: Promise<CreditMemoSelectorOption[]>;
    creditMemoFormRegister: UseFormRegisterReturn;
}

export const CreditMemoSelector: React.FC<CreditMemoSelectorProps> = (props: CreditMemoSelectorProps) => {
    const { t } = useTranslation();
    const [options, setOptions] =
        React.useState<CreditMemoSelectorOption[]>([]);

    React.useEffect(() => {
        props.options.then((creditMemos: CreditMemoSelectorOption[]) => {
            setOptions(creditMemos);
        });

    }, [props.options]);

    return options.length > 0 ? (
        <div className="form-field creditmemo-selector">
            <label htmlFor="creditmemo-selector" className="form-label">
                {t(CREDIT_MEMO_LABEL)}
            </label>
            <select className="form-select" {...props.creditMemoFormRegister}>
                <option value="">{t(SELECT_AN_OPTION)}</option>
                {options.map((value) => (
                    <option key={value.id} value={value.id}>{`${props.currency}${value.amount}`}</option>
                ))}
            </select>
        </div>
    ) : null;

};
