import React, { ReactElement, RefObject } from 'react';
import Typeahead from 'react-bootstrap-typeahead/types/core/Typeahead';
import { useTranslation } from 'react-i18next';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { DISPLAY_ADDITIONAL_RESULTS, LOADING, NO_MATCHES_FOUND, SEARCHING } from '../../../../i18n/translations/TR';
import './AsyncSelect.scss';
import { AsyncSelectData } from './AsyncSelectData';

interface AsyncSelectProps<T> {
    id: string,
    isLoading: boolean
    placeholder: string
    options: AsyncSelectData<T>[]
    reference: RefObject<Typeahead>
    handleSearch: (textSearch: string) => void
    handleSelected: (option: AsyncSelectData<T>[]) => void
    item?: (option: AsyncSelectData<T>) => ReactElement
}

export const AsyncSelect = <T, >(props: AsyncSelectProps<T>): ReactElement => {
    const { t } = useTranslation();
    const maxResultPerPage: number = 5;

    return (
        <AsyncTypeahead
            paginate
            clearButton
            delay={1000}
            id={props.id}
            positionFixed
            useCache={false}
            labelKey="label"
            ref={props.reference}
            options={props.options}
            promptText={t(SEARCHING)}
            searchText={t(SEARCHING)}
            isLoading={props.isLoading}
            maxResults={maxResultPerPage}
            // Disables the frontend filtering, allowing all options from the backend to be displayed as they are.
            filterBy={(): boolean => true}
            placeholder={props.placeholder}
            className="component-async-select"
            paginationText={t(DISPLAY_ADDITIONAL_RESULTS)}
            emptyLabel={props.isLoading ? t(LOADING) : t(NO_MATCHES_FOUND)}
            onSearch={props.handleSearch}
            // @ts-ignore
            onChange={props.handleSelected}
            // @ts-ignore
            renderMenuItemChildren={(option: AsyncSelectData<T>): ReactElement => (props.item ? (
                props.item(option)
            ) : (
                <div className="async-select-option-item">
                    <span>{option.label}</span>
                </div>
            ))}
        />
    );
};
