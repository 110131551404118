export enum ErrorCode {
    UNKNOWN = 0,
    CART_NO_STOCK_AVAILABLE = 30002,
    CART_NO_STOCK_AVAILABLE_ON_SINGLE_LOT = 30003,
    CART_BIG_RESERVE = 30004,
    CART_NO_STOCK_AVAILABLE_USA = 30007,
    CART_MUST_BE_IN_SAME_WAREHOUSE = 30008,
    CART_IN_PAYMENT_PROCESS_STATUS = 30009,
    CART_IN_PAYMENT_GATEWAY_STATUS = 30010,
    CART_PAYMENT_MISMATCH = 30011,
    ORDER_LINE_NOT_RESERVED = 40025,
    CP_OUT_OF_RANGE = 40016,
    CONTAINER_MAX_WEIGHT_EXCEED = 40022,
    ECHO_BAD_SHIPPING = 40026,
    CART_MUST_BE_IN_SAME_FAMILY = 40027,
    PAYMENT_IS_ZERO_EXCEPTION = 80006,
    CREDIT_LIMIT = 80007,
}
