import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { getRoundedValues } from 'app/shared/helper/getRoundedValues';
import { getSquareMeasurement } from 'app/shared/helper/getSquareMeasurement';
import { WarehouseLocation } from '../../../domain/entity/WarehouseLocation';
import { NumberFormatComponentPieceUnit } from '../../../../shared/components/NumberFormatPieceUnit';
import { ProductUnit } from '../../../domain/valueObject/ProductUnit';
import { Lot } from '../../../../cart/domain/value-objects/Lot';
import { PIECE_UNIT } from '../../../../../i18n/translations/TR';

interface StockAvailableInfoProps {
    lots: Lot[]
    amount: number
    unit: ProductUnit
    origin: string | null | undefined
    outOfStockWarehousesLocations?: WarehouseLocation[]
}

export const StockAvailableInfo = (props: StockAvailableInfoProps): ReactElement => {
    const { t } = useTranslation();

    const measureUnit = props.unit.isPiece() ? t(PIECE_UNIT) : getSquareMeasurement();

    const warehousesQuantities: Record<string, {
        quantity: number,
        warehouse: WarehouseLocation
    }> = {};

    props.lots?.forEach((lot) => {
        if (!lot.warehouse) return;

        const warehouseCode = lot.warehouse.code;

        if (warehousesQuantities[warehouseCode]) {
            warehousesQuantities[warehouseCode].quantity += lot.quantity;
        } else {
            warehousesQuantities[warehouseCode] = {
                quantity: lot.quantity,
                warehouse: lot.warehouse,
            };
        }
    });

    const EUElement = props.amount > 0 ? (
        <NumberFormatComponentPieceUnit
            value={getRoundedValues(props.amount)}
            suffix={` ${measureUnit}`}
        />
    ) : (
        <span>-</span>
    );

    const USAElement = (
        <>
            <NumberFormatComponentPieceUnit
                value={getRoundedValues(props.amount)}
                suffix={` ${measureUnit}`}
            />
            <ul>
                {Object.keys(warehousesQuantities).map((key) => {
                    const info = warehousesQuantities[key];
                    if (!info.quantity) {
                        return ('');
                    }
                    return (
                        <li key={key}>
                            <NumberFormatComponentPieceUnit
                                value={getRoundedValues(info.quantity)}
                                suffix={` ${measureUnit}`}
                            />
                            <small className="p-2">
                                {`(${info.warehouse.name})`}
                            </small>
                        </li>
                    );
                })}
                {props.outOfStockWarehousesLocations && props.outOfStockWarehousesLocations.length > 0 ?
                    props.outOfStockWarehousesLocations.map((warehouse) => {
                        return (
                            <li key={warehouse.code}>
                                <NumberFormatComponentPieceUnit
                                    value={0}
                                    suffix={` ${measureUnit}`}
                                />
                                <small className="p-2">
                                    { `(${warehouse.name})`}
                                </small>
                            </li>
                        );
                    }) : null}
            </ul>
        </>
    );
    if (props.origin === 'EUROPE') {
        return EUElement;
    }
    if (props.origin === 'USA') {
        return USAElement;
    }

    return (<span>-</span>);
};
