import React, { ReactElement } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCollectionListScreenController } from 'app/collection/view/screen/list/useCollectionListScreenController';
import { Spinner } from 'app/shared/components/Spinner';
import { ServerErrorMessage } from 'app/shared/components/ServerErrorMessage';
import { TencerRoutes } from '../../../../shared/tencer-routing/TencerRoutes';
import { COLLECTIONS, NO_RESULTS } from '../../../../../i18n/translations/TR';
import { CollectionHeader } from './header/CollectionHeader';
import { CollectionListItem } from './item/CollectionListItem';

export const CollectionListScreen = (): ReactElement => {
    const { t } = useTranslation();
    const controller = useCollectionListScreenController();

    return (
        <div
            className={`tab-pane fade
            ${window.location.pathname === TencerRoutes.COLLECTIONS ? 'show active' : ''}`}
            id="pills-collection"
            role="tabpanel"
            aria-labelledby="pills-collection-tab"
        >

            <CollectionHeader title={t(COLLECTIONS)} />

            {!controller.isLoading ?
                (
                    <div>
                        {!controller.error ?
                            (
                                <InfiniteScroll
                                    dataLength={controller.collections.length}
                                    next={controller.fetchMore}
                                    hasMore={controller.hasMore}
                                    loader={<Spinner classCss="basic-spinner-container" />}
                                >
                                    {controller.collections.length ?
                                        (
                                            <section className="container section-grid">
                                                {
                                                    controller.collections.map((collection) => (
                                                        <Link
                                                            key={collection.id.value}
                                                            className="text-decoration-none w-100"
                                                            to={`/collections/${collection.id.value}`}
                                                        >
                                                            <CollectionListItem
                                                                collection={collection}
                                                            />
                                                        </Link>
                                                    ))
                                                }
                                            </section>
                                        ) : <div>{t(NO_RESULTS)}</div>}
                                </InfiniteScroll>
                            )
                            : <ServerErrorMessage />}
                    </div>
                ) : <Spinner classCss="header-section-spinner" />}
        </div>
    );
};
