import { ProductId } from 'app/product/domain/valueObject/ProductId';
import { WarehouseLocation } from '../../../product/domain/entity/WarehouseLocation';

export class Lot {

    constructor(
        private readonly _productId: ProductId,
        private readonly _quantity: number,
        private readonly _box: string | null,
        private readonly _caliber: number | null,
        private readonly _quality: string | null,
        private readonly _palletType: string | null,
        private readonly _reference: string | null,
        private readonly _warehouse: WarehouseLocation | null,
    ) {}

    public get productId(): ProductId {
        return this._productId;
    }

    public get quantity(): number {
        return this._quantity;
    }

    public get box(): string | null {
        return this._box;
    }

    public get caliber(): number | null {
        return this._caliber;
    }

    public get palletType(): string | null {
        return this._palletType;
    }

    public get quality(): string | null {
        return this._quality;
    }

    public get reference(): string | null {
        return this._reference;
    }

    public get warehouse(): WarehouseLocation | null {
        return this._warehouse;
    }

}
