import jwtDecode from 'jwt-decode';
import { PermissionDTO } from '../infrastructure/tencer-api/dto/permission.DTO';
import { PermissionArrayMapper } from '../infrastructure/tencer-api/data-mapper/permission-array.mapper';
import { PermissionArray } from '../domain/permission-array';
import { TencerAuthServiceInstance } from '../tencer-routing/tencer-auth-route/TencerAuthService';
import { PaymentMethod } from '../../orders/model/value-objects/PaymentMethod';

export interface useTokenResponse {
    erpCode: string | undefined;
    exp: number | undefined;
    iat: number | undefined;
    rol: string | undefined;
    origin: string | undefined;
    username: string | undefined;
    permissions: PermissionArray;
    paymentMethod: PaymentMethod | undefined;
    pickHandle: boolean;
}

export interface DecodedToken {
    erp_code: string;
    exp: number;
    iat: number;
    roles: string[];
    username: string;
    permissions: PermissionDTO[];
    origin: string;
    payment_method: string;
    pick_handle: boolean
}

export enum RolName {
    ADMIN = 'ROLE_ADMIN',
}

export const useToken = ():useTokenResponse => {
    const token = TencerAuthServiceInstance.getToken();
    const decodedToken: DecodedToken | undefined = token ? jwtDecode(token) : undefined;

    if (decodedToken === null) {
        throw new Error('invalid token');
    }
    const rol = decodedToken?.roles ? decodedToken.roles[0] : undefined;
    const username = decodedToken ? decodedToken.username : undefined;
    const erpCode = decodedToken ? decodedToken.erp_code : undefined;
    const exp = decodedToken ? decodedToken.exp : undefined;
    const iat = decodedToken ? decodedToken.iat : undefined;
    const origin = decodedToken ? decodedToken.origin : undefined;
    const permissions = decodedToken
        ? PermissionArrayMapper.toDomain(decodedToken.permissions)
        : new PermissionArray();

    let paymentMethod;
    if (decodedToken?.payment_method) {
        switch (decodedToken.payment_method) {
            case 'memo': paymentMethod = PaymentMethod.CREDIT_CARD; break;
            case 'terms': paymentMethod = PaymentMethod.TERMS; break;
            default: paymentMethod = PaymentMethod.CREDIT_CARD; break;
        }
    }
    const pickHandle = decodedToken ? decodedToken.pick_handle : false;

    return {
        rol,
        erpCode,
        iat,
        exp,
        origin,
        username,
        permissions,
        paymentMethod,
        pickHandle,
    };
};
