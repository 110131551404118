export enum CollectionTypeValue {
    ALL = 'ALL',
    HOME = 'HOME',
}

export class CollectionType {

    public constructor(private readonly _value: CollectionTypeValue) {
        this._ensureIsValid();
    }

    public get value(): CollectionTypeValue {
        return this._value;
    }

    private _ensureIsValid(): void {
        if (!Object.values(CollectionTypeValue).includes(this._value)) {
            throw new Error(`Invalid collection type value: ${this._value}`);
        }
    }

}
