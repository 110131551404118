import React from 'react';
import { useTranslation } from 'react-i18next';
import { useOrigin } from 'app/shared/hooks/useOrigin';
import { ModalError } from 'app/shared/components/modals/ModalError';
import {
    CREDIT_LIMIT_ERROR,
    ECHO_BAD_SHIPPING_ADDRESS_ERROR,
    PROCESS_ORDER,
    PURCHASE_ORDER_ERROR,
    YOU_MUST_SELECT_A_SHIPPING_ADDRESS,
} from '../../../i18n/translations/TR';
import { CartSummaryFooter } from './components/footer/CartSummaryFooter';
import { useCartSummary } from './hooks/useCartSummary';
import { PageSpinner } from '../../shared/components/page-spinner/page-spinner';
import './cartSummaryScreen.scss';
import { PageBanner } from '../../page-banner/component/PageBanner';
import { CartSummaryBody } from './components/body/CartSummaryBody';
import { Origin } from '../../shared/domain/value-objects/Origin';
import { TermsBanner } from './components/termsBanner/TermsBanner';

export const CartSummaryScreen = (): React.ReactElement => {
    const origin = useOrigin();
    const { t } = useTranslation();
    const UseCartSummary = useCartSummary();

    return (
        <main className="screen screen-cart-summary">
            {
                UseCartSummary.exceedTermsLimit && origin === Origin.USA ?
                    <TermsBanner pageId={UseCartSummary.pageName} />
                    : <PageBanner pageId={UseCartSummary.pageName} />
            }

            <PageSpinner isBusy={UseCartSummary.cartSummaryScreenIsBusy} />

            {UseCartSummary.cart?.items ? (
                <div className="cart-container">
                    <div className=" cart-header">
                        <h4 className="section-title">{t(PROCESS_ORDER)}</h4>
                    </div>

                    <CartSummaryBody
                        UseCartSummary={UseCartSummary}
                    />

                    {UseCartSummary.cart.orderId ? (
                        <CartSummaryFooter
                            cart={UseCartSummary.cart}
                            UseCartPO={UseCartSummary.UseCartPO}
                            onSubmit={UseCartSummary.confirmCart}
                        />
                    ) : null}
                </div>
            ) : null}

            <ModalError
                show={UseCartSummary.showShippingAddressError}
                message={t(YOU_MUST_SELECT_A_SHIPPING_ADDRESS)}
                onHide={UseCartSummary.handleHideShippingAddressError}
            />

            <ModalError
                message={t(PURCHASE_ORDER_ERROR)}
                show={UseCartSummary.UseCartPO.errorPO}
                onHide={UseCartSummary.UseCartPO.handleHidePOError}
            />

            <ModalError
                message={t(ECHO_BAD_SHIPPING_ADDRESS_ERROR)}
                show={UseCartSummary.showEchoShippingAddressError}
                onHide={UseCartSummary.handleHideEchoShippingAddressError}
            />

            <ModalError
                message={t(CREDIT_LIMIT_ERROR, { limit: UseCartSummary.paymentTerms?.credit_limit.amount ?? 0,
                    available: UseCartSummary.paymentTerms?.credit_available.amount ?? 0 })}
                show={UseCartSummary.showCreditLimitError}
                onHide={UseCartSummary.handleHideCreditLimitError}
            />
        </main>
    );
};
