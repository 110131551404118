import React, { ReactElement } from 'react';

interface CollectionHeaderProps {
    title: string;
}

export const CollectionHeader = (props: CollectionHeaderProps): ReactElement => {
    return (
        <div className="component-collection-header header-catalog header-actions">
            <h3 className="section-title">
                {props.title}
            </h3>
        </div>
    );
};
