import { OrderStatusResponse } from '../responses/OrderResponse';
import { OrderStatusType, OrderStatus } from '../../../../model/value-objects/OrderStatus';

export class OrderStatusMapper {

    public static fromResponse(statusResponse: OrderStatusResponse): OrderStatus {
        switch (statusResponse) {
            case OrderStatusResponse.ORDERED:
                return new OrderStatus(OrderStatusType.ORDERED);
            case OrderStatusResponse.IN_PREPARATION:
                return new OrderStatus(OrderStatusType.IN_PREPARATION);
            case OrderStatusResponse.READY:
                return new OrderStatus(OrderStatusType.READY);
            case OrderStatusResponse.CHARGED:
                return new OrderStatus(OrderStatusType.CHARGED);
            case OrderStatusResponse.INVOICED:
                return new OrderStatus(OrderStatusType.INVOICED);
            case OrderStatusResponse.RELEASED:
                return new OrderStatus(OrderStatusType.RELEASED);
            case OrderStatusResponse.OPEN:
                return new OrderStatus(OrderStatusType.OPEN);
            default:
                throw new Error(`Unknown status type: ${statusResponse}`);
        }
    }

}
