import React from 'react';
import { useTranslation } from 'react-i18next';
import { ERROR_500 } from '../../../i18n/translations/TR';

export const ServerErrorMessage: React.FC = () => {

    const { t } = useTranslation();

    return (
        <div className="errorServerMessage">
            {t(ERROR_500)}
        </div>
    );
};
