import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useOrigin } from 'app/shared/hooks/useOrigin';
import { ProductId } from '../../../domain/valueObject/ProductId';
import { WarehouseLocation } from '../../../domain/entity/WarehouseLocation';
import { Origin } from '../../../../shared/domain/value-objects/Origin';
import { CurrentUserContext } from '../../../../shared/context/CurrentUserContext';
import { EAnalyticsEvent } from '../../../../shared/domain/value-objects/AnalyticsEvent';
import { DependencyInjectionContext } from '../../../../shared/context/DependecyInjectionContext';
import { ProductDetails } from '../../../domain/entity/ProductDetails';
import getProductUseCase from '../../../domain/useCase/product/GetProductUseCase';
import { Lot } from '../../../../cart/domain/value-objects/Lot';
import getWarehousesLocationUseCase from '../../../domain/useCase/warehouseLocation/GetWarehousesLocationUseCase';

interface UseProductDetailScreenControllerResponse {
    error: boolean
    loading: boolean
    data?: ProductDetails
    fetchGetProduct: (productId: ProductId) => void
    outOfStockWarehousesLocations: WarehouseLocation[]
}

export function useProductDetailScreenController(): UseProductDetailScreenControllerResponse {
    const origin = useOrigin();
    const { id } = useParams<{id: string}>();
    const dic = useContext(DependencyInjectionContext);
    const context = useContext(CurrentUserContext);
    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<ProductDetails>();
    const [outOfStockWarehousesLocations, setOutOfStockWarehousesLocations] = useState<WarehouseLocation[]>([]);

    const handleOutOfStockWarehousesLocations = (
        allWarehousesLocations: WarehouseLocation[],
        lots: Lot[],
    ): WarehouseLocation[] => {
        const productWareHousesCodes = new Set(lots.map((info) => info.warehouse?.code));
        return allWarehousesLocations.filter((warehouse) => !productWareHousesCodes.has(warehouse.code));
    };

    const fetchGetWarehouseLocations = (productDetails: ProductDetails): void => {
        getWarehousesLocationUseCase({
            warehouseLocationRepository: dic.warehouseLocationRepository,
        })
            .then((warehousesLocation) => {
                if (productDetails.lots?.length > 0) {
                    setOutOfStockWarehousesLocations(
                        handleOutOfStockWarehousesLocations(
                            warehousesLocation,
                            productDetails.lots,
                        ),
                    );
                }
            })
            .catch(() => {
                setError(true);
            })
            .finally(() => setLoading(false));
    };

    const fetchGetProduct = async (productId: ProductId): Promise<void> => {
        await getProductUseCase({
            productId,
            productRepository: dic.productRepository,
        })
            .then((product) => {
                setData(product);
                if (origin === Origin.USA) {
                    fetchGetWarehouseLocations(product);
                } else {
                    setLoading(false);
                }
            })
            .catch(() => {
                setLoading(false);
                setError(true);
            });
    };

    const fetchPostProductDetailEvent = (productId: ProductId): void => {
        dic.analyticsRepository.productDetailEvent(
            new Date(),
            EAnalyticsEvent.PRODUCT_DETAIL,
            productId,
        );
    };

    useEffect(() => {
        if (id) {
            fetchGetProduct(new ProductId(id));
        }
    }, [context?.cartModified, id]);

    useEffect(() => {
        if (id) {
            fetchPostProductDetailEvent(new ProductId(id));
        }
    }, [id]);

    return {
        data,
        error,
        loading,
        fetchGetProduct,
        outOfStockWarehousesLocations,
    };
}
