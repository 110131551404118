import Transport from '../../../../../orders/model/Transport';
import {
    CartCourierResponse,
    CartCourierServiceResponse,
    CartShippingResponse,
} from '../../../../../shared/infrastructure/tencer-api/dto/responses/CartShippingResponse';
import { Price } from '../../../../../orders/model/value-objects/Price';
import { TransportTypeMapper } from '../../../../../shared/infrastructure/tencer-api/data-mapper/transport-type.mapper';
import { CourierId } from '../../../../../shared/domain/value-objects/CourierId';
import { Courier } from '../../../../../cartSummary/domain/entity/Courier';
import { CourierService } from '../../../../../shared/domain/CourierService';
import { CourierServiceId } from '../../../../../shared/domain/value-objects/CourierServiceId';

export class CartTransportMapper {

    public static fromResponse(response?: CartShippingResponse): Transport | undefined {
        if (!response) {
            return undefined;
        }

        return new Transport(
            TransportTypeMapper.fromResponse(response.shipping_type.value),
            response.consolidated ? response.consolidated : false,
            response.description || '',
            response.account_number,
            response.courier ? this._cartCourierFromResponse(response.courier) : undefined,
            response.courier_service ? this._cartCourierServiceFromResponse(response.courier_service) : undefined,
            response.lift_gate || undefined,
            undefined,
            response.pick_handle_price ? new Price(response.pick_handle_price) : undefined,
        );

    }

    private static _cartCourierFromResponse(cartCourierResponse: CartCourierResponse): Courier {
        return new Courier(
            new CourierId(cartCourierResponse.code.value),
            cartCourierResponse.code.value,
            cartCourierResponse.price ? new Price(cartCourierResponse.price.amount) : undefined,
        );
    }

    private static _cartCourierServiceFromResponse(cartCourierServiceResponse: CartCourierServiceResponse): CourierService {
        return new CourierService(
            new CourierServiceId(cartCourierServiceResponse.code.value),
            cartCourierServiceResponse.name.value,
        );
    }

}
