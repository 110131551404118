import React, { ReactElement, useEffect, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import {
    ACCEPT,
    CART_IN_PAYMENT_PROCESS_STATUS,
    CART_IN_PAYMENT_PROCESS_STATUS_INFO,
    PROCESSING,
} from '../../../../i18n/translations/TR';
import { CActionButton } from '../CActionButton/CActionButton';

interface CartInPaymentProcessModalProps {
    showModal: boolean
    closeModal?: () => void
    onCheckingProgress?: () => void
}

export const CartInPaymentProcessModal = (props: CartInPaymentProcessModalProps): ReactElement => {
    const { t } = useTranslation();
    const pollingInterval = 5000;
    // eslint-disable-next-line no-undef
    const pollingRef = useRef<NodeJS.Timeout | null>(null);

    const startPolling = (): void => {
        if (!pollingRef.current) {
            pollingRef.current = setInterval(() => {
                if (props.onCheckingProgress) {
                    props.onCheckingProgress();
                }
            }, pollingInterval);
        }
    };

    const stopPolling = (): void => {
        if (pollingRef.current) {
            clearInterval(pollingRef.current);
            pollingRef.current = null;
        }
    };

    useEffect(() => {
        if (props.onCheckingProgress && props.showModal) {
            startPolling();
        } else {
            stopPolling();
        }

        return (): void => {
            stopPolling();
        };
    }, [props.showModal]);

    return (
        <Modal
            centered
            show={props.showModal}
            className="modal fade shopping-modal"
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header>
                <h5 className="modal-title">{t(CART_IN_PAYMENT_PROCESS_STATUS)}</h5>
            </Modal.Header>
            <Modal.Body>
                <div>{t(CART_IN_PAYMENT_PROCESS_STATUS_INFO)}</div>
            </Modal.Body>
            <Modal.Footer>
                {
                    props.closeModal ? (
                        <button
                            type="button"
                            form="add-form"
                            onClick={props.closeModal}
                            className="btn btn-primary"
                        >
                            {t(ACCEPT)}
                        </button>
                    ) : (
                        <CActionButton
                            isBusy
                            disabled
                            text={t(PROCESSING)}
                        />
                    )
                }
            </Modal.Footer>
        </Modal>
    );
};
