import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useOrigin } from 'app/shared/hooks/useOrigin';
import { getRoundedValues } from 'app/shared/helper/getRoundedValues';
import { ProductId } from 'app/product/domain/valueObject/ProductId';
import getProductUseCase from 'app/product/domain/useCase/product/GetProductUseCase';
import { DISCOUNT } from '../../../../../../i18n/translations/TR';
import 'react-loading-skeleton/dist/skeleton.css';
import { Currency } from '../../../../../shared/components/Currency';
import { Quantity } from '../../../../../shared/components/Quantity';
import OrderLine from '../../../../model/OrderLine';
import { OrderLineProductInfo } from './productInfo/OrderLineProductInfo';
import { DependencyInjectionContext } from '../../../../../shared/context/DependecyInjectionContext';
import { ProductDetails } from '../../../../../product/domain/entity/ProductDetails';

interface OrderLineItemProps {
    line:OrderLine,
}

export const OrderLineItem = (props: OrderLineItemProps): ReactElement => {
    const origin = useOrigin();
    const dic = useContext(DependencyInjectionContext);
    const { t } = useTranslation();
    const [productDetails, setProductDetails] = useState<ProductDetails>();

    useQuery({
        queryKey: ['packings', props.line.productId],
        queryFn: ({ queryKey }) => {
            return getProductUseCase({
                productRepository: dic.productRepository,
                productId: queryKey[1] as ProductId,
            });
        },
        onSuccess: (data: ProductDetails) => {
            setProductDetails(data);
        },
    });

    const { data: queryData } = useQuery({
        queryKey: ['packings', props.line.productId],
        queryFn: ({ queryKey }) => {
            return getProductUseCase({
                productRepository: dic.productRepository,
                productId: queryKey[1] as ProductId,
            });
        },
        onSuccess: (data: ProductDetails) => {
            setProductDetails(data);
        },
        initialData: productDetails,
    });

    useEffect(() => {
        if (queryData) {
            setProductDetails(queryData);
        }
    }, [queryData]);

    const productActionsRender = (
        <div className="detail-price">
            <div className="product-actions">
                <div className="product-price">
                    {(!props.line.discountSpecial || props.line.discountSpecial.value <= 0) ? null :
                        (
                            <div className="product-discount-cart order-line-discount-special">
                                {`${props.line.discountSpecial.value}% ${t(DISCOUNT)}`}
                            </div>
                        )}
                </div>
                <div className="product-price ">
                    {(!props.line.discountSpecial2 || props.line.discountSpecial2.value <= 0) ? null :
                        (
                            <div className="product-discount-cart order-line-discount-special2">
                                {`${props.line.discountSpecial2.value}% ${t(DISCOUNT)}`}
                            </div>
                        )}
                </div>
            </div>
            <div className="product-actions">
                <div className="total-price">
                    <strong>
                        <Currency
                            value={props.line.price?.amount ? props.line.price.amount : 0}
                            className="import"
                        />
                    </strong>
                    {' x '}
                    <Quantity
                        value={props.line.quantity.amount ? props.line.quantity.amount : 0}
                        label={props.line.quantity.unitLabel(origin)}
                    />
                </div>
                <div>
                    <strong>
                        <Currency
                            value={
                                getRoundedValues(props.line.net?.amount ? props.line.net.amount : 0)
                            }
                            className="import"
                        />
                    </strong>
                </div>
            </div>
        </div>
    );

    return (
        <li className="list-item">
            <OrderLineProductInfo
                line={props.line}
                product={productDetails?.product}
                getPackingCatalogIsLoading={!productDetails}
            />
            {productActionsRender}
        </li>
    );
};
