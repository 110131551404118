import React, { ReactElement } from 'react';
import { Collection } from '../../../../../collection/domain/entity/Collection';

interface FeaturedCollectionItemProps {
    collection: Collection;
}

export const FeaturedCollectionItem = (props: FeaturedCollectionItemProps): ReactElement => {
    return (
        <li className="grid-item">
            <div className="product-image">
                <img src={props.collection.image.value} alt="product" />
            </div>
            <div className="product-item-details">
                <div className="product-title">{props.collection.name.value}</div>
                <div className="product-description">{props.collection.name.value}</div>
            </div>
        </li>
    );
};
