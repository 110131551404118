import React, { ReactElement } from 'react';
import { DownloadsBanner } from 'app/shared/components/DownloadBanner';
import { Spinner } from 'app/shared/components/Spinner';
import { ServerErrorMessage } from 'app/shared/components/ServerErrorMessage';
import { CollectionHeaderSection } from './collectionHeader/CollectionHeaderSection';
import { CollectionTileSection } from './collectionTile/CollectionTileSection';
import { CollectionDetails } from '../../../domain/entity/CollectionDetails';

interface CollectionInfoProps {
    error: boolean
    isLoading: boolean
    data?: CollectionDetails
}

export const CollectionInfo = (props: CollectionInfoProps): ReactElement | null => {

    if (props.isLoading) {
        return <Spinner classCss="header-section-spinner" />;
    }

    if (props.error) {
        return <ServerErrorMessage />;
    }

    if (!props.data) {
        return null;
    }

    return (
        <div className="component-collection-info">
            <CollectionHeaderSection
                name={props.data.collection.name.value}
                category={props.data.collection.name.value}
            />
            {props.data.tiles.map((tile) => (
                <CollectionTileSection
                    key={tile.type}
                    title={tile.type}
                    products={tile.products}
                />
            ))}
            <DownloadsBanner />
        </div>
    );

};
