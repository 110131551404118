export interface PaginationRequest {
    results: number
    page: number
    order?: PaginationRequestOrder
}

export enum PaginationRequestOrder {
    ALPHABETICAL = 'alphabetical',
    DATE = 'date',
}
