import { CollectionRepository } from '../repository/CollectionRepository';
import { CollectionId } from '../valueObject/CollectionId';
import { CollectionDetails } from '../entity/CollectionDetails';

interface GetCollectionUseCaseProps {
    collectionId: CollectionId,
    collectionRepository: CollectionRepository,
}

export default async (props: GetCollectionUseCaseProps): Promise<CollectionDetails> => {
    try {
        return await props.collectionRepository.findById(props.collectionId);
    } catch (error) {
        return Promise.reject(error);
    }
};
