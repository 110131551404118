import { ETransportType, TransportType } from '../../../../orders/model/value-objects/transport-type';
import { TransportTypeResponseValue } from '../dto/responses/TransportTypeResponse';

export const TransportTypeMapper = {
    fromDomain: (transportType: TransportType): TransportTypeResponseValue => {
        switch (transportType.value) {
            case ETransportType.WILL_CALL:
                return TransportTypeResponseValue.WILL_CALL;
            case ETransportType.OWN_LOGISTIC:
                return TransportTypeResponseValue.OWN_LOGISTIC;
            case ETransportType.SMALL_PARCEL:
                return TransportTypeResponseValue.SMALL_PARCEL;
            case ETransportType.WOW:
                return TransportTypeResponseValue.WOW;
            default:
                throw new Error(`Invalid transport type ${transportType}`);
        }
    },

    fromResponse: (transportTypeResponseValue: TransportTypeResponseValue): TransportType => {
        switch (transportTypeResponseValue) {
            case TransportTypeResponseValue.WILL_CALL:
                return new TransportType(ETransportType.WILL_CALL);
            case TransportTypeResponseValue.OWN_LOGISTIC:
                return new TransportType(ETransportType.OWN_LOGISTIC);
            case TransportTypeResponseValue.SMALL_PARCEL:
                return new TransportType(ETransportType.SMALL_PARCEL);
            case TransportTypeResponseValue.WOW:
                return new TransportType(ETransportType.WOW);
            default:
                throw new Error(`Invalid transport type response ${transportTypeResponseValue}`);
        }
    },
};
