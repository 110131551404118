import { ProductPriceResponse } from '../../../../../../shared/infrastructure/tencer-api/dto/responses/ProductPriceResponse';
import { ProductPrice } from '../../../../../domain/valueObject/ProductPrice';

export class ProductPriceDataMapper {

    public static fromResponse(price: ProductPriceResponse): ProductPrice {
        return new ProductPrice(
            price.value,
            price.quality,
            price.discount,
            price.pallet_price,
        );
    }

}
