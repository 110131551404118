import OrderShipmentLine from 'app/orders/model/value-objects/OrderShipmentLine';
import {
    OrderCourierResponse,
    OrderCourierServiceResponse,
    OrderShipmentLineResponse,
} from '../responses/OrderShipmentLineResponse';
import { TransportTypeMapper } from '../../../../../shared/infrastructure/tencer-api/data-mapper/transport-type.mapper';
import { ShippingId } from '../../../../model/value-objects/ShippingId';
import { Price } from '../../../../model/value-objects/Price';
import { Courier } from '../../../../../cartSummary/domain/entity/Courier';
import { CourierId } from '../../../../../shared/domain/value-objects/CourierId';
import { CourierService } from '../../../../../shared/domain/CourierService';
import { CourierServiceId } from '../../../../../shared/domain/value-objects/CourierServiceId';

export class OrderShipmentLineMapper {

    public static fromResponse(response: OrderShipmentLineResponse): OrderShipmentLine {
        return new OrderShipmentLine(
            TransportTypeMapper.fromResponse(response.type.value),
            response.consolidated ? response.consolidated : false,
            response.description,
            new Price(response.lift_gate_price ?? 0),
            response.account_number,
            response.shipping_id ? new ShippingId(response.shipping_id.value) : null,
            response.courier ? this._orderCourierFromResponse(response.courier) : null,
            response.courier_service ? this._orderCourierServiceFromResponse(response.courier_service) : null,
        );
    }

    private static _orderCourierFromResponse(orderCourierResponse: OrderCourierResponse): Courier {
        return new Courier(
            new CourierId(orderCourierResponse.code.value),
            orderCourierResponse.code.value,
            orderCourierResponse.price ? new Price(orderCourierResponse.price.amount) : undefined,
        );
    }

    private static _orderCourierServiceFromResponse(orderCourierServiceResponse: OrderCourierServiceResponse): CourierService {
        return new CourierService(
            new CourierServiceId(orderCourierServiceResponse.code.value),
            orderCourierServiceResponse.name.value,
        );
    }

}
