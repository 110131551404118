import React, { ReactElement } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';
import { NO_MORE_RESULTS } from 'i18n/translations/TR';
import { Spinner } from 'app/shared/components/Spinner';
import { PageBanner } from 'app/page-banner/component/PageBanner';
import { ProductsListHeader } from '../../component/header/ProductsListHeader';
import { ProductFilterModal } from '../../component/filterModal/ProductFilterModal';
import { ProductTabs } from '../../component/tabs/ProductTabs';
import { ProductsList } from '../../component/list/ProductsList';
import { useProductListScreenController } from './useProductListScreenController';
import { ProductType } from '../../../domain/valueObject/ProductType';

interface ProductListScreenProps {
    title: string
    type: ProductType
    className: string
    hasFiltersEnabled: boolean
}

export const ProductListScreen = (props: ProductListScreenProps): ReactElement => {
    const { t } = useTranslation();
    const controller = useProductListScreenController({
        type: props.type,
    });

    return (
        <section className={`${props.className} header-section`}>
            <PageBanner pageId={props.title} />

            {
                props.hasFiltersEnabled ? (
                    <ProductTabs />
                ) : null
            }

            {!controller.isLoading ?
                (
                    <div className="container">
                        <ProductsListHeader
                            title={props.title}
                            filters={controller.filters}
                            onRemove={controller.removeFilter}
                            hasFiltersEnabled={props.hasFiltersEnabled}
                            handleShow={controller.handleShowFilters}
                        />

                        <InfiniteScroll
                            next={controller.fetchMore}
                            hasMore={controller.hasMore}
                            dataLength={controller.products.length}
                            loader={<Spinner classCss="basic-spinner-container" />}
                            endMessage={<p className="end-message">{t(NO_MORE_RESULTS)}</p>}
                        >
                            <ProductsList products={controller.products} />
                        </InfiniteScroll>
                    </div>
                ) : (
                    <Spinner classCss="header-section-spinner" />
                )}
            {controller.showFilters && props.hasFiltersEnabled ? (
                <ProductFilterModal
                    filters={controller.filters}
                    show={controller.showFilters}
                    onReset={controller.resetFilters}
                    onApply={controller.applyFilters}
                    onHide={controller.handleCloseFilters}
                />
            ) : null}
        </section>
    );
};
