import React from 'react';
import { useTranslation } from 'react-i18next';
import { ModalError } from 'app/shared/components/modals/ModalError';
import {
    EXCEED_CONTAINER_WEIGHT_ERROR,
    LOADING_ORDER_ACTUAL_WEIGHT,
    LOADING_ORDER_MAX_WEIGHT,
} from '../../../../../i18n/translations/TR';
import './container-weight-error-modal.scss';
import { COWeight } from '../../../../shared/components/CO-weight';

interface IContainerWeightErrorModalProps{
    maxWeight: number
    onHide: () => void
    showModal: boolean
    currentWeight: number
}
export const ContainerWeightErrorModal = (props: IContainerWeightErrorModalProps): React.ReactElement => {
    const { t } = useTranslation();
    return (
        <ModalError
            onHide={props.onHide}
            message={(
                <div className="container-weight-error-container">
                    <span className="container-weight-error-message">
                        {t(EXCEED_CONTAINER_WEIGHT_ERROR)}
                    </span>
                    <div className="container-weight-error-quantity-message">
                        <span className="container-weight-error-quantity-title">
                            {t(LOADING_ORDER_ACTUAL_WEIGHT)}
                        </span>
                        <span className="container-weight-error-quantity-value">
                            <COWeight value={props.currentWeight} />
                        </span>
                    </div>
                    <div className="container-weight-error-quantity-message">
                        <span className="container-weight-error-quantity-title">
                            {t(LOADING_ORDER_MAX_WEIGHT)}
                        </span>
                        <span className="container-weight-error-quantity-value">
                            <COWeight value={props.maxWeight} />
                        </span>
                    </div>
                </div>
            )}
            show={props.showModal}
        />
    );
};
