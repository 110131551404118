import { Name } from 'app/shared/domain/value-objects/Name';
import { PermissionDTO } from '../dto/permission.DTO';
import { Permission } from '../../../domain/permission';
import { Id } from '../../../domain/value-objects/id';

export class PermissionMapper {

    public static toDomain(permissionDTO: PermissionDTO): Permission {
        return new Permission(new Id(permissionDTO.id), new Name(permissionDTO.name));
    }

}
