import { PaginationOrder } from './PaginationOrder';

export class Pagination {

    private readonly _page: number;
    private readonly _results: number;
    private readonly _order: PaginationOrder | null;

    public constructor(
        page: number,
        results: number,
        order: PaginationOrder | null,
    ) {
        this._page = page;
        this._order = order;
        this._results = results;
    }

    public get page(): number {
        return this._page;
    }

    public get results(): number {
        return this._results;
    }

    public get order(): PaginationOrder | null {
        return this._order;
    }

}
