import { CollectionDetailsResponse } from '../../../../../shared/infrastructure/tencer-api/dto/responses/CollectionDetailsResponse';
import { CollectionDetails } from '../../../../domain/entity/CollectionDetails';
import { CollectionTileDataMapper } from './CollectionTileDataMapper';
import { CollectionDataMapper } from './CollectionDataMapper';

export class CollectionDetailsDataMapper {

    public static fromResponse(response: CollectionDetailsResponse): CollectionDetails {
        return new CollectionDetails(
            CollectionDataMapper.fromResponse(response.collection),
            response.tiles.map(CollectionTileDataMapper.fromResponse),
        );
    }

}
