import React, { useState } from 'react';

export interface usePaginationInterface {
    hasMore: boolean
    actualPage:number,
    resultsPerPage: number
    refresh: (resultsLength:number) => void
    resetPaginator: (resultsLength: number) => void
    setResultsPerPage: React.Dispatch<React.SetStateAction<number>>
    setActualPage: React.Dispatch<React.SetStateAction<number>>
}

export const usePagination = (initialResults: number = 15): usePaginationInterface => {
    const [hasMore, sethasMore] = useState(true);
    const [actualPage, setActualPage] = useState<number>(1);
    const [resultsPerPage, setResultsPerPage] = useState<number>(initialResults || 15);

    const nextPage = ():void => {
        const nextPageNumber = actualPage + 1;
        setActualPage(nextPageNumber);
    };

    const refresh = (resultsLength: number): void => {
        const willHaveMore = resultsLength >= resultsPerPage;
        sethasMore(willHaveMore);

        if (willHaveMore) {
            nextPage();
        }
    };

    const resetPaginator = (resultsLength: number): void => {
        const willHaveMore = resultsLength === resultsPerPage;
        sethasMore(willHaveMore);
        if (willHaveMore) {
            setActualPage(2);
        } else {
            setActualPage(1);
        }
    };

    return {
        refresh,
        hasMore,
        actualPage,
        setActualPage,
        resultsPerPage,
        resetPaginator,
        setResultsPerPage,
    };
};
