import React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';
import { useOrigin } from 'app/shared/hooks/useOrigin';
import {
    SHIPPING_ADDRESS_LABEL_POSTALCODE,
    SHIPPING_ADDRESS_LABEL_ZIPCODE,
} from '../../../../../../i18n/translations/TR';
import { Origin } from '../../../../../shared/domain/value-objects/Origin';

interface ShippingAddressPostalCodeFieldProps {
    postalCodeRegister: UseFormRegisterReturn
    errorField: FieldError | undefined

}

export const ShippingAddressPostalCodeField = (
    {
        postalCodeRegister,
        errorField,
    }: ShippingAddressPostalCodeFieldProps,
): React.ReactElement => {
    const origin = useOrigin();
    const { t } = useTranslation();
    return (
        <div className="form-field postalcode-field">
            <label htmlFor="postalCode" className="form-label is-required">
                {origin === Origin.USA ? t(SHIPPING_ADDRESS_LABEL_ZIPCODE) : t(SHIPPING_ADDRESS_LABEL_POSTALCODE)}
            </label>
            <input
                type="text"
                id="postalCode"
                className="form-control"
                {...postalCodeRegister}
            />
            {errorField ? (
                <div className="error-input-form">{errorField.message}</div>
            ) : null}
        </div>
    );
};
