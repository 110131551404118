import { CollectionType, CollectionTypeValue } from '../../../../domain/valueObject/CollectionType';
import { CollectionTypeResponse } from '../../../../../shared/infrastructure/tencer-api/dto/responses/CollectionResponse';

export class CollectionTypeDataMapper {

    public static fromDomain(collectionType: CollectionType): CollectionTypeResponse {
        const mapping: Record<CollectionTypeValue, CollectionTypeResponse> = {
            [CollectionTypeValue.ALL]: CollectionTypeResponse.ALL,
            [CollectionTypeValue.HOME]: CollectionTypeResponse.HOME,
        };

        const value = mapping[collectionType.value];

        if (!value) {
            throw new Error(`Invalid CollectionType value: ${collectionType.value}`);
        }

        return value;
    }

}
