import { useTranslation } from 'react-i18next';
import { CM_UNIT } from '../../../i18n/translations/TR';
import { useOrigin } from '../hooks/useOrigin';

export const getMeasurement = (): string => {

    const { t } = useTranslation();
    const origin = useOrigin();

    let unitString: string = '';

    if (origin === 'EUROPE') {
        unitString = t(CM_UNIT);
    } else if (origin === 'USA') {
        unitString = '';
    }

    return unitString;
};
