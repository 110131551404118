import { useContext, useEffect, useState } from 'react';
import { PaginationOrder } from '../../../../shared/domain/value-objects/PaginationOrder';
import { Collection } from '../../../domain/entity/Collection';
import { Pagination } from '../../../../shared/domain/value-objects/Pagination';
import getCollectionsUseCase from '../../../domain/useCase/GetCollectionsUseCase';
import { usePagination } from '../../../../shared/hooks/usePagination';
import { DependencyInjectionContext } from '../../../../shared/context/DependecyInjectionContext';
import { SearchContext } from '../../../../shared/context/SearchContext';
import { CollectionType, CollectionTypeValue } from '../../../domain/valueObject/CollectionType';

interface UseCollectionListScreenControllerResponse {
    error: boolean;
    hasMore: boolean;
    isLoading: boolean;
    collections: Collection[];
    fetchMore: () => Promise<void>,
}

export function useCollectionListScreenController(): UseCollectionListScreenControllerResponse {
    const initialPage = 1;
    const defaultResults = 30;

    const searchContext = useContext(SearchContext);
    const dic = useContext(DependencyInjectionContext);
    const paginator = usePagination(defaultResults);

    const [error, setError] = useState<boolean>(false);
    const [collections, setCollections] = useState<Collection[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isFirstCall, setIsFirstCall] = useState<boolean>(true);

    const getCollections = async (
        page: number,
        search: string,
    ): Promise<void> => {
        if (page === initialPage) {
            setIsLoading(true);
        }

        const collectionPagination = new Pagination(
            paginator.actualPage,
            paginator.resultsPerPage,
            PaginationOrder.ALPHABETICAL,
        );

        await getCollectionsUseCase({
            type: new CollectionType(CollectionTypeValue.ALL),
            textSearch: search,
            pagination: collectionPagination,
            collectionRepository: dic.collectionRepository,
        })
            .then((data) => {
                if (page === initialPage) {
                    setCollections(data);
                    paginator.resetPaginator(data.length);
                } else {
                    setCollections((prevCollections) => (
                        [...prevCollections, ...data]
                    ));
                    paginator.refresh(data.length);
                }
            })
            .catch(() => {
                setError(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const fetchMore = async (): Promise<void> => {
        if (paginator.hasMore) {
            await getCollections(paginator.actualPage, searchContext.search);
        }

        return Promise.resolve();
    };

    const fetchInitialData = async (): Promise<void> => {
        const noSearch = '';

        await getCollections(initialPage, noSearch)
            .finally((): void => {
                setIsFirstCall(false);
            });
    };

    const handleDataFetch = async (): Promise<void> => {
        // This is necessary because when the user changes the location for the first time and the search context has a value,
        // two requests are made. To manage this, we need to ensure that the initial call is made without data.
        if (isFirstCall) {
            return;
        }

        await getCollections(initialPage, searchContext.search);
    };

    useEffect(() => {
        handleDataFetch();
    }, [searchContext.search]);

    useEffect(() => {
        fetchInitialData();
    }, []);

    return {
        error,
        isLoading,
        fetchMore,
        collections,
        hasMore: paginator.hasMore,
    };
}
