import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { SHOW_ORDER_TRACKING } from '../../../../../i18n/translations/TR';
import { CActionButton } from '../../../../shared/components/CActionButton/CActionButton';
import { ShippingId } from '../../../model/value-objects/ShippingId';

interface Props {
    shippingId: ShippingId
}

export const TrackingButton = (props: Props): ReactElement => {
    const { t } = useTranslation();

    const openTracking = (): void => {
        window.open(`https://www.echo.com/shippers/track-a-shipment/?ref=${props.shippingId.value}`);
    };

    return (
        <CActionButton
            text={t(SHOW_ORDER_TRACKING)}
            onClick={openTracking}
        />
    );
};
