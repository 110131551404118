import { useContext, useEffect, useState } from 'react';
import getProductsUseCase from 'app/product/domain/useCase/product/GetProductsUseCase';
import { ProductFilterCollection } from '../../../../orders/model/ProductFilterCollection';
import { DependencyInjectionContext } from '../../../../shared/context/DependecyInjectionContext';
import { usePagination } from '../../../../shared/hooks/usePagination';
import { Pagination } from '../../../../shared/domain/value-objects/Pagination';
import { Product } from '../../../domain/entity/Product';
import { ProductType } from '../../../domain/valueObject/ProductType';
import { SearchContext } from '../../../../shared/context/SearchContext';

export interface UseProductListScreenControllerResponse {
    hasMore: boolean,
    isLoading: boolean,
    products: Product[],
    showFilters: boolean,
    resetFilters: () => void;
    handleShowFilters: () => void,
    handleCloseFilters: () => void,
    fetchMore: () => Promise<void>,
    filters: ProductFilterCollection,
    removeFilter(key: string): void;
    applyFilters: (filters: ProductFilterCollection) => Promise<void>;
}

interface UseProductListScreenControllerProps {
    type: ProductType
}

export const useProductListScreenController = (props: UseProductListScreenControllerProps): UseProductListScreenControllerResponse => {
    const initialPage = 1;
    const defaultResults = 20;

    const searchContext = useContext(SearchContext);
    const dic = useContext(DependencyInjectionContext);
    const paginator = usePagination(defaultResults);

    const [showFilters, setShowFilters] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [products, setProducts] = useState<Product[]>([]);
    const [isFirstCall, setIsFirstCall] = useState<boolean>(true);
    const [filters, setFilters] = useState<ProductFilterCollection>(new ProductFilterCollection([]));

    const handleShowFilters = (): void => {
        setShowFilters(true);
    };

    const handleCloseFilters = (): void => {
        setShowFilters(false);
    };

    const fetchProducts = async (
        filtersCollection: ProductFilterCollection,
        search: string,
        page: number,
    ): Promise<void> => {
        if (page === initialPage) {
            setIsLoading(true);
        }

        const pagination = new Pagination(
            page,
            paginator.resultsPerPage,
            null,
        );

        await getProductsUseCase({
            pagination,
            productsId: [],
            type: props.type,
            textSearch: search,
            filters: filtersCollection,
            productRepository: dic.productRepository,
        })
            .then((data) => {
                if (page === initialPage) {
                    setProducts(data);
                    paginator.resetPaginator(data.length);
                } else {
                    setProducts((prevProducts) => (
                        [...prevProducts, ...data]
                    ));
                    paginator.refresh(data.length);
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const refreshFilters = async (filtersToApply?: ProductFilterCollection, search?: string): Promise<void> => {
        handleCloseFilters();

        const resultFilters = filtersToApply || await dic.productFilterRepository.findAll();

        setFilters(resultFilters);

        await fetchProducts(resultFilters, search || '', initialPage);
    };

    const resetFilters = async (): Promise<void> => {
        await dic.productFilterRepository.reset();
        await refreshFilters();
    };

    const applyFilters = async (filtersCollection: ProductFilterCollection):Promise<void> => {
        await dic.productFilterRepository.reset();
        handleCloseFilters();

        for (const filter of filtersCollection.toArray()) {
            await dic.productFilterRepository.add(filter);
        }

        setFilters(filtersCollection);
        if (searchContext.search === '') {
            fetchProducts(filtersCollection, searchContext.search, initialPage);
        } else {
            searchContext.cleanSearch();
        }
        return Promise.resolve();
    };

    const removeFilter = async (filterName: string):Promise<void> => {
        await dic.productFilterRepository.remove(filterName);
        await refreshFilters();
    };

    const fetchMore = async (): Promise<void> => {
        if (paginator.hasMore) {
            await fetchProducts(filters, searchContext.search, paginator.actualPage);
        }

        return Promise.resolve();
    };

    const fetchInitialData = async (): Promise<void> => {
        const noFilters = new ProductFilterCollection([]);
        const noSearch = '';

        await fetchProducts(noFilters, noSearch, initialPage)
            .finally((): void => {
                setIsFirstCall(false);
            });
    };

    const handleDataFetch = async (): Promise<void> => {
        const hasSearch = searchContext.search !== '';

        // This is necessary because when the user changes the location for the first time and the search context has a value,
        // two requests are made. To manage this, we need to ensure that the initial call is made without data.
        if (isFirstCall) {
            return;
        }

        if (hasSearch) {
            const newFilters = new ProductFilterCollection([]);
            await dic.productFilterRepository.reset();
            setFilters(newFilters);
            await fetchProducts(newFilters, searchContext.search, initialPage);
        } else {
            await fetchProducts(filters, searchContext.search, initialPage);
        }
    };

    useEffect(() => {
        handleDataFetch();
    }, [searchContext.search]);

    useEffect(() => {
        fetchInitialData();
    }, []);

    return {
        filters,
        products,
        isLoading,
        fetchMore,
        showFilters,
        removeFilter,
        applyFilters,
        resetFilters,
        handleShowFilters,
        handleCloseFilters,
        hasMore: paginator.hasMore,
    };
};
