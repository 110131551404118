import React, { ReactElement } from 'react';
import { SwiperSlide } from 'swiper/react';
import { useNavigate } from 'react-router-dom';
import 'swiper/components/pagination/pagination.scss';
import { useTranslation } from 'react-i18next';
import { SwiperCard } from 'app/shared/components/SwiperCard';
import { BESTSELLERS } from '../../../../../i18n/translations/TR';
import { TencerRoutes } from '../../../../shared/tencer-routing/TencerRoutes';
import { Carousel } from '../Carousel';
import { Product } from '../../../../product/domain/entity/Product';

interface BestSellersCarouselProps {
    isGettingBestSellersProducts: boolean
    errorGettingBestSellersProducts: boolean
    bestSellersProducts: Product[]
}

export const BestSellersCarousel = (props: BestSellersCarouselProps): ReactElement => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const goToCatalog = ():void => {
        navigate(TencerRoutes.PRODUCT_BESTSELLERS);
    };

    return (
        <Carousel
            showAdvice={false}
            onViewAll={goToCatalog}
            title={t(BESTSELLERS)}
            error={props.errorGettingBestSellersProducts}
            isLoading={props.isGettingBestSellersProducts}
            hasItems={props.bestSellersProducts.length > 0}
        >
            <>
                {(props.bestSellersProducts.map((product) => (
                    <SwiperSlide
                        key={product.id.value}
                        className="swiper-slide"
                    >
                        <div
                            key={product.id.value}
                            className="text-decoration-none w-100"
                        >
                            <SwiperCard
                                product={product}
                                title={t(BESTSELLERS)}
                            />
                        </div>
                    </SwiperSlide>
                )))}
                <div className="swiper-pagination" />
            </>
        </Carousel>
    );
};
