import { useContext, useEffect, useState } from 'react';
import { CollectionDetails } from '../../../domain/entity/CollectionDetails';
import { DependencyInjectionContext } from '../../../../shared/context/DependecyInjectionContext';
import getMarketingUseCase from '../../../domain/useCase/GetMarketingUseCase';
import { SearchContext } from '../../../../shared/context/SearchContext';

export interface useMarketingScreenControllerResponse {
    error: boolean;
    isLoading: boolean;
    data?: CollectionDetails;
}

export const useMarketingScreenController = (): useMarketingScreenControllerResponse => {
    const dic = useContext(DependencyInjectionContext);
    const searchContext = useContext(SearchContext);

    const [error, setError] = useState<boolean>(false);
    const [data, setData] = useState<CollectionDetails>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isFirstCall, setIsFirstCall] = useState<boolean>(true);

    const getMarketingDetails = async (search: string): Promise<void> => {
        setIsLoading(true);
        await getMarketingUseCase({
            textSearch: search,
            collectionRepository: dic.collectionRepository,
        })
            .then(setData)
            .catch(() => {
                setError(true);
            })
            .finally(() => setIsLoading(false));
    };

    const fetchInitialData = async (): Promise<void> => {
        const noSearch = '';

        await getMarketingDetails(noSearch)
            .finally((): void => {
                setIsFirstCall(false);
            });
    };

    const handleDataFetch = async (): Promise<void> => {
        // This is necessary because when the user changes the location for the first time and the search context has a value,
        // two requests are made. To manage this, we need to ensure that the initial call is made without data.
        if (isFirstCall) {
            return;
        }

        await getMarketingDetails(searchContext.search);
    };

    useEffect(() => {
        handleDataFetch();
    }, [searchContext.search]);

    useEffect(() => {
        fetchInitialData();
    }, []);

    return {
        data,
        error,
        isLoading,
    };
};
