import { Weight } from 'app/orders/model/value-objects/Weight';
import { CollectionId } from '../../../../../../collection/domain/valueObject/CollectionId';
import {
    ProductResponse,
    ProductUnitResponse,
} from '../../../../../../shared/infrastructure/tencer-api/dto/responses/ProductResponse';
import { Product } from '../../../../../domain/entity/Product';
import { CollectionName } from '../../../../../../collection/domain/valueObject/CollectionName';
import { Image } from '../../../../../domain/valueObject/Image';
import { ProductFormat } from '../../../../../domain/valueObject/ProductFormat';
import { ProductId } from '../../../../../domain/valueObject/ProductId';
import { ProductPriceDataMapper } from './ProductPriceDataMapper';
import { ProductUnitValue, ProductUnit } from '../../../../../domain/valueObject/ProductUnit';

export class ProductDataMapper {

    public static fromResponse(response: ProductResponse): Product {
        return new Product(
            new ProductId(response.product),
            !!response.outlet,
            !!response.default,
            !!response.enabled,
            response.heights ? response.heights : 0,
            response.boxPiece ? response.boxPiece : 0,
            response.boxMeter ? response.boxMeter : 0,
            response.boxMeterL ? response.boxMeterL : 0,
            new Weight(response.boxWeight ? response.boxWeight : 0),
            response.palletBox ? response.palletBox : 0,
            response.palletPlane ? response.palletPlane : 0,
            response.palletPiece ? response.palletPiece : 0,
            response.palletMeter ? response.palletMeter : 0,
            response.box ? response.box : null,
            response.type ? response.type : null,
            response.tile ? response.tile : null,
            response.code ? response.code : null,
            response.paste ? response.paste : null,
            response.family ? response.family : null,
            // eslint-disable-next-line no-underscore-dangle
            response.unit ? ProductDataMapper._fromProductUnitResponse(response.unit) : null,
            response.description ? response.description : null,
            response.image ? new Image(response.image) : null,
            response.price ? ProductPriceDataMapper.fromResponse(response.price) : null,
            response.stockEntryDate ? new Date(response.stockEntryDate) : null,
            response.format ? new ProductFormat(response.format) : null,
            response.collectionId ? new CollectionId(response.collectionId) : null,
            response.collection ? new CollectionName(response.collection) : null,
        );
    }

    private static _fromProductUnitResponse(response: ProductUnitResponse): ProductUnit {
        const mapping: Record<ProductUnitResponse, ProductUnitValue> = {
            [ProductUnitResponse.M2]: ProductUnitValue.M2,
            [ProductUnitResponse.PIECE]: ProductUnitValue.PIECE,
        };

        const value = mapping[response];

        if (!value) {
            throw new Error(`Invalid ProductUnitResponse value: ${response}`);
        }

        return new ProductUnit(value);
    }

}
