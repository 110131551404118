import { OrderId } from 'app/shared/domain/value-objects/OrderId';
import { useIsMounted } from 'app/shared/hooks/useIsMounted';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TencerRoutes } from 'app/shared/tencer-routing/TencerRoutes';
import { useOrigin } from 'app/shared/hooks/useOrigin';
import Order from '../../model/Order';
import OrderLine from '../../model/OrderLine';
import { useOrderRepository } from './useOrderRepository';
import { useShippingAddressRepository } from './useShippingAddressRepository';
import { ShippingAddress } from '../../model/ShippingAddress';
import { Origin } from '../../../shared/domain/value-objects/Origin';
import { ShippingAddressId } from '../../model/ShippingAddressId';

export interface useOrderDetailsInterface {
    submit: () => void
    showFileAdder: boolean
    order: Order | undefined
    isGettingOrderInfo: boolean
    getLines: () => OrderLine[]
    refreshAttachments: () => void
    orderShippingAddress: ShippingAddress | undefined
}

export const useOrderDetails = (orderId: OrderId): useOrderDetailsInterface => {
    const isMounted = useIsMounted();
    const origin = useOrigin();
    const history = useNavigate();
    const orderRepository = useOrderRepository();
    const shippingAddressRepository = useShippingAddressRepository();

    const [showFileAdder] = useState<boolean>(false);
    const [order, setOrder] = useState<Order>();
    const [orderShippingAddress, setOrderShippingAddress] = useState<ShippingAddress>();

    const getOrderShippingAddress = (shippingAddressId: ShippingAddressId):void => {
        shippingAddressRepository.getShippingAddressMutation.mutate({ shippingAddressId }, {
            onSuccess: (result) => {
                setOrderShippingAddress(result);
            },
        });
    };

    const refreshAttachments = ():void => {
        orderRepository.fetchOrderMutation.mutate({ orderId }, {
            onSuccess: (result: Order) => {
                setOrder(result);
                if (origin === Origin.EUROPE && result.shipping) {
                    getOrderShippingAddress(result.shipping);
                }
            },
        });
    };

    useEffect(() => {
        refreshAttachments();
    }, []);

    const getLines = (): OrderLine[] => {
        return order?.lines ?? [];
    };

    const submit = ():void => {
        history(TencerRoutes.ORDERS);
    };

    const isGettingOrderInfo = !isMounted ||
        orderRepository.fetchOrderMutation.isLoading ||
        shippingAddressRepository.getShippingAddressMutation.isLoading;

    return {
        order,
        submit,
        getLines,
        showFileAdder,
        refreshAttachments,
        isGettingOrderInfo,
        orderShippingAddress,
    };
};
