import React, { ReactElement } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { HomeScreen } from 'app/home/presentation/HomeScreen';
import { OrderDetailScreen } from 'app/orders/application/detail/OrderDetailScreen';
import { MarketingScreen } from 'app/collection/view/screen/marketing/MarketingScreen';
import { ProductCatalogScreen } from 'app/product/view/screen/list/catalog/ProductCatalogScreen';
import { ProductBestsellersScreen } from 'app/product/view/screen/list/bestSellers/ProductBestsellersScreen';
import { ProductOutletScreen } from 'app/product/view/screen/list/outlet/ProductOutletScreen';
import { CheckoutKo } from './cartSummary/application/components/checkout/checkoutKo/CheckoutKo';
import { TencerRoutes } from './shared/tencer-routing/TencerRoutes';
import { TencerAuthServiceInstance } from './shared/tencer-routing/tencer-auth-route/TencerAuthService';
import { ScrollTop } from './shared/components/ScrollTop';
import { Header } from './shared/components/Header';
import { Footer } from './shared/components/Footer';
import { SignInScreen } from './login/sign-in/SignInScreen';
import { AccountScreen } from './account/application/screen/account-screen';
import { SignUpScreen } from './login/sign-up/SignUpScreen';
import { CollectionDetailScreen } from './collection/view/screen/detail/CollectionDetailScreen';
import { ProductDetailScreen } from './product/view/screen/detail/ProductDetailScreen';
import { PasswordRecoveryScreen } from './password-recovery/PasswordRecoveryScreen';
import { CartScreen } from './cart/CartScreen';
import { OrdersScreen } from './orders/application/list/OrdersScreen';
import { LoadingOrderListScreen } from './loading-order/application/list/screen/loading-order-list-screen';
import { CartSummaryScreen } from './cartSummary/application/CartSummaryScreen';
import { LoadingOrderDetailScreen } from './loading-order/application/detail/screen/loading-order-detail-screen';
import { useOrigin } from './shared/hooks/useOrigin';
import { Origin } from './shared/domain/value-objects/Origin';
import { EnvEnum } from './shared/domain/value-objects/EnvEnum';
import { CartPaymentCardScreen } from './cartSummary/application/screens/CartPaymentCard/CartPaymentCardScreen';
import { CartResultScreen } from './cartSummary/application/screens/cartResult/CartResultScreen';
import { InvoicesScreen } from './invoices/application/screens/list/InvoicesScreen';
import { InvoiceDetailScreen } from './invoices/application/screens/detail/InvoiceDetailScreen';
import { CollectionListScreen } from './collection/view/screen/list/CollectionListScreen';
import { CheckoutOk } from './cartSummary/application/components/checkout/checkoutOk/CheckoutOk';

export const AppRouting = (): ReactElement => {
    const origin = useOrigin();

    const routes = (
        <Routes>
            <Route
                path={TencerRoutes.LOGIN_SIGN_IN}
                element={!TencerAuthServiceInstance.isAuthenticated() ? (
                    <SignInScreen />
                ) : (
                    <Navigate to={TencerRoutes.HOME} />
                )}
            />
            <Route
                path={TencerRoutes.LOGIN_SIGN_UP}
                element={!TencerAuthServiceInstance.isAuthenticated() ? (
                    <SignUpScreen />
                ) : (
                    <Navigate to={TencerRoutes.HOME} />
                )}
            />
            <Route
                path={TencerRoutes.ACCOUNT}
                element={TencerAuthServiceInstance.isAuthenticated() ? (
                    <div className="co-app">
                        <main>
                            <Header showSearch={false} />
                            <AccountScreen />
                        </main>
                        <Footer />
                    </div>
                ) : (
                    <Navigate to={TencerRoutes.LOGIN_SIGN_IN} />
                )}
            />
            <Route
                path={TencerRoutes.HOME}
                element={TencerAuthServiceInstance.isAuthenticated() ? (
                    <div className="co-app">
                        <main>
                            <Header showSearch={false} />
                            <HomeScreen title="home" url="/" />
                        </main>
                        <Footer />
                    </div>
                ) : (
                    <Navigate to={TencerRoutes.LOGIN_SIGN_IN} />
                )}
            />
            <Route
                path={TencerRoutes.COLLECTION_DETAILS}
                element={TencerAuthServiceInstance.isAuthenticated() ? (
                    <div className="co-app">
                        <main>
                            <Header showSearch={false} />
                            <CollectionDetailScreen />
                        </main>
                        <Footer />
                    </div>
                ) : (
                    <Navigate to={TencerRoutes.LOGIN_SIGN_IN} />
                )}
            />
            <Route
                path={TencerRoutes.PRODUCT_DETAILS}
                element={TencerAuthServiceInstance.isAuthenticated() ? (
                    <div className="co-app">
                        <main>
                            <Header showSearch={false} />
                            <ProductDetailScreen />
                        </main>
                        <Footer />
                    </div>
                ) : (
                    <Navigate to={TencerRoutes.LOGIN_SIGN_IN} />
                )}
            />
            <Route
                path={TencerRoutes.RESET_PASSWORD}
                element={!TencerAuthServiceInstance.isAuthenticated() ? (
                    <div className="co-app">
                        <main>
                            <PasswordRecoveryScreen />
                        </main>
                        <Footer />
                    </div>
                ) : (
                    <Navigate to={TencerRoutes.HOME} />
                )}
            />
            <Route
                path={TencerRoutes.CART}
                element={TencerAuthServiceInstance.isAuthenticated() ? (
                    <div className="co-app">
                        <main>
                            <Header showSearch={false} />
                            <CartScreen />
                        </main>
                    </div>
                ) : (
                    <Navigate to={TencerRoutes.LOGIN_SIGN_IN} />
                )}
            />
            <Route
                path={TencerRoutes.ORDER_DETAIL}
                element={TencerAuthServiceInstance.isAuthenticated() ? (
                    <div className="co-app">
                        <main>
                            <Header showSearch={false} />
                            <OrderDetailScreen />
                        </main>
                        <Footer />
                    </div>
                ) : (
                    <Navigate to={TencerRoutes.LOGIN_SIGN_IN} />
                )}
            />
            <Route
                path={TencerRoutes.ORDERS}
                element={TencerAuthServiceInstance.isAuthenticated() ? (
                    <div className="co-app">
                        <main>
                            <Header showSearch={false} />
                            <OrdersScreen />
                        </main>
                        <Footer />
                    </div>
                ) : (
                    <Navigate to={TencerRoutes.LOGIN_SIGN_IN} />
                )}
            />
            <Route
                path={TencerRoutes.INVOICES}
                element={TencerAuthServiceInstance.isAuthenticated() ? (
                    <div className="co-app">
                        <main>
                            <Header showSearch={false} />
                            <InvoicesScreen />
                        </main>
                        <Footer />
                    </div>
                ) : (
                    <Navigate to={TencerRoutes.LOGIN_SIGN_IN} />
                )}
            />
            <Route
                path={TencerRoutes.INVOICE_DETAIL}
                element={TencerAuthServiceInstance.isAuthenticated() ? (
                    <div className="co-app">
                        <main>
                            <Header showSearch={false} />
                            <InvoiceDetailScreen />
                        </main>
                        <Footer />
                    </div>
                ) : (
                    <Navigate to={TencerRoutes.LOGIN_SIGN_IN} />
                )}
            />
            {
                process.env.REACT_APP_ENV !== EnvEnum.PROD ? (
                    <Route
                        path={TencerRoutes.LOADING_ORDERS}
                        element={TencerAuthServiceInstance.isAuthenticated()
                        && origin === Origin.EUROPE
                            ? (
                                <div className="co-app">
                                    <main>
                                        <Header showSearch={false} />
                                        <LoadingOrderListScreen />
                                    </main>
                                    <Footer />
                                </div>
                            ) : (
                                <Navigate to={TencerRoutes.LOGIN_SIGN_IN} />
                            )}
                    />
                ) : null
            }
            {
                process.env.REACT_APP_ENV !== EnvEnum.PROD ? (
                    <Route
                        path={TencerRoutes.LOADING_ORDER_DETAIL}
                        element={TencerAuthServiceInstance.isAuthenticated() && origin === Origin.EUROPE
                            ? (
                                <div className="co-app">
                                    <main>
                                        <Header showSearch={false} />
                                        <LoadingOrderDetailScreen />
                                    </main>
                                    <Footer />
                                </div>
                            ) : (
                                <Navigate to={TencerRoutes.LOGIN_SIGN_IN} />
                            )}
                    />
                ) : null
            }
            <Route
                path={TencerRoutes.CART_SUMMARY}
                element={TencerAuthServiceInstance.isAuthenticated() ? (
                    <div className="co-app">
                        <main>
                            <Header showSearch={false} />
                            <CartSummaryScreen />
                        </main>
                    </div>
                ) : (
                    <Navigate to={TencerRoutes.LOGIN_SIGN_IN} />
                )}
            />
            <Route
                path={TencerRoutes.CART}
                element={TencerAuthServiceInstance.isAuthenticated() ? (
                    <div className="co-app">
                        <main>
                            <Header showSearch={false} />
                            <CartScreen />
                        </main>
                        <Footer />
                    </div>
                ) : (
                    <Navigate to={TencerRoutes.LOGIN_SIGN_IN} />
                )}
            />
            <Route
                path={TencerRoutes.CART_PAYMENT_CARD}
                element={TencerAuthServiceInstance.isAuthenticated() ? (
                    <div className="co-app">
                        <main>
                            <CartPaymentCardScreen />
                        </main>
                        <Footer />
                    </div>
                ) : (
                    <Navigate to={TencerRoutes.LOGIN_SIGN_IN} />
                )}
            />
            <Route
                path={TencerRoutes.CHECKOUT_KO}
                element={TencerAuthServiceInstance.isAuthenticated() ? (
                    <CartResultScreen
                        pageName="checkout-result"
                    >
                        <CheckoutKo />
                    </CartResultScreen>
                ) : (
                    <Navigate to={TencerRoutes.LOGIN_SIGN_IN} />
                )}
            />
            <Route
                path={TencerRoutes.CHECKOUT_OK}
                element={TencerAuthServiceInstance.isAuthenticated() ? (
                    <CartResultScreen
                        pageName="checkout-result"
                    >
                        <CheckoutOk />
                    </CartResultScreen>
                ) : (
                    <Navigate to={TencerRoutes.LOGIN_SIGN_IN} />
                )}
            />
            <Route
                path={TencerRoutes.COLLECTIONS}
                element={TencerAuthServiceInstance.isAuthenticated() ? (
                    <div className="co-app">
                        <main>
                            <Header showSearch />
                            <CollectionListScreen />
                        </main>
                        <Footer />
                    </div>
                ) : (
                    <Navigate to={TencerRoutes.LOGIN_SIGN_IN} />
                )}
            />
            <Route
                path={TencerRoutes.CATALOG}
                element={TencerAuthServiceInstance.isAuthenticated() ? (
                    <div className="co-app">
                        <main>
                            <Header showSearch />
                            <ProductCatalogScreen />
                        </main>
                        <Footer />
                    </div>
                ) : (
                    <Navigate to={TencerRoutes.LOGIN_SIGN_IN} />
                )}
            />

            <Route
                path={TencerRoutes.PRODUCT_BESTSELLERS}
                element={TencerAuthServiceInstance.isAuthenticated() ? (
                    <div className="co-app">
                        <main>
                            <Header showSearch />
                            <ProductBestsellersScreen />
                        </main>
                        <Footer />
                    </div>
                ) : (
                    <Navigate to={TencerRoutes.LOGIN_SIGN_IN} />
                )}
            />
            <Route
                path={TencerRoutes.PRODUCT_OUTLET}
                element={TencerAuthServiceInstance.isAuthenticated() ? (
                    <div className="co-app">
                        <main>
                            <Header showSearch />
                            <ProductOutletScreen />
                        </main>
                        <Footer />
                    </div>
                ) : (
                    <Navigate to={TencerRoutes.LOGIN_SIGN_IN} />
                )}
            />
            <Route
                path={TencerRoutes.MARKETING}
                element={TencerAuthServiceInstance.isAuthenticated() &&
                origin === Origin.USA ?
                    (
                        <div className="co-app">
                            <main>
                                <Header showSearch />
                                <MarketingScreen />
                            </main>
                            <Footer />
                        </div>
                    ) : (
                        <Navigate to={TencerRoutes.LOGIN_SIGN_IN} />
                    )}
            />
            <Route path="*" element={<Navigate to={TencerRoutes.HOME} />} />
        </Routes>
    );

    return (
        <React.Suspense fallback={null}>
            <BrowserRouter>
                <ScrollTop />
                {routes}
            </BrowserRouter>
        </React.Suspense>
    );
};
