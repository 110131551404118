import { RefObject, useContext, useRef, useState } from 'react';
import getProductsUseCase from 'app/product/domain/useCase/product/GetProductsUseCase';
import { useNavigate } from 'react-router-dom';
import Typeahead from 'react-bootstrap-typeahead/types/core/Typeahead';
import { AsyncSelectData } from '../../../shared/components/asyncSelect/AsyncSelectData';
import { ProductType, ProductTypeValue } from '../../../product/domain/valueObject/ProductType';
import { DependencyInjectionContext } from '../../../shared/context/DependecyInjectionContext';
import { Product } from '../../../product/domain/entity/Product';
import { Pagination } from '../../../shared/domain/value-objects/Pagination';

export interface UseHomeSearcherControllerResponse {
    isLoading: boolean
    options: AsyncSelectData<Product>[]
    refAsyncSelect: RefObject<Typeahead>
    fetchProducts: (textSearch: string) => Promise<void>
    handleSelect: (options: AsyncSelectData<Product>[]) => void
}

export const useHomeSearcherController = (): UseHomeSearcherControllerResponse => {
    const initialPage = 1;
    const defaultResults = 20;
    const refAsyncSelect = useRef<Typeahead>(null);

    const navigate = useNavigate();
    const dic = useContext(DependencyInjectionContext);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [options, setOptions] = useState<AsyncSelectData<Product>[]>([]);

    const handleSelect = (selection: AsyncSelectData<Product>[]): void => {
        navigate(`/products/${selection[0].value}`);
    };

    const fetchProducts = async (search: string): Promise<void> => {
        setIsLoading(true);

        const pagination = new Pagination(
            initialPage,
            defaultResults,
            null,
        );

        await getProductsUseCase({
            pagination,
            productsId: [],
            type: new ProductType(ProductTypeValue.ALL),
            textSearch: search,
            productRepository: dic.productRepository,
        })
            .then((data) => {
                const optionsMapped = data.map((product) => {
                    return {
                        value: product.id.value,
                        label: product.description ?? '',
                        data: product,
                    };
                });

                setOptions(optionsMapped);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return {
        options,
        isLoading,
        handleSelect,
        fetchProducts,
        refAsyncSelect,
    };
};
