import React, { ReactElement } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, EffectFade } from 'swiper';
import 'swiper/components/effect-fade/effect-fade.scss';
import { BG_01, BG_02, BG_03, BG_04, BG_05, BG_06 } from '../../../assets/img';

interface SwiperBackgroundProps {
    classNameProp: string,
    images?: string[]
}

export const SwiperBackground = (props: SwiperBackgroundProps): ReactElement => {

    SwiperCore.use([Autoplay, EffectFade]);

    const defaultImages: string[] = [BG_01, BG_02, BG_03, BG_04, BG_05, BG_06];

    const sliderImages: string[] = props.images?.length ? props.images : defaultImages;

    return (
        <div>
            <Swiper
                speed={4000}
                effect="fade"
                slidesPerView={1}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                }}
                fadeEffect={{
                    crossFade: true, // enables slides to cross fade
                }}
                className={props.classNameProp}
            >
                {sliderImages.map((image) => (
                    <SwiperSlide
                        key={image}
                        className="swiper-slide"
                    >
                        <img
                            src={image}
                            alt="sliderImage"
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};
