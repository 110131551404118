import React, { ReactElement } from 'react';

interface CollectionHeaderSectionProps {
    name: string,
    category: string,
}

export const CollectionHeaderSection = (props: CollectionHeaderSectionProps): ReactElement => {
    return (
        <section className="header-section bg-light">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 product-info">
                        <p className="product-subtitle text-start">
                            {props.category}
                        </p>
                        <h3 className="page-title text-start">
                            {props.name}
                        </h3>
                    </div>
                </div>
            </div>
        </section>
    );
};
