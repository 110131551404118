import { useTranslation } from 'react-i18next';
import React, { ReactElement } from 'react';
import { POUND_WEIGHT, WEIGHT_UNIT } from 'i18n/translations/TR';
import NumberFormat from 'react-number-format';
import { useOrigin } from 'app/shared/hooks/useOrigin';
import { Origin } from '../domain/value-objects/Origin';

interface COWeightProps{
    value: number | string,
    className?: string,
}

export const COWeight = (props:COWeightProps):React.ReactElement => {
    const { i18n, t } = useTranslation();
    const origin = useOrigin();
    return (
        <NumberFormat
            value={props.value}
            displayType="text"
            className={props.className}
            thousandSeparator={i18n.language === 'es' ? '.' : ','}
            decimalSeparator={i18n.language === 'es' ? ',' : '.'}
            decimalScale={2}
            renderText={(x):ReactElement => (
                <>
                    <span className="weight-value">{x}</span>
                    {origin === Origin.EUROPE ? (
                        <span className="weight-label">
                            {' '}
                            {t(WEIGHT_UNIT)}
                        </span>
                    ) : null}
                    {origin === Origin.USA ? (
                        <span className="weight-label">
                            {' '}
                            {t(POUND_WEIGHT)}
                        </span>
                    ) : null}
                </>
            )}
        />
    );
};

COWeight.defaultProps = {
    className: '',
};
