import { Pagination } from '../../../domain/value-objects/Pagination';
import { PaginationRequest, PaginationRequestOrder } from '../dto/requests/PaginationRequest';
import { PaginationOrder } from '../../../domain/value-objects/PaginationOrder';

export class PaginationRequestDataMapper {

    public static fromDomain(pagination: Pagination): PaginationRequest {
        return {
            results: pagination.results,
            page: pagination.page,
            order: pagination.order ? this.orderPaginationFromDomain(pagination.order) : undefined,
        };
    }

    private static orderPaginationFromDomain(order: PaginationOrder): PaginationRequestOrder {
        switch (order) {
            case PaginationOrder.DATE:
                return PaginationRequestOrder.DATE;
            case PaginationOrder.ALPHABETICAL:
            default:
                return PaginationRequestOrder.ALPHABETICAL;
        }
    }

}
