import { ProductPriceResponse } from './ProductPriceResponse';

export interface ProductResponse {
    product: string;
    box: string | null;
    code: string | null;
    type: string | null;
    paste: string | null;
    tile: string | null;
    image: string | null;
    format: string | null;
    family: string | null;
    heights: number | null;
    outlet: boolean | null;
    enabled: boolean | null;
    default: boolean | null;
    boxPiece: number | null;
    boxMeter: number | null;
    boxMeterL: number | null;
    boxWeight: number | null;
    palletBox: number | null;
    collection: string | null;
    palletPlane: number | null;
    palletPiece: number | null;
    palletMeter: number | null;
    description: string | null;
    collectionId: number | null;
    stockEntryDate: string | null;
    unit: ProductUnitResponse | null;
    price: ProductPriceResponse | null;
}

export enum ProductUnitResponse {
    M2 = '1',
    PIECE = '0',
}

export enum ProductTypeResponse {
    ALL = 'all',
    OUTLET = 'outlet',
    CATALOG = 'catalog',
    BESTSELLER = 'trending',
}
