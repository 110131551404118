import ProductQuantity from '../../../../../domain/valueObject/ProductQuantity';
import { ProductQuantityResponse } from '../../../../../../shared/infrastructure/tencer-api/dto/responses/ProductQuantityResponse';

export class ProductQuantityDataMapper {

    public static fromResponse(response: ProductQuantityResponse): ProductQuantity {
        return new ProductQuantity(
            response.amount,
            {
                name: response.unit.name,
                value: response.unit.value,
            },
        );
    }

}
