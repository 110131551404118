import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import getCollectionUseCase from '../../../domain/useCase/GetCollectionUseCase';
import { DependencyInjectionContext } from '../../../../shared/context/DependecyInjectionContext';
import { CollectionId } from '../../../domain/valueObject/CollectionId';
import { CollectionDetails } from '../../../domain/entity/CollectionDetails';

interface UseCollectionDetailScreenControllerResponse {
    error: boolean;
    isLoading: boolean;
    data?: CollectionDetails;
}

export function useCollectionDetailScreenController(): UseCollectionDetailScreenControllerResponse {
    const { id } = useParams<{id: string}>();
    const dic = useContext(DependencyInjectionContext);
    const [error, setError] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<CollectionDetails>();

    const fetchData = async (): Promise<void> => {
        getCollectionUseCase({
            collectionId: new CollectionId(Number(id)),
            collectionRepository: dic.collectionRepository,
        })
            .then((collection) => {
                setData(collection);
            })
            .catch(() => {
                setError(true);
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        fetchData();
    }, []);

    return {
        data,
        error,
        isLoading,
    };
}
