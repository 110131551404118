export enum ProductUnitValue {
    M2 = 'M2',
    PIECE = 'PIECE',
}

export class ProductUnit {

    public constructor(private readonly _value: ProductUnitValue) {
        this._ensureIsValid();
    }

    public get value(): ProductUnitValue {
        return this._value;
    }

    public isPiece(): boolean {
        return this._value === ProductUnitValue.PIECE;
    }

    public isM2(): boolean {
        return this._value === ProductUnitValue.M2;
    }

    private _ensureIsValid(): void {
        if (!Object.values(ProductUnitValue).includes(this._value)) {
            throw new Error(`Invalid product unit value: ${this._value}`);
        }
    }

}
