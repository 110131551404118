import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useOrigin } from '../../../../shared/hooks/useOrigin';
import { TencerRoutes } from '../../../../shared/tencer-routing/TencerRoutes';
import { COLLECTIONS, MENU_OPTION_MARKETING, PRODUCTS } from '../../../../../i18n/translations/TR';
import { Origin } from '../../../../shared/domain/value-objects/Origin';
import { ProductTab } from './tab/ProductTab';

export const ProductTabs = (): ReactElement => {
    const origin = useOrigin();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const OPTIONS = {
        CATALOG: 'catalog',
        COLLECTIONS: 'collections',
        MARKETING: 'marketing',
    };

    const optionsRouteMap = new Map<string, string>([
        [OPTIONS.CATALOG, TencerRoutes.CATALOG],
        [OPTIONS.COLLECTIONS, TencerRoutes.COLLECTIONS],
        [OPTIONS.MARKETING, TencerRoutes.MARKETING],
    ]);

    const isActiveOption = (option: string): boolean => {
        return optionsRouteMap.get(option) === window.location.pathname;
    };

    const goToCatalog = ():void => {
        navigate(TencerRoutes.CATALOG);
    };

    const goToCollections = ():void => {
        navigate(TencerRoutes.COLLECTIONS);
    };

    const goToMarketing = ():void => {
        navigate(TencerRoutes.MARKETING);
    };

    const classTab = classNames('container');

    return (
        <div className={classTab}>
            <ul className="nav nav-pills" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                    <ProductTab
                        id="pills-catalog-tab"
                        active={isActiveOption(OPTIONS.CATALOG)}
                        text={t(PRODUCTS)}
                        target="pills-catalog"
                        onClick={goToCatalog}
                    />
                </li>
                <li className="nav-item" role="presentation">
                    <ProductTab
                        id="pills-collection-tab"
                        active={isActiveOption(OPTIONS.COLLECTIONS)}
                        text={t(COLLECTIONS)}
                        target="pills-collection"
                        onClick={goToCollections}
                    />
                </li>
                { origin && origin.match(Origin.USA) ? (
                    <li className="nav-item" role="presentation">
                        <ProductTab
                            id="pills-marketing-tab"
                            active={isActiveOption(OPTIONS.MARKETING)}
                            text={t(MENU_OPTION_MARKETING)}
                            target="pills-marketing"
                            onClick={goToMarketing}
                        />
                    </li>
                ) : null }
            </ul>
        </div>
    );
};
