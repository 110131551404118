import { useTranslation } from 'react-i18next';
import { CONTACT_EMAIL_EU, CONTACT_EMAIL_USA } from 'app/shared/constants/hrefConstants';
import { useOrigin } from './useOrigin';
import { Origin } from '../domain/value-objects/Origin';

interface UseSupportEmailResponse {
    openSupportEmail: () => void
    getSupportEmailLink: () => string
}

export const useSupportEmail = (): UseSupportEmailResponse => {
    const origin = useOrigin();
    const { t } = useTranslation();

    const getSupportEmailLink = (): string => {
        const email = origin === Origin.USA
            ? CONTACT_EMAIL_USA
            : CONTACT_EMAIL_EU;

        const body = origin === Origin.USA
            ? `?subject=${t('SUPPORT_INQUIRY')}&body=Ph: 703-910-6162 Ext: 115`
            : `?subject=${t('SUPPORT_INQUIRY')}`;

        return `mailto:${email}${body}`;
    };

    const openSupportEmail = (): void => {
        window.location.href = getSupportEmailLink();
    };

    return {
        openSupportEmail,
        getSupportEmailLink,
    };
};
