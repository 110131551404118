import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { PRODUCTS } from '../../../../../../i18n/translations/TR';
import { ProductListScreen } from '../ProductListScreen';
import { ProductType, ProductTypeValue } from '../../../../domain/valueObject/ProductType';

export const ProductCatalogScreen = (): ReactElement => {
    const { t } = useTranslation();

    return (
        <ProductListScreen
            title={t(PRODUCTS)}
            hasFiltersEnabled
            className="component-product-catalog-screen"
            type={new ProductType(ProductTypeValue.CATALOG)}
        />
    );
};
